import { transformError } from "@/shared";
import { docs } from "@/api/kuber-api";
import {
  AgCustomerDocumentPackageListEntity,
  GetCustomerDocumentPackageController_AgCustomerDocumentPackageResponse,
  GetCustomerDocumentPackageListController_AgCustomerDocumentPackageListResponse,
} from "@/api/kuber-api/codegen";
import { DocsResponse, GetDocumentsRequest } from "@/domain/docs";

export async function getDocumentList(request: GetDocumentsRequest): Promise<DocsResponse> {
  try {
    return await docs.api.getDocumentList(request).then((response) => toDocsResponse(response));
  } catch (error) {
    throw transformError(error);
  }
}

function toDocsResponse(
  response: GetCustomerDocumentPackageListController_AgCustomerDocumentPackageListResponse,
): DocsResponse {
  const docs = response.entities ? response.entities : ([] as AgCustomerDocumentPackageListEntity[]);
  return {
    items: docs,
    pagination: response.pagination ? response.pagination : { offset: 0, limit: 10, total: docs.length },
  };
}

export async function getDocumentPackage(request: {
  documentPackageId: string;
}): Promise<GetCustomerDocumentPackageController_AgCustomerDocumentPackageResponse> {
  try {
    return await docs.api.getDocumentPackage(request).then((response) => response);
  } catch (error) {
    throw transformError(error);
  }
}
