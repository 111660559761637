import { atom } from "jotai";

// global state for scrollY on main layout
export const scrollYAtom = atom<number>(0);

export const scrollYBeforeFooterAtom = atom<number>(0);
export const scrollYAfterHeaderAtom = atom<number>(0);

export const footerHeight = 506;
export const headerHeight = 270;
