import { clientDefault } from "../client";
import {
  CheckBeforePrintTnController_AgCheckBeforePrintTnResponse,
  CheckBeforePrintTnController_AgOrderIdCodeBoxIdRequest,
  PrintTnController_AgOrderIdCodeBoxIdRequest,
  PrintTnController_AgPrintTnResponse,
} from "../codegen";
import { CHECK_BEFORE_PRINT_TN, PRINT_TN } from "../endpoints";

export function checkBeforePrintTN(
  request: CheckBeforePrintTnController_AgOrderIdCodeBoxIdRequest,
): Promise<CheckBeforePrintTnController_AgCheckBeforePrintTnResponse> {
  return clientDefault
    .post<CheckBeforePrintTnController_AgCheckBeforePrintTnResponse>(CHECK_BEFORE_PRINT_TN, request)
    .then((response) => response.data);
}

export function printTN(
  request: PrintTnController_AgOrderIdCodeBoxIdRequest,
): Promise<PrintTnController_AgPrintTnResponse> {
  return clientDefault.post<PrintTnController_AgPrintTnResponse>(PRINT_TN, request).then((response) => response.data);
}
