import RefreshIcon from "@/components/icons/RefreshIcon";
import { AppError } from "@/domain";
import { parseErrorMessage } from "@/shared";
import { Button } from "@/ui";

interface Props {
  error: AppError;
  onRetry: () => void;
}

export const ErrorState = ({ error, onRetry }: Props) => {
  const { title = "Внутренняя ошибка", description = "Пожалуйста, попробуйте позже<br />или обратитесь в поддержку" } =
    parseErrorMessage(error.errorMessage ?? "");

  if (error.error?.stack?.includes("code 403"))
    return (
      <div className="flex flex-col  items-center space-y-6 at768:my-15 at1366:my-30 at1366:space-y-8">
        <div className="hidden h-12 w-12 at1366:block">
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M44 4L24 24M24 24L4 44M24 24L4 4M24 24L44 44"
              stroke="#D0D6E0"
              strokeWidth="8"
              strokeLinecap="round"
            />
          </svg>
        </div>
        <div className="space-y-3 text-center at1366:space-y-4">
          <div className="text-lg font-bold text-title">Запрет на чтение данных!</div>
          <div className="text-title">
            У вас нет прав для просмотра содержимого страницы, пожалуйста, обратитесь в поддержку.
          </div>
          <div className="text-cool-grey-500">код ошибки: 403</div>
        </div>
      </div>
    );

  return (
    <div className="flex flex-col  items-center space-y-6 at768:my-15 at1366:my-30 at1366:space-y-8">
      <div className="hidden h-12 w-12 at1366:block">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M44 4L24 24M24 24L4 44M24 24L4 4M24 24L44 44"
            stroke="#D0D6E0"
            strokeWidth="8"
            strokeLinecap="round"
          />
        </svg>
      </div>
      <div className="space-y-3 text-center at1366:space-y-4">
        {/*<div className="text-lg font-bold text-title">{title}</div>*/}
        <div className="text-title" dangerouslySetInnerHTML={{ __html: description }}></div>
        {error.errorCode && <div className="text-cool-grey-500">код ошибки: {error.errorCode}</div>}
      </div>
      <div>
        <Button onClick={onRetry} className="flex items-center gap-x-2.5">
          <div className="h-6 w-6">
            <RefreshIcon />
          </div>
          <span className="font-bold">Обновить</span>
        </Button>
      </div>
    </div>
  );
};
