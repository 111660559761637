// const Close = () => (
//   <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
//     <path d="M1 1L8 8M15 15L8 8M8 8L15 1L1 15" stroke="currentColor" />
//   </svg>
// );

const Close = () => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="stroke-primaryText"
  >
    <path d="M0.5 1L7.5 8M14.5 15L7.5 8M7.5 8L14.5 1L0.5 15" />
  </svg>
);

export default Close;
