import { profile } from "@/api/kuber-api";
import {
  UpdateBankInfoRequest,
  UpdateCompanyInfoRequest,
  UpdateProfileInfoRequest,
  UploadDocumentRequest,
} from "@/domain";
import { transformError } from "@/shared";

export async function getProfile(isMover: boolean) {
  try {
    const profileInfo = isMover ? await profile.api.getMoverProfile() : await profile.api.getCustomerProfile();

    return profile.mappers.toProfile(profileInfo);
  } catch (error) {
    throw transformError(error);
  }
}

export function updateProfileInfo(request: UpdateProfileInfoRequest) {
  return request.profileType === "customer"
    ? profile.api
        .updateCustomerProfileInfo({
          profile_entity: profile.mappers.toNewCustomerProfileEntity(request.payload),
        })
        .catch((error) => {
          throw transformError(error);
        })
    : profile.api
        .updateMoverProfileInfo({ profile_entity: profile.mappers.toNewMoverProfileEntity(request.payload) })
        .catch((error) => {
          throw transformError(error);
        });
}

export function updateCompanyInfo(request: UpdateCompanyInfoRequest) {
  return request.profileType === "customer"
    ? profile.api
        .updateCustomerCompanyInfo({
          company_entity: profile.mappers.toNewCustomerCompanyEntity(request.payload),
        })
        .catch((error) => {
          throw transformError(error);
        })
    : profile.api
        .updateMoverCompanyInfo({
          company_entity: profile.mappers.toNewMoverCompanyEntity(request.payload),
        })
        .catch((error) => {
          throw transformError(error);
        });
}

export function updateBankInfo(request: UpdateBankInfoRequest) {
  return request.profileType === "customer"
    ? profile.api
        .updateCustomerBankInfo({ bank_bill_entity: profile.mappers.toNewBankBillEntity(request.payload) })
        .catch((error) => {
          throw transformError(error);
        })
    : profile.api
        .updateMoverBankInfo({ bank_bill_entity: profile.mappers.toNewBankBillEntity(request.payload) })
        .catch((error) => {
          throw transformError(error);
        });
}

export function uploadDocument(request: UploadDocumentRequest) {
  return request.profileType === "customer"
    ? profile.api
        .uploadCustomerDocument({ new_document_entity: profile.mappers.toNewDocument(request.payload) })
        .catch((error) => {
          throw transformError(error);
        })
    : profile.api
        .uploadMoverDocument({ new_document_entity: profile.mappers.toNewDocument(request.payload) })
        .catch((error) => {
          throw transformError(error);
        });
}
