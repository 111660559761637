import { GetTicketChatRequest, GetTicketMessagesRequest, GetTicketsListRequest } from "@/domain/tickets";
import { clientWithAuth } from "../client";
import {
  ChangeCustomerTicketPinnedStatusController_NewTicketPinnedStatusRequest,
  ChangeCustomerTicketPinnedStatusController_ResultBoolResponse,
  ChangeMoverTicketPinnedStatusController_NewTicketPinnedStatusRequest,
  ChangeMoverTicketPinnedStatusController_ResultBoolResponse,
  GetCustomerMovedFromOtherTicketMessageInfoController_MovedFromOtherTicketMessageInfoResponse,
  GetCustomerMovedToOtherTicketMessageInfoController_MovedToOtherTicketMessageInfoResponse,
  GetCustomerOrdersController_CustomerOrdersResponse,
  GetCustomerTicketListController_CustomerTicketListResponse,
  GetCustomerTicketMessageListController_TicketMessageListResponse,
  GetCustomerTicketsController_ShortTicketListResponse,
  GetCustomerUnreadTicketMessageCountController_UnreadTicketMessageCountResponse,
  GetMoverMovedFromOtherTicketMessageInfoController_MovedFromOtherTicketMessageInfoResponse,
  GetMoverMovedToOtherTicketMessageInfoController_MovedToOtherTicketMessageInfoResponse,
  GetMoverOrdersController_MoverOrdersResponse,
  GetMoverTicketListController_MoverTicketListResponse,
  GetMoverTicketMessageListController_TicketMessageListResponse,
  GetMoverTicketsController_ShortTicketListResponse,
  GetMoverUnreadTicketMessageCountController_UnreadTicketMessageCountResponse,
  MarkCustomerMessagesAsReadController_MessagesMarkAsReadRequest,
  MarkCustomerMessagesAsReadController_ResultBoolResponse,
  MarkMoverMessagesAsReadController_MessagesMarkAsReadRequest,
  MarkMoverMessagesAsReadController_ResultBoolResponse,
  OpenCustomerTicketController_TicketAndTicketMessageRequest,
  OpenCustomerTicketController_TicketIdResponse,
  OpenMoverTicketController_TicketAndTicketMessageRequest,
  OpenMoverTicketController_TicketIdResponse,
  SendCustomerTicketMessageController_TicketMessageIdResponse,
  SendCustomerTicketMessageController_TicketMessageRequest,
  SendMoverTicketMessageController_TicketMessageIdResponse,
  SendMoverTicketMessageController_TicketMessageRequest,
} from "../codegen";
import {
  CUSTOMER_CHANGE_TICKET_PINNED_STATUS,
  CUSTOMER_GET_MOVED_MESSAGE_FROM_INFO,
  CUSTOMER_GET_MOVED_MESSAGE_TO_INFO,
  CUSTOMER_MARK_MESSAGE_AS_READ,
  CUSTOMER_ORDERS_LIST,
  CUSTOMER_TICKET_CHAT_DIRECTORY,
  CUSTOMER_TICKET_CHAT_LIST,
  CUSTOMER_TICKET_CHAT_MESSAGE_LIST,
  CUSTOMER_UNREAD_MESSAGE_COUNT,
  GET_TICKETS,
  MOVER_CHANGE_TICKET_PINNED_STATUS,
  MOVER_GET_MOVED_MESSAGE_FROM_INFO,
  MOVER_GET_MOVED_MESSAGE_TO_INFO,
  MOVER_MARK_MESSAGE_AS_READ,
  MOVER_ORDERS_LIST,
  MOVER_TICKET_CHAT_DIRECTORY,
  MOVER_TICKET_CHAT_LIST,
  MOVER_TICKET_CHAT_MESSAGE_LIST,
  MOVER_UNREAD_MESSAGE_COUNT,
  OPEN_CUSTOMER_TICKET_CHAT,
  OPEN_MOVER_TICKET_CHAT,
  SEND_CUSTOMER_TICKET_CHAT_MESSAGE,
  SEND_MOVER_TICKET_CHAT_MESSAGE,
} from "../endpoints";

export function getTickets(
  request: GetTicketsListRequest,
): Promise<
  GetCustomerTicketListController_CustomerTicketListResponse | GetMoverTicketListController_MoverTicketListResponse
> {
  let url = GET_TICKETS;
  url += `?offset=${request.pagination.offset}&limit=${request.pagination.limit}&sorting_field=${request.sorting.field}&sorting_order_by=${request.sorting.direction}`;
  if (request.status) {
    url += `&status=${request.status}`;
  }

  return clientWithAuth
    .get<
      GetMoverTicketListController_MoverTicketListResponse | GetCustomerTicketListController_CustomerTicketListResponse
    >(url)
    .then((response) => response.data);
}

export function openCustomerTicketChat(
  request: OpenCustomerTicketController_TicketAndTicketMessageRequest,
): Promise<OpenCustomerTicketController_TicketIdResponse> {
  return clientWithAuth
    .post<OpenCustomerTicketController_TicketIdResponse>(OPEN_CUSTOMER_TICKET_CHAT, request)
    .then((response) => response.data);
}

export function openMoverTicketChat(
  request: OpenMoverTicketController_TicketAndTicketMessageRequest,
): Promise<OpenMoverTicketController_TicketIdResponse> {
  return clientWithAuth
    .post<OpenMoverTicketController_TicketIdResponse>(OPEN_MOVER_TICKET_CHAT, request)
    .then((response) => response.data);
}

export function sendCustomerTicketChatMessage(
  request: SendCustomerTicketMessageController_TicketMessageRequest,
): Promise<SendCustomerTicketMessageController_TicketMessageIdResponse> {
  return clientWithAuth
    .post<SendCustomerTicketMessageController_TicketMessageIdResponse>(SEND_CUSTOMER_TICKET_CHAT_MESSAGE, request)
    .then((response) => response.data);
}

export function sendMoverTicketChatMessage(
  request: SendMoverTicketMessageController_TicketMessageRequest,
): Promise<SendMoverTicketMessageController_TicketMessageIdResponse> {
  return clientWithAuth
    .post<SendMoverTicketMessageController_TicketMessageIdResponse>(SEND_MOVER_TICKET_CHAT_MESSAGE, request)
    .then((response) => response.data);
}

export function getCustomerOrders(): Promise<GetCustomerOrdersController_CustomerOrdersResponse> {
  return clientWithAuth
    .get<GetCustomerOrdersController_CustomerOrdersResponse>(CUSTOMER_ORDERS_LIST)
    .then((response) => response.data);
}

export function getMoverOrders(): Promise<GetMoverOrdersController_MoverOrdersResponse> {
  return clientWithAuth
    .get<GetMoverOrdersController_MoverOrdersResponse>(MOVER_ORDERS_LIST)
    .then((response) => response.data);
}

export function getCustomerTicketChats(
  request: GetTicketChatRequest,
): Promise<GetCustomerTicketListController_CustomerTicketListResponse> {
  const url = `${CUSTOMER_TICKET_CHAT_LIST}?offset=${request.pagination.offset}&limit=${request.pagination.limit}`;
  return clientWithAuth
    .get<GetCustomerTicketListController_CustomerTicketListResponse>(url)
    .then((response) => response.data);
}

export function getMoverTicketChats(
  request: GetTicketChatRequest,
): Promise<GetMoverTicketListController_MoverTicketListResponse> {
  const url = `${MOVER_TICKET_CHAT_LIST}?offset=${request.pagination.offset}&limit=${request.pagination.limit}`;
  return clientWithAuth
    .get<GetMoverTicketListController_MoverTicketListResponse>(url)
    .then((response) => response.data);
}

export function getCustomerTicketChatMessages(
  request: GetTicketMessagesRequest,
): Promise<GetCustomerTicketMessageListController_TicketMessageListResponse> {
  const url = `${CUSTOMER_TICKET_CHAT_MESSAGE_LIST}?ticket_id=${request.ticketId}&offset=${request.pagination.offset}&limit=${request.pagination.limit}`;
  return clientWithAuth
    .get<GetCustomerTicketMessageListController_TicketMessageListResponse>(url)
    .then((response) => response.data);
}

export function getMoverTicketChatMessages(
  request: GetTicketMessagesRequest,
): Promise<GetMoverTicketMessageListController_TicketMessageListResponse> {
  const url = `${MOVER_TICKET_CHAT_MESSAGE_LIST}?ticket_id=${request.ticketId}&offset=${request.pagination.offset}&limit=${request.pagination.limit}`;

  return clientWithAuth
    .get<GetMoverTicketMessageListController_TicketMessageListResponse>(url)
    .then((response) => response.data);
}

export function getCustomerTicketChatDirectory(): Promise<GetCustomerTicketsController_ShortTicketListResponse> {
  return clientWithAuth
    .get<GetCustomerTicketsController_ShortTicketListResponse>(CUSTOMER_TICKET_CHAT_DIRECTORY)
    .then((response) => response.data);
}

export function getMoverTicketChatDirectory(): Promise<GetMoverTicketsController_ShortTicketListResponse> {
  return clientWithAuth
    .get<GetMoverTicketsController_ShortTicketListResponse>(MOVER_TICKET_CHAT_DIRECTORY)
    .then((response) => response.data);
}

export function getCustomerUnreadMessageCount(request: {
  ticketId: string;
}): Promise<GetCustomerUnreadTicketMessageCountController_UnreadTicketMessageCountResponse> {
  const url = `${CUSTOMER_UNREAD_MESSAGE_COUNT}?ticket_id=${request.ticketId}`;

  return clientWithAuth
    .get<GetCustomerUnreadTicketMessageCountController_UnreadTicketMessageCountResponse>(url)
    .then((response) => response.data);
}

export function getMoverUnreadMessageCount(request: {
  ticketId: string;
}): Promise<GetMoverUnreadTicketMessageCountController_UnreadTicketMessageCountResponse> {
  const url = `${MOVER_UNREAD_MESSAGE_COUNT}?ticket_id=${request.ticketId}`;
  return clientWithAuth
    .get<GetMoverUnreadTicketMessageCountController_UnreadTicketMessageCountResponse>(url)
    .then((response) => response.data);
}

export function changeCustomerTicketPinnedStatus(
  request: ChangeCustomerTicketPinnedStatusController_NewTicketPinnedStatusRequest,
): Promise<ChangeCustomerTicketPinnedStatusController_ResultBoolResponse> {
  return clientWithAuth
    .post<ChangeCustomerTicketPinnedStatusController_ResultBoolResponse>(CUSTOMER_CHANGE_TICKET_PINNED_STATUS, request)
    .then((response) => response.data);
}

export function changeMoverTicketPinnedStatus(
  request: ChangeMoverTicketPinnedStatusController_NewTicketPinnedStatusRequest,
): Promise<ChangeMoverTicketPinnedStatusController_ResultBoolResponse> {
  return clientWithAuth
    .post<ChangeMoverTicketPinnedStatusController_ResultBoolResponse>(MOVER_CHANGE_TICKET_PINNED_STATUS, request)
    .then((response) => response.data);
}

export function markCustomerMessagesAsRead(
  request: MarkCustomerMessagesAsReadController_MessagesMarkAsReadRequest,
): Promise<MarkCustomerMessagesAsReadController_ResultBoolResponse> {
  return clientWithAuth
    .post<MarkCustomerMessagesAsReadController_ResultBoolResponse>(CUSTOMER_MARK_MESSAGE_AS_READ, request)
    .then((response) => response.data);
}

export function markMoverMessagesAsRead(
  request: MarkMoverMessagesAsReadController_MessagesMarkAsReadRequest,
): Promise<MarkMoverMessagesAsReadController_ResultBoolResponse> {
  return clientWithAuth
    .post<MarkMoverMessagesAsReadController_ResultBoolResponse>(MOVER_MARK_MESSAGE_AS_READ, request)
    .then((response) => response.data);
}

export function getCustomerMoveMessageFromInfo(request: {
  messageId: string;
}): Promise<GetCustomerMovedFromOtherTicketMessageInfoController_MovedFromOtherTicketMessageInfoResponse> {
  const url = `${CUSTOMER_GET_MOVED_MESSAGE_FROM_INFO}?message_id=${request.messageId}`;
  return clientWithAuth
    .get<GetCustomerMovedFromOtherTicketMessageInfoController_MovedFromOtherTicketMessageInfoResponse>(url)
    .then((response) => response.data);
}

export function getCustomerMoveMessageToInfo(request: {
  messageId: string;
}): Promise<GetCustomerMovedToOtherTicketMessageInfoController_MovedToOtherTicketMessageInfoResponse> {
  const url = `${MOVER_GET_MOVED_MESSAGE_FROM_INFO}?message_id=${request.messageId}`;
  return clientWithAuth
    .get<GetCustomerMovedToOtherTicketMessageInfoController_MovedToOtherTicketMessageInfoResponse>(url)
    .then((response) => response.data);
}

export function getMoverMoveMessageFromInfo(request: {
  messageId: string;
}): Promise<GetMoverMovedFromOtherTicketMessageInfoController_MovedFromOtherTicketMessageInfoResponse> {
  const url = `${CUSTOMER_GET_MOVED_MESSAGE_TO_INFO}?message_id=${request.messageId}`;
  return clientWithAuth
    .get<GetMoverMovedFromOtherTicketMessageInfoController_MovedFromOtherTicketMessageInfoResponse>(url)
    .then((response) => response.data);
}

export function getMoverMoveMessageToInfo(request: {
  messageId: string;
}): Promise<GetMoverMovedToOtherTicketMessageInfoController_MovedToOtherTicketMessageInfoResponse> {
  const url = `${MOVER_GET_MOVED_MESSAGE_TO_INFO}?message_id=${request.messageId}`;
  return clientWithAuth
    .get<GetMoverMovedToOtherTicketMessageInfoController_MovedToOtherTicketMessageInfoResponse>(url)
    .then((response) => response.data);
}
