import { clientWithAuth } from "../client";
import {
  GET_CUSTOMER_PROFILE,
  GET_MOVER_PROFILE,
  UPDATE_CUSTOMER_BILL_INFO,
  UPDATE_CUSTOMER_COMPANY_INFO,
  UPDATE_CUSTOMER_PROFILE_INFO,
  UPDATE_MOVER_BILL_INFO,
  UPDATE_MOVER_COMPANY_INFO,
  UPDATE_MOVER_PROFILE_INFO,
  UPLOAD_NEW_CUSTOMER_DOCUMENT,
  UPLOAD_NEW_MOVER_DOCUMENT,
} from "../endpoints";

import {
  PrivateCustomerNewBankBillController_CustomerNewBankBillRequest,
  PrivateCustomerNewBankBillController_ResultBoolResponse,
  PrivateCustomerNewCompanyController_CustomerNewCompanyRequest,
  PrivateCustomerNewCompanyController_ResultBoolResponse,
  PrivateCustomerNewDocumentController_CustomerNewDocumentRequest,
  PrivateCustomerNewDocumentController_ResultBoolResponse,
  PrivateCustomerNewProfileController_CustomerNewProfileRequest,
  PrivateCustomerNewProfileController_ResultBoolResponse,
  PrivateCustomerProfileController_MyProfileResponse,
  PrivateMoverNewBankBillController_MoverNewBankBillRequest,
  PrivateMoverNewBankBillController_ResultBoolResponse,
  PrivateMoverNewCompanyController_MoverNewCompanyRequest,
  PrivateMoverNewCompanyController_ResultBoolResponse,
  PrivateMoverNewDocumentController_MoverNewDocumentRequest,
  PrivateMoverNewDocumentController_ResultBoolResponse,
  PrivateMoverNewProfileController_MoverNewProfileNewRequest,
  PrivateMoverNewProfileController_ResultBoolResponse,
  PrivateMoverProfileController_MyProfileResponse,
} from "../codegen";

export function getCustomerProfile() {
  return clientWithAuth
    .get<PrivateCustomerProfileController_MyProfileResponse>(GET_CUSTOMER_PROFILE)
    .then((response) => response.data);
}

export function getMoverProfile() {
  return clientWithAuth
    .get<PrivateMoverProfileController_MyProfileResponse>(GET_MOVER_PROFILE)
    .then((response) => response.data);
}

export function updateCustomerProfileInfo(request: PrivateCustomerNewProfileController_CustomerNewProfileRequest) {
  return clientWithAuth
    .post<PrivateCustomerNewProfileController_ResultBoolResponse>(UPDATE_CUSTOMER_PROFILE_INFO, request)
    .then((response) => response.data);
}

export function updateCustomerCompanyInfo(request: PrivateCustomerNewCompanyController_CustomerNewCompanyRequest) {
  return clientWithAuth
    .post<PrivateCustomerNewCompanyController_ResultBoolResponse>(UPDATE_CUSTOMER_COMPANY_INFO, request)
    .then((response) => response.data);
}

export function updateCustomerBankInfo(request: PrivateCustomerNewBankBillController_CustomerNewBankBillRequest) {
  return clientWithAuth
    .post<PrivateCustomerNewBankBillController_ResultBoolResponse>(UPDATE_CUSTOMER_BILL_INFO, request)
    .then((response) => response.data);
}

export function updateMoverProfileInfo(request: PrivateMoverNewProfileController_MoverNewProfileNewRequest) {
  return clientWithAuth
    .post<PrivateMoverNewProfileController_ResultBoolResponse>(UPDATE_MOVER_PROFILE_INFO, request)
    .then((response) => response.data);
}

export function updateMoverCompanyInfo(request: PrivateMoverNewCompanyController_MoverNewCompanyRequest) {
  return clientWithAuth
    .post<PrivateMoverNewCompanyController_ResultBoolResponse>(UPDATE_MOVER_COMPANY_INFO, request)
    .then((response) => response.data);
}

export function updateMoverBankInfo(request: PrivateMoverNewBankBillController_MoverNewBankBillRequest) {
  return clientWithAuth
    .post<PrivateMoverNewBankBillController_ResultBoolResponse>(UPDATE_MOVER_BILL_INFO, request)
    .then((response) => response.data);
}

export function uploadCustomerDocument(request: PrivateCustomerNewDocumentController_CustomerNewDocumentRequest) {
  return clientWithAuth
    .post<PrivateCustomerNewDocumentController_ResultBoolResponse>(UPLOAD_NEW_CUSTOMER_DOCUMENT, request)
    .then((response) => response.data);
}

export function uploadMoverDocument(request: PrivateMoverNewDocumentController_MoverNewDocumentRequest) {
  return clientWithAuth
    .post<PrivateMoverNewDocumentController_ResultBoolResponse>(UPLOAD_NEW_MOVER_DOCUMENT, request)
    .then((response) => response.data);
}
