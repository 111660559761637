import { misc } from "@/api/kuber-api";
import {
  GetNewsRequest,
  GetNewsResponse,
  GetOneNewsRequest,
  RequestConsultationRequest,
  ResultResponse,
} from "@/domain";
import { transformError } from "@/shared";
import {
  CleanAddressController_AddressRequest,
  PublicCleanAddressController_ValidAddressResponse,
  GeoAddressController_AddressGeoRequest,
  NewsEntity,
  PublicCleanAddressController_AddressRequest,
  ValidAddressEntity,
} from "@/api/kuber-api/codegen";

export function requestConsultation(request: RequestConsultationRequest): Promise<ResultResponse> {
  return misc.api
    .sendRequestForm(misc.mappers.fromRequestConsultationRequest(request))
    .then(misc.mappers.toResultResponse)
    .catch((error) => {
      throw transformError(error);
    });
}

export function getNews(request: GetNewsRequest): Promise<GetNewsResponse> {
  return misc.api.getNews(request).then(misc.mappers.toGetNewsResponse);
}

export function getOneNews(request: GetOneNewsRequest): Promise<NewsEntity> {
  return misc.api
    .getOneNews(request)
    .then((response) => response.entity)
    .catch((error) => {
      throw transformError(error);
    });
}

export function cleanAddress(request: PublicCleanAddressController_AddressRequest): Promise<ValidAddressEntity | null> {
  return misc.api
    .cleanAddress(request)
    .then((response) => response.valid_address_entity ?? null)
    .catch((error) => {
      throw transformError(error);
    });
}

export function getGeoPosition(request: GeoAddressController_AddressGeoRequest): Promise<{
  geo_lat: number;
  geo_lon: number;
} | null> {
  return misc.api
    .getGeoPosition(request)
    .then((response) => {
      if (
        !response.address_geo_output_entity ||
        !response.address_geo_output_entity.geo_lat ||
        !response.address_geo_output_entity.geo_lon
      )
        return null;
      return {
        geo_lat: response.address_geo_output_entity.geo_lat,
        geo_lon: response.address_geo_output_entity.geo_lon,
      };
    })
    .catch((error) => {
      throw transformError(error);
    });
}

export function getDriverTask(code: string): Promise<any> {
  return misc.api
    .getDriverTask(code)
    .then((response) => response)
    .catch((error) => {
      throw transformError(error);
    });
}
