import React from "react";

function Error() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 44 44">
      <path
        stroke="#E85287"
        strokeLinecap="round"
        strokeWidth="10"
        d="M39 5.5L22 22m0 0L5 38.5M22 22L5 5.5M22 22l17 16.5"
      ></path>
    </svg>
  );
}

export default React.memo(Error);
