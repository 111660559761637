export const stages = {
  LOGIN: "login",
  WELCOME: "welcome",
  RESTORE_PASSWORD: "restore-password",
  RESTORE_PASSWORD_EMAIL_SENT: "restore-password-email-sent",
  REGISTER_CHOOSE_ROLE: "register.choose-role",
  REGISTER_ENTER_PHONE_NUMBER: "register.enter-phone-number",
  REGISTER_ENTER_CONFIRMATION_CODE: "register.enter-confirmation-code",
  REGISTER_CREATE_ACCOUNT: "register.create-account",
  REGISTER_CHOOSE_LEGAL_ENTITY_FORM: "register.choose-legal-entity-form",
  REGISTER_ENTER_INN: "register.enter-inn",
  REGISTER_CONNECT_ATI: "register.connect-ati",
} as const;

type StageKeys = keyof typeof stages;
export type StageName = (typeof stages)[StageKeys];

export const roles = {
  CUSTOMER: "customer",
  MOVER: "mover",
} as const;

type RoleKeys = keyof typeof roles;
export type RoleName = (typeof roles)[RoleKeys];

export const supportRoles = {
  CUSTOMER: "customer",
  MOVER: "mover",
  ADMIN: "admin",
} as const;

type SupportRoleKeys = keyof typeof supportRoles;
export type SupportRoleName = (typeof supportRoles)[SupportRoleKeys];
export const ownershipForms = {
  /** Юр. лицо */
  LEGAL: "legal",
  /** ИП */
  INDIVIDUAL: "individual",
  /** Самозанятый */
  SELFWORKER: "selfworker",
} as const;

type OwnershipFormKeys = keyof typeof ownershipForms;
export type OwnershipFormValue = (typeof ownershipForms)[OwnershipFormKeys];

export const nalogTypesOptions = [
  { label: "УСН", value: "usn" },
  { label: "ОСН", value: "osn" },
];

export const FIRST_MILE = "first_mile" as const;
export const LAST_MILE = "last_mile" as const;
export const EMPTY_CONTAINER = "empty_container" as const;

export type DeliveryType = typeof FIRST_MILE | typeof LAST_MILE | typeof EMPTY_CONTAINER;

export const locations = {
  MSK: "msk",
  SPB: "spb",
  NSK: "nsk",
} as const;

export type LocationType = (typeof locations)[keyof typeof locations];

export const containerClasses = {
  NEW_TYPE: "new_type",
  OLD_TYPE: "old_type",
} as const;

export type ContainerClassType = (typeof containerClasses)[keyof typeof containerClasses];

export const containerTypes = {
  /** 20 футов */
  "20f": "cont20f",
  /** 20 футов */
  "40f": "cont40f",
  /** 45 футов */
  "45f": "cont45f",
  /** 2/20 футов */
  "2x20f": "cont2x20f",
  /** 20 реф */
  "20ref": "cont20ref",
  /** 40 реф */
  "40ref": "cont40ref",
  /** 45 реф */
  "45ref": "cont45ref",
  /** 20 танк */
  "20tank": "cont20tank",
  /** 40 танк */
  "40tank": "cont40tank",
};

export const options = {
  /** Опасный груз */
  DANGEROUS_CARGO: "dangerous_cargo",
  GENSET_REQUIRED: "genset_required",
};

// export type ContainerTypeType = typeof containerTypes[keyof typeof containerTypes];

export const terminals = {
  /** конт. площадка */
  PLATFORM: "platform",
  /** ж/д терминал */
  TERMINAL: "terminal",
};

export type TerminalType = (typeof terminals)[keyof typeof terminals];

export const howToCarryOptionsMap = {
  [containerTypes["20f"]]: ["cont20_any_plat", "cont20_cut40plat", "cont20_middle40plat", "cont20_middle_any_plat"],
  [containerTypes["2x20f"]]: ["cont2x20_split40plat", "cont2x20_platform40doors_out"],
  [containerTypes["20ref"]]: ["cont20_any_plat", "cont20_cut40plat", "cont20_middle40plat", "cont20_middle_any_plat"],
  [containerTypes["20tank"]]: ["cont20tank_only_special_plat", "cont20tank_platform40floor", "cont20tank_any_plat"],
  [containerTypes["40tank"]]: ["cont40tank_only_special_plat", "cont40tank_any_plat"],
};

export const orderStatuses = {
  CREATED_CUSTOMER: "created_customer",
  TRANSPORT_HUNTING_CUSTOMER: "transport_hunting_customer",
  TRANSPORT_ASSIGNED_CUSTOMER: "transport_assigned_customer",
  CONTAINER_RECEIVED_CUSTOMER: "container_received_customer",
  CONTAINER_LOADING_CUSTOMER: "container_loading_customer",
  CONTAINER_UNLOADING_CUSTOMER: "container_unloading_customer",
  LOADING_FINISHED_CUSTOMER: "loading_finished_customer",
  UNLOADING_FINISHED_CUSTOMER: "unloading_finished_customer",
  CONTAINER_DELIVERED_CUSTOMER: "container_delivered_customer",
  WAITING_PAYMENT_CUSTOMER: "waiting_payment_customer",
  COMPLETED_CUSTOMER: "completed_customer",
  CANCELED_CUSTOMER: "canceled_customer",
  SUSPENDED_CUSTOMER: "suspended_customer",
  TRANSPORT_HUNTING_MOVER: "transport_hunting_mover",
  TRANSPORT_ASSIGNED_MOVER: "transport_assigned_mover",
  CONTAINER_RECEIVED_MOVER: "container_received_mover",
  CONTAINER_LOADING_MOVER: "container_loading_mover",
  CONTAINER_UNLOADING_MOVER: "container_unloading_mover",
  LOADING_FINISHED_MOVER: "loading_finished_mover",
  UNLOADING_FINISHED_MOVER: "unloading_finished_mover",
  CONTAINER_DELIVERED_MOVER: "container_delivered_mover",
  WAITING_ORIGINALS_DOCUMENTS_MOVER: "waiting_originals_documents_mover",
  WAITING_PAYMENT_MOVER: "waiting_payment_mover",
  COMPLETED_MOVER: "completed_mover",
  CANCELED_MOVER: "canceled_mover",
  SUSPENDED_MOVER: "suspended_mover",
} as const;

export type OrderStatusType = (typeof orderStatuses)[keyof typeof orderStatuses];

export const documentTypes = {
  PASSPORT_MAIN: "passport_main",
  PASSPORT_REGISTRATION: "passport_registration",
  INDIVIDUAL_PASSPORT_MAIN: "individual_passport_main",
  INDIVIDUAL_PASSPORT_REGISTRATION: "individual_passport_registration",
  SELFWORKER_PASSPORT_MAIN: "passport_main_selfworker",
  SELFWORKER_PASSPORT_REGISTRATION: "passport_registration_selfworker",
  CUSTOMER_COMPLETE_POWER_OF_ATTORNEY: "customer_complete_power_of_attorney",
  MOVER_COMPLETE_POWER_OF_ATTORNEY: "mover_complete_power_of_attorney",
  DOVERENNOST_MANAGER_CUSTOMER: "doverennost_manager_customer",
  DOVERENNOST_MANAGER_MOVER: "doverennost_manager_mover",
  DRIVER_PASSPORT_MAIN: "driver_passport_main",
  DRIVER_PASSPORT_REGISTRATION: "driver_passport_registration",
  DRIVER_LICENSE_FRONT: "driver_license_front",
  DRIVER_LICENSE_BACK: "driver_license_back",
  TRUCK_PTS_FRONT: "truck_pts_front",
  TRUCK_PTS_BACK: "truck_pts_back",
  TRUCK_STS_FRONT: "truck_sts_front",
  TRUCK_STS_BACK: "truck_sts_back",
  TRUCK_RENT_CONTRACT: "truck_lease_contract",
  TRUCK_LEASING_CONTRACT: "truck_leasing_contract",
  TRUCK_FREE_USE_CONTRACT: "truck_free_use_contract",
  SEMI_TRAILER_PTS_FRONT: "semi_trailer_pts_front",
  SEMI_TRAILER_PTS_BACK: "semi_trailer_pts_back",
  SEMI_TRAILER_STS_FRONT: "semi_trailer_sts_front",
  SEMI_TRAILER_STS_BACK: "semi_trailer_sts_back",
  SEMI_TRAILER_RENT_CONTRACT: "semi_trailer_lease_contract",
  SEMI_TRAILER_LEASING_CONTRACT: "semi_trailer_leasing_contract",
  SEMI_TRAILER_FREE_USE_CONTRACT: "semi_trailer_free_use_contract",
} as const;

export const tariffTypes = {
  DEFAULT: "nds20",
  NDS: "nds0",
  NONDS: "noNds",
} as const;

type TariffTypeKeys = keyof typeof tariffTypes;
export type TariffTypeValue = (typeof tariffTypes)[TariffTypeKeys];

export const tariffNames = {
  [tariffTypes.NDS]: "НДС 0",
  [tariffTypes.DEFAULT]: "НДС 20",
  [tariffTypes.NONDS]: "Без НДС",
} as const;

export const withTariffNames: Record<string, string> = {
  [tariffTypes.NDS]: "с НДС 0",
  [tariffTypes.DEFAULT]: "с НДС 20",
} as const;

export const ROUBLE_SIGN = "₽";

export const truckOwnershipForms = {
  LEASING: "leasing",
  RENT: "rent",
  JOINT_PROPERTY: "joint_property",
  FREE_USE: "free_use",
};

const currentYear = new Date().getFullYear();
const yearThreshold = parseInt(process.env.NEXT_PUBLIC_PASSPORT_ISSUE_DATE_MAX_YEARS_AGO!);
export const PASSPORT_ISSUE_DATE_MIN = new Date(`${currentYear - yearThreshold}-01-01`);

/** оффсет для картинки с картой и местоположением компании */
export const CONTACTS_PAGE_MAP_OFFSET_Y = 0.003 as const;

/** виды сообщений, приходящих с сервера */
export const serverResponseMessageTypes = {
  ERROR: "error",
  WARNING: "warning",
  SUCCESS: "success",
} as const;

type ServerResponseMessageKeys = keyof typeof serverResponseMessageTypes;
export type ServerResponseMessageValue = (typeof serverResponseMessageTypes)[ServerResponseMessageKeys];

export const statusStepValues = {
  INACTIVE: "inactive",
  PASSED: "passed",
  ACTIVE: "active",
  ERROR: "error",
} as const;

export const jwtRoles = {
  MOVER: "ROLE_MOVER",
  CUSTOMER: "ROLE_CUSTOMER",
  ADMIN: "ROLE_ADMIN",
  COMPANY_COMPLETED: "ROLE_COMPANY_COMPLETED",
  SUPERUSER: "ROLE_SUPERUSER",
  SUPPORT: "ROLE_SUPPORT",
  BLOCKED: "ROLE_BLOCKED",
  ATI_AUTHORIZED: "ROLE_ATI_AUTHORIZED",
  MANAGER: "ROLE_MANAGER",
  SYSTEM: "ROLE_SYSTEM",
  DEVELOPMENT: "ROLE_DEVELOPMENT",
  LOGIST: "ROLE_LOGIST",
  CEO: "ROLE_CEO",
  ACCOUNTANT: "ROLE_ACCOUNTANT",
} as const;

type JWTRoleKeys = keyof typeof jwtRoles;
export type JWTRoleValue = (typeof jwtRoles)[JWTRoleKeys];

export const TOAST_LIFECYCLE_DURATION = 10000 as const;

export const invalidDateStates = {
  MINIMUM_RANGE: "minimum-range",
  LOCAL_TIME: "local-time",
  INCORRECT_RANGE: "incorrect-range",
  RANGE_LOCAL_TIME: "range-local-time",
  EIGHT_HOURS_FROM_LOCAL_TIME: "eight-hours-from-local-time",
} as const;

type InvalidDateKeys = keyof typeof invalidDateStates;
export type InvalidDateValue = (typeof invalidDateStates)[InvalidDateKeys];
export type InvalidDateType = InvalidDateValue | null;

export const kuberFormattedPhoneNumber = "8 800 250 24 02" as const;
export const kuberPhoneNumber = "88002502402" as const;

export const keyboardNumbers = {
  ENTER: 13,
} as const;

export const kuberHeadTitle = "KUBER" as const;
export const initialAdminPage = "/admin/dashboard" as const;
export const initialDashboardPage = "/dashboard/orders" as const;

export const citizenshipTypes = {
  RU: "ru",
  OTHER: "other",
} as const;

export type CitizenshipTypeValue = (typeof citizenshipTypes)[keyof typeof citizenshipTypes];

export const citizenshipNames = {
  [citizenshipTypes.RU]: "Российская Федерация",
  [citizenshipTypes.OTHER]: "Граждане остальных стран",
} as const;
