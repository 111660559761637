import React from "react";
import clsx from "clsx";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: "default" | "small" | "large";
  borderless?: boolean;
}

const GhostButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ size = "default", borderless = true, children, className, ...rest }, ref) => {
    return (
      <button
        ref={ref}
        className={clsx(
          "rounded-2xl",
          size === "default" && "w-11 h-11",
          size === "large" && "w-14 h-14",
          "flex items-center justify-center",
          !borderless && "text-primary hover:text-primary-dark active:text-secondary",
          !borderless && "border border-primary hover:border-primary-dark active:border-secondary",
          className,
        )}
        {...rest}
      >
        {children}
      </button>
    );
  },
);

export default GhostButton;
