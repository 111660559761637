/**
 * Перевозчик
 * docs: http://84.201.143.108:8990/front/#tag/Perevozchik
 */
export const CONNECT_ATI = "v1.0/mover/ati/auth";

/**
 * Личный кабинет
 * docs: http://84.201.143.108:8990/front/#tag/Lichnyj-kabinet
 */
export const CREATE_COMPANY = "v1.0/account/company/new";
export const DOWNLOAD_FILE = "v1.0/account/download/file";
export const GET_ACCOUNT_INFO = "v1.0/account/information";

/**
 * Настройки аккаунта
 * docs: http://84.201.143.108:8990/front/#tag/Nastrojki-akkaunta
 */
export const DELETE_SESSIONS = "v1.0/account/delete-sessions";
export const CHANGE_CURRENT_EMAIL = "v1.0/account/email/change";
export const VERIFY_CONFIRM_EMAIL_CODE = "v1.0/account/email/confirm";
export const SEND_CONFIRMATION_CODE = "v1.0/account/email/send-code-new";
export const CONFIRM_CURRENT_VERIFIED_EMAIL = "v1.0/account/email/send-code-verified";
export const SEND_VERIFICATION_CODE_EMAIL = "v1.0/account/email/verification";
export const CHANGE_PASSWORD = "v1.0/account/password-change";
export const CHANGE_CURRENT_PHONE_NUMBER = "v1.0/account/phone/change";
export const CONFIRM_CURRENT_VERIFIED_PHONE_NUMBER = "v1.0/account/phone/send-code-verified";
export const GET_SESSIONS = "v1.0/account/sessions-list";
export const CONFIRM_EMAIL_VIA_LINK = "v1.0/account/email/confirm-via-link";

/**
 * Авторизация
 * docs: http://84.201.143.108:8990/front/#tag/Avtorizaciya
 */
export const LOGOUT = "v1.0/account/logout";
export const LOGIN = "v1.0/auth/login";
export const REFRESH_TOKEN = "v1.0/auth/token/refresh/new";

/**
 * Регистрация
 * docs: http://84.201.143.108:8990/front/#tag/Registraciya
 */
export const SEARCH_COMPANY = "v1.0/account/search-company";
export const CONFIRM_EMAIL = "v1.0/public/email/confirm";
export const CHECK_PHONE = "v1.0/public/phone/check";
export const CREATE_ACCOUNT_CUSTOMER = "v1.0/registration/customer";
export const CREATE_ACCOUNT_MOVER = "v1.0/registration/mover";
export const CHECK_EMAIL_AVAILABILITY = "v1.0/registration/email/available";
export const PHONE_TOKEN = "v1.0/registration/phone/token";
export const PHONE_VERIFY = "v1.0/registration/phone/verify";

/**
 * Админ. Баланс
 * docs: http://84.201.143.108:8990/front/#tag/Admin.-Balans
 */
export const ADD_CREDIT = "/v1.0/admin/billing/add-credit";
export const MAKE_BANK_PAYMENT = "/v1.0/admin/billing/bank/bill-invoice";
export const CREATE_MOVER_WITHDRAW_REQUEST = "/v1.0/admin/billing/withdraw/mover-balance-invoice";
export const CREATE_USER_WITHDRAW_REQUEST = "/v1.0/admin/billing/withdraw/user-balance-invoice";
export const UPLOAD_BANK_DOC = "/v1.0/admin/billing/bank/statement-bill-invoice";
export const GET_WITHDRAW_LIST = "/v1.0/admin/billing/get-application-withdraw";
export const GET_CREDIT_LIST = "/v1.0/admin/billing/get-credit-issued-customer";
export const GET_OPERATION_LIST = "/v1.0/admin/billing/get-history-personal-operation";
export const GET_POSTPAYMENT_LIST = "/v1.0/admin/billing/get-credit-order-customer";
export const GET_INVOICE_LIST = "/v1.0/admin/billing/get-invoice";
export const GET_COMPLETED_WORK_ACT = "/v1.0/admin/billing/get-completed-work-act";
export const GET_BANK_PAYMENT_LIST = "/v1.0/admin/billing/get-bank-bill-operation";
export const CLOSE_POSTPAYMENT = "/v1.0/admin/billing/advanced-credit-repayment";

/**
 * Админ. Документы
 * docs: http://84.201.143.108:8990/front/#tag/Admin.-Dokumenty
 */
export const CREATE_TN_2022_MANUALLY = "v1.0/admin/docs/create-tn-2022-manually";
export const VERIFY_MANUAL_DIGITIZATION = "v1.0/admin/docs/digitizations/manual/digitize";
export const GET_ALL_MANUAL_DIGITIZATIONS = "v1.0/admin/docs/digitizations/manual/get-all";
export const GENERATE_CUSTOMER_DOCUMENT_PACKAGE = "/v1.0/admin/docs/document-package/customer/generate";
export const FIXATE_SEND_ORIGINALS = "v1.0/admin/docs/document-package/customer/set-original-documents-sent";
export const GET_DIGITIZATION_LIST = "/v1.0/admin/digitization/get-list";
export const DECLINE_DOCUMENT = "/v1.0/admin/digitization/decline";
export const CHECK_ORDER_DOC_PAC = "v1.0/admin/docs/orders-check-doc-pac";

/**
 * Админ. Документы перевозчика
 * docs: http://84.201.143.108:8990/front/#tag/Admin.-Dokumenty-Perevozchika
 */
export const GET_MOVER_DOCS_LIST = "/v1.0/admin/docs/document-package/mover/get-file-list";
export const GET_MOVER_DOCS_PACKAGE = "/v1.0/admin/docs/document-package/mover/get-document-package-list";
export const GET_CUSTOMER_DOCS_PACKAGE = "/v1.0/admin/docs/document-package/customer/get-document-package-list";
export const ADD_FILE_TO_EXISTING_MOVER_PACKAGE = "/v1.0/admin/docs/document-package/mover/add-file";
export const CREATE_NEW_MOVER_PACKAGE = "/v1.0/admin/docs/document-package/mover/create-new";
export const UPDATE_MOVER_DOC = "/v1.0/admin/docs/document-package/mover/update-file";
export const UPLOAD_MOVER_DOC = "/v1.0/admin/docs/document-package/mover/upload-file";
export const GET_MOVER_TRANSPORT_DOCS_LIST = "/v1.0/admin/docs/document-package/mover/get-transport-documents-list";
export const SET_TRANSPORT_ORIGINAL_DOCS_RECEIVED =
  "/v1.0/admin/docs/document-package/mover/transport/set-original-documents-received";
export const SET_ACCOUNTANCY_ORIGINAL_DOCS_RECEIVED =
  "/v1.0/admin/docs/document-package/mover/accountancy/set-original-documents-received";
export const GET_BRIEF_DOC_PACKAGE_LIST = "/v1.0/admin/docs/document-package/mover/get-brief-info-list";

/**
 * Админ. Меню
 * docs: https://apidoc.kuber.ru/front/#tag/Admin.-Menyu
 */
export const GET_ADMIN_MENU = "v1.0/public/admin_menu_config";

/**
 * Админ. Заказы
 * docs: http://84.201.143.108:8990/front/#tag/Admin.-Zakazy
 */
export const ADD_CONTAINER_TO_ORDER = "v1.0/admin/orders/add-container-number";
export const GENERATE_CLOSING_DOCUMENTS = "v1.0/admin/orders/document/generate-closing";
export const FINISH_ORDER_FOR_CUSTOMER = "v1.0/admin/orders/finish-order-customer";
export const FINISH_ORDER_FOR_MOVER = "v1.0/admin/orders/finish-order-mover";
export const GET_CUSTOMER_ORDERS_READY_FOR_FINISH = "/v1.0/admin/orders/get-all-ready-closed-customer";
export const GET_MOVER_ORDERS_READY_FOR_FINISH = "/v1.0/admin/orders/get-all-ready-closed-mover";
export const GET_ORDERS_WITHOUT_CONTAINERS = "v1.0/admin/orders/get-all-without-container-number";
export const SET_ORDER_STATUS = "v1.0/admin/orders/set-status";
export const GET_ORDER_LIST = "/v1.0/admin/orders/get-list-admin";
export const GET_CUSTOMER_ORDER_STATUS_CHAIN = "v1.0/admin/orders/customer/status-chain/";
export const GET_MOVER_ORDER_STATUS_CHAIN = "/v1.0/admin/orders/mover/status-chain/";
export const ADD_DOC_TO_ORDER = "/v1.0/admin/orders/document/add";
export const CANCEL_ADMIN_ORDER = "/v1.0/admin/orders/cancel-order";
export const CHANGE_FINISH_DATE = "/v1.0/admin/orders/container-delivery-date/change";
export const GET_BRIEF_ORDER_LIST = "/v1.0/admin/orders/brief-info-list";
export const GET_BRIEF_DOWNTIME_ORDER_LIST = "/v1.0/admin/orders/get-user-downtime";
export const ADD_DOWNTIME = "/v1.0/admin/orders/add-downtime";

/**
 * Админ. Пользователи
 * docs: http://84.201.143.108:8990/front/#tag/Admin.-Polzovateli
 */
export const EDIT_USER = "/v1.0/admin/security/edit-profile";
export const GET_USER_ROLES = "/v1.0/admin/security/get-user-roles";
export const GET_USERS = "/v1.0/admin/security/get-users-list";
export const GET_USER_INFO = "/v1.0/admin/security/get-user-info";
export const GET_USER_ID_BY_PARAMS = "/v1.0/admin/security/user-id";
export const ADD_ROLES = "/v1.0/admin/security/roles/change";
export const GET_USER_DICTIONARY = "/v1.0/admin/security/get-user-list-light";
export const GET_USER_AUTH_TOKENS = "/v1.0/admin/security/get-spoofing";
export const GET_LOGIST_LIST = "/v1.0/admin/security/get-logist-list";
export const CHANGE_RESPONSIBLE_LOGIST = "/v1.0/admin/security/change-responsible-logist-for-customer";

/**
 * Админ. Компании
 * docs: http://84.201.143.108:8990/front/#tag/Admin.-Kompanii
 */
export const GET_COMPANY_LIST = "/v1.0/admin/security/get-company-with-1c-info-list";
export const CHANGE_CONTRACTOR_INFO = "/v1.0/admin/security/update-contractor-1c";

/**
 * Админ. Новости
 * docs: http://84.201.143.108:8990/front/#tag/Admin.-Novosti
 */

/**
 * Админ. Служба поддержки
 * docs: http://84.201.143.108:8990/front/#tag/Admin.-Sluzhba-podderzhki
 */
export const ADMIN_TICKET_CHAT_DIRECTORY = "v1.0/admin/support-ticket/short-tickets";
export const ADMIN_TICKET_CHAT_LIST = "v1.0/admin/support-ticket/ticket-list";
export const ADMIN_CHANGE_TICKET_PINNED_STATUS = "v1.0/admin/support-ticket/change-ticket-pinned";
export const ADMIN_TICKET_CHAT_MESSAGE_LIST = "v1.0/admin/support-ticket/message-list";
export const CLOSE_TICKET_CHAT = "v1.0/admin/support-ticket/close-ticket";
export const ADMIN_UNREAD_MESSAGE_COUNT = "v1.0/admin/support-ticket/unread-message-count";
export const SEND_ADMIN_MESSAGE = "v1.0/admin/support-ticket/send-message";
export const MARK_ADMIN_MESSAGE_AS_READ = "v1.0/admin/support-ticket/read-all-unread-messages";
export const OPEN_ADMIN_TICKET_CHAT = "v1.0/admin/support-ticket/open-ticket";
export const MOVE_TICKET_CHAT_MESSAGES = "v1.0/admin/support-ticket/move-message";
export const DELETE_TICKET_CHAT_MESSAGES = "v1.0/admin/support-ticket/delete-message";
export const GET_CLOSED_TICKET_INFO = "v1.0/admin/support-ticket/closed-ticket-info";
export const GET_MOVED_FROM_MESSAGE_INFO = "v1.0/admin/support-ticket/get-moved-from-message-info";
export const GET_MOVED_MESSAGE_INFO = "v1.0/admin/support-ticket/get-moved-message-info";
export const GET_TICKET_CHAT_ID = "v1.0/admin/support-ticket/get-ticket-id";

/**
 * Админ. Расчёт тарифа
 * docs: http://84.201.143.108:8990/front/#tag/Admin.-Raschyot-tarifa
 */
export const CALCULATE_TARIFF_FIRST_MILE_ADMIN = "/v1.0/admin/tariff/first-mile/calculate";
export const CALCULATE_TARIFF_LAST_MILE_ADMIN = "/v1.0/admin/tariff/last-mile/calculate";
export const CALCULATE_TARIFF_EMPTY_MILE_ADMIN = "/v1.0/admin/tariff/empty-mile/calculate";
export const RECALCULATE_TARIFF_ADMIN = "/v1.0/admin/tariff/reculation";
export const CHANGE_TARIFF_ADMIN = "/v1.0/admin/orders/change-tariff";
export const CALCULATE_DOWNTIME = "v1.0/admin/tariff/downtime/calculate";

/**
 * Админ. Промокоды
 * docs: http://84.201.143.108:8990/front/#tag/Admin.-Promokody
 */
export const GET_PROMOS = "v1.0/admin/tariff/promocodes/get-all";
export const GET_PROMO_INFO = "v1.0/admin/tariff/promocodes/get-info";
export const CREATE_PROMO = "v1.0/admin/tariff/promocodes/create";
export const CREATE_SPECIAL_PROMO = "v1.0/admin/tariff/promocodes-special/create";
export const CHANGE_PROMO_STATUS = "v1.0/admin/tariff/promocodes/update-status";

/**
 * Админ. Справочники перевозчика
 * docs: http://84.201.143.108:8990/front/#tag/Admin.-Spravochniki-perevozchika
 */
export const SYNC_TRANSPORT_WITH_ATI = "/v1.0/admin/transport/manual-ati-sync";
export const CHANGE_TRANSPORT_OWNER = "/v1.0/admin/transport/change-transport-owner";
export const GET_DRIVER_LIST = "/v1.0/admin/transport/driver/get-list";
export const GET_TRUCK_LIST = "/v1.0/admin/transport/truck/get-list";
export const GET_SEMITRAILER_LIST = "/v1.0/admin/transport/semi-trailer/get-list";
export const GET_DRIVER_WORKFLOW_LIST = "/v1.0/admin/workflow/driver-list";
export const GET_TRUCK_WORKFLOW_LIST = "/v1.0/admin/workflow/truck-list";
export const GET_SEMITRAILER_WORKFLOW_LIST = "/v1.0/admin/workflow/semi-trailer-list";

/**
 * Админ. Виджеты
 * docs: http://84.201.143.108:8990/front/#tag/Admin.-Vidzhety
 */
export const GET_WIDGETS = "/v1.0/admin/widgets/get-admin-widget-order-aggregate-filter";
export const GET_WIDGET_COMPANY = "/v1.0/admin/widgets/get-admin-widget-company-order-aggregate-filter";
/**
 * Админ. Верификация пользователя
 * docs: http://84.201.143.108:8990/front/#tag/Admin.-Verifikaciya-polzovatelya
 */
export const CHECK_VERIFICATION_MANUALLY = "v1.0/admin/workflow/send-manual-check";
export const GET_ALL_VERIFICATIONS = "/v1.0/admin/workflow/check-list";
export const GET_USER_WORKFLOW_LIST = "/v1.0/admin/workflow/user-list";
export const GET_ALL_WORKFLOW_CHECK_LIST = "/v1.0/admin/workflow/all-manual-checks";
export const GET_CHECK_USER_DETAILS = "/v1.0/admin/workflow/check-details-user";

/**
 * Баланс
 * docs: http://84.201.143.108:8990/front/#tag/Balans
 */
export const GET_MOVER_BALANCE = "v1.0/billing/mover/get-balance";
export const GET_CUSTOMER_BALANCE = "v1.0/billing/customer/get-balance";
export const GET_MOVER_BILLING_INFO = "v1.0/billing/mover/get-info";
export const GET_CUSTOMER_BILLING_INFO = "v1.0/billing/customer/get-info";
export const DEPOSIT_CUSTOMER = "v1.0/billing/customer/add/invoicing";
export const DEPOSIT_MOVER = "v1.0/billing/mover/add/invoicing";
export const WITHDRAW_CUSTOMER = "v1.0/billing/customer/withdraw/balance-invoice";
export const WITHDRAW_MOVER = "v1.0/billing/mover/withdraw/balance-invoice";
export const GET_CUSTOMER_INVOICES = "v1.0/billing/customer/get-invoices";
export const GET_MOVER_INVOICES = "v1.0/billing/mover/get-invoices";
export const GET_CUSTOMER_HISTORY = "v1.0/billing/customer/get-history-operation";
export const GET_MOVER_HISTORY = "v1.0/billing/mover/get-history-operation";
export const GET_CUSTOMER_FINES = "v1.0/billing/customer/get-fines";
export const GET_MOVER_FINES = "v1.0/billing/mover/get-fines";
export const GET_CUSTOMER_WITHDRAWALS = "v1.0/billing/customer/get-application-withdraw";
export const GET_MOVER_WITHDRAWALS = "v1.0/billing/mover/get-application-withdraw";
export const CANCEL_CUSTOMER_WITHDRAW_REQUEST = "v1.0/billing/customer/canceled/withdraw";
export const CANCEL_MOVER_WITHDRAW_REQUEST = "v1.0/billing/mover/canceled/withdraw";
export const GET_CUSTOMER_BILLING_CREDITS = "v1.0/billing/customer/get-credit";
export const PAY_CUSTOMER_CREDIT = "v1.0/billing/customer/advanced/credit";
export const GET_MOVER_COMPLETED_WORK_ACT = "v1.0/billing/mover/get-completed-work-act";
export const AUTO_WITHDRAW_MOVER = "v1.0/billing/mover/withdraw/auto-balance";

/**
 * Профиль
 * docs: http://84.201.143.108:8990/front/#tag/Profil
 */
export const GET_MOVER_PROFILE = "v1.0/mover/profile";
export const GET_CUSTOMER_PROFILE = "v1.0/customer/profile";
export const UPDATE_CUSTOMER_PROFILE_INFO = "v1.0/customer/profile/new";
export const UPDATE_MOVER_PROFILE_INFO = "v1.0/mover/profile/new";
export const UPDATE_CUSTOMER_COMPANY_INFO = "v1.0/customer/company/new";
export const UPDATE_MOVER_COMPANY_INFO = "v1.0/mover/company/new";
export const UPDATE_CUSTOMER_BILL_INFO = "v1.0/customer/bill/new";
export const UPDATE_MOVER_BILL_INFO = "v1.0/mover/bill/new";
export const UPLOAD_NEW_CUSTOMER_DOCUMENT = "v1.0/customer/document/new";
export const UPLOAD_NEW_MOVER_DOCUMENT = "v1.0/mover/document/new";

/**
 * Справочники платформы
 * docs: http://84.201.143.108:8990/front/#tag/Spravochniki-platformy
 */
export const GET_ORDERS_DIRECTORY = "v1.0/directory/orders";
export const GET_BOXES_DIRECTORY = "v1.0/directory/boxes";
export const GET_ALL_DOCUMENT_DIRECTORY = "v1.0/directory/document/all-v2";
export const GET_CUSTOMER_DOCUMENT_DIRECTORY = "v1.0/directory/document/customer";
export const GET_MOVER_DOCUMENT_DIRECTORY = "v1.0/directory/document/mover";
export const GET_SUPPORT_DIRECTORY = "v1.0/directory/support/all";
export const GET_BILLING_DIRECTORY = "v1.0/directory/billing";
export const GET_TRANSPORT_DIRECTORY = "v1.0/directory/transport/all";
export const GET_SECURITY_DIRECTORY = "v1.0/directory/security/all";
export const GET_DOCS_PACKAGE_DIRECTORY = "v1.0/directory/docs/all";
export const GET_NDS_DIRECTORY = "v1.0/directory/docs/all";
export const GET_WORKFLOW_DIRECTORY = "v1.0/directory/workflow/all";

/**
 * Документы
 * docs: http://84.201.143.108:8990/front/#tag/Dokumenty
 */
export const GET_DOCS_LIST = "/v1.0/docs/customer/package-list";
export const GET_DOCS_PACKAGE = "/v1.0/docs/customer/package/";

/**
 * Публичные страницы
 * docs: http://84.201.143.108:8990/front/#tag/Publichnye-stranicy
 */
export const GET_NEWS_API = "v1.0/main-page/news";
export const GET_ONE_NEWS_API = "v1.0/main-page/news/item";
export const GET_CONTACTS_API = "v1.0/main-page/contacts";
export const GET_MAIN_PAGE_DATA_API = "v1.0/main-page/customer";
export const GET_MOVER_PAGE_DATA_API = "v1.0/main-page/mover";
export const GET_ABOUT_PAGE_DATA_API = "v1.0/main-page/about";
export const GET_ACTUAL_ORDERS = "v1.0/public/orders/last-actual";
export const POST_MAIN_FORM = "v1.0/main-page/main-forma/send";
export const RESET_PASSWORD = "v1.0/public/password/reset";
export const CHANGE_PASSWORD_PUBLIC = "v1.0/public/password/change";
export const GET_DRIVER_TASK = "v1.0/public/orders/driver-task";

/**
 * Справочники перевозчика
 * docs: http://84.201.143.108:8990/front/#tag/Spravochniki-perevozchika
 */
export const ARCHIVE_ENTITY = "v1.0/mover/transport/archive/add";
export const UNARCHIVE_ENTITY = "v1.0/mover/transport/archive/withdraw";
export const GET_DRIVERS = "v1.0/mover/get-drivers";
export const ADD_DRIVER = "v1.0/mover/driver/new";
export const UPDATE_DRIVER = "v1.0/mover/driver/update";
export const GET_TRUCKS = "v1.0/mover/get-trucks";
export const ADD_TRUCK = "v1.0/mover/truck/new";
export const UPDATE_TRUCK = "v1.0/mover/truck/update";
export const GET_SEMI_TRAILERS = "v1.0/mover/get-semi-trailers";
export const ADD_SEMI_TRAILER = "v1.0/mover/semi-trailer/new";
export const UPDATE_SEMI_TRAILER = "v1.0/mover/semi-trailer/update";
export const UPDATE_ENTITY_DOCUMENT = "v1.0/mover/transport/document/update";
export const GET_CHECK_DRIVER_DETAILS = "/v1.0/admin/workflow/check-details-driver";
export const GET_CHECK_SEMI_TRAILER_DETAILS = "/v1.0/admin/workflow/check-details-semi-trailer";
export const GET_CHECK_TRUCK_DETAILS = "/v1.0/admin/workflow/check-details-truck";
export const GET_DRIVERS_LIST = "v1.0/mover/driver/get-list";
export const GET_TRUCKS_LIST = "v1.0/mover/truck/get-list";
export const GET_SEMITRAILERS_LIST = "v1.0/mover/semiTrailer/get-list";
export const GET_DRIVER = "v1.0/mover/driver/get-card";
export const GET_TRUCK = "v1.0/mover/truck/get-card";
export const GET_SEMITRAILER = "v1.0/mover/semiTrailer/get-card";

/**
 * Заказы
 * docs: http://84.201.143.108:8990/front/#tag/Zakazy
 */
export const CREATE_ORDER_FIRST_MILE = "v1.0/orders/new/first-mile";
export const CREATE_ORDER_LAST_MILE = "v1.0/orders/new/last-mile";
export const CREATE_ORDER_EMPTY_CONTAINER = "v1.0/orders/new/empty-container";
export const SEARCH_ORDERS_BY_FILTER = "v1.0/orders/actual";
export const SEARCH_ARCHIVE_ORDERS_BY_FILTER = "v1.0/orders/archive";
export const SEARCH_ORDERS_BY_QUERY = "v1.0/orders/search";
export const GET_ORDER_STATUS_CHAIN = "v1.0/orders/status-chain";
export const GET_ORDER_BY_ID = "v1.0/orders/get-order";
export const GET_ORDER_MAP_BY_ID = "v1.0/orders/route-map";
export const CANCEL_ORDER = "v1.0/orders/cancel";
export const UPDATE_TARIFF = "v1.0/orders/update-tariff";
export const CONFIRM_TRANSPORT_INFO = "v1.0/orders/confirm-transport-sent";
export const CHANGE_CONTAINER_DELIVERY_SPOT = "v1.0/orders/container-delivery-spot/update";

/**
 * KUBERbox
 * docs: http://84.201.143.108:8990/front/#tag/KUBERbox
 */
export const CHECK_BEFORE_PRINT_TN = "v1.0/public/orders/check-before-print-tn";
export const PRINT_TN = "v1.0/public/orders/print-tn";

/**
 * Расчёт тарифа
 * docs: http://84.201.143.108:8990/front/#tag/Raschyot-tarifa
 */
export const CALCULATE_TARIFF_EMPTY_CONTAINER = "v1.0/public/tariff/empty-container/calculate";
export const CALCULATE_TARIFF_FIRST_MILE = "v1.0/public/tariff/first-mile/calculate";
export const CALCULATE_TARIFF_LAST_MILE = "v1.0/public/tariff/last-mile/calculate";
export const CALCULATE_TARIFF_EMPTY_CONTAINER_PRIVATE = "v1.0/tariff/empty-mile/calculate";
export const CALCULATE_TARIFF_FIRST_MILE_PRIVATE = "v1.0/tariff/first-mile/calculate";
export const CALCULATE_TARIFF_LAST_MILE_PRIVATE = "v1.0/tariff/last-mile/calculate";

/**
 * Служба поддержки
 * docs: http://84.201.143.108:8990/front/#tag/Sluzhba-podderzhki
 */
export const GET_TICKETS = "v1.0/support-ticket/list";
export const OPEN_CUSTOMER_TICKET_CHAT = "v1.0/support-ticket/customer/open-ticket";
export const OPEN_MOVER_TICKET_CHAT = "v1.0/support-ticket/mover/open-ticket";
export const SEND_CUSTOMER_TICKET_CHAT_MESSAGE = "v1.0/support-ticket/customer/send-message";
export const SEND_MOVER_TICKET_CHAT_MESSAGE = "v1.0/support-ticket/mover/send-message";
export const CUSTOMER_ORDERS_LIST = "v1.0/support-ticket/customer/orders";
export const MOVER_ORDERS_LIST = "v1.0/support-ticket/mover/orders";
export const CUSTOMER_TICKET_CHAT_MESSAGE_LIST = "v1.0/support-ticket/customer/message-list";
export const MOVER_TICKET_CHAT_MESSAGE_LIST = "v1.0/support-ticket/mover/message-list";
export const CUSTOMER_TICKET_CHAT_LIST = "v1.0/support-ticket/customer/ticket-list";
export const MOVER_TICKET_CHAT_LIST = "v1.0/support-ticket/mover/ticket-list";
export const CUSTOMER_TICKET_CHAT_DIRECTORY = "v1.0/support-ticket/customer/tickets";
export const MOVER_TICKET_CHAT_DIRECTORY = "v1.0/support-ticket/mover/tickets";
export const CUSTOMER_CHANGE_TICKET_PINNED_STATUS = "v1.0/support-ticket/customer/change-pinned-status";
export const MOVER_CHANGE_TICKET_PINNED_STATUS = "v1.0/support-ticket/mover/change-pinned-status";
export const CUSTOMER_MARK_MESSAGE_AS_READ = "v1.0/support-ticket/customer/mark-message-as-read";
export const MOVER_MARK_MESSAGE_AS_READ = "v1.0/support-ticket/mover/mark-message-as-read";
export const CUSTOMER_UNREAD_MESSAGE_COUNT = "v1.0/support-ticket/customer/unread-message-count";
export const MOVER_UNREAD_MESSAGE_COUNT = "v1.0/support-ticket/mover/unread-message-count";
export const CUSTOMER_GET_MOVED_MESSAGE_FROM_INFO = "v1.0/support-ticket/customer/get-moved-from-message-info";
export const MOVER_GET_MOVED_MESSAGE_FROM_INFO = "v1.0/support-ticket/mover/get-moved-from-message-info";
export const CUSTOMER_GET_MOVED_MESSAGE_TO_INFO = "v1.0/support-ticket/customer/get-moved-message-info";
export const MOVER_GET_MOVED_MESSAGE_TO_INFO = "v1.0/support-ticket/mover/get-moved-message-info";

/**
 * Промокоды
 * docs: http://84.201.143.108:8990/front/#tag/Promokody
 */

export const GET_UNUSED_CUSTOMER_PROMOS = "v1.0/tariff/promocodes-order/get-all/";
export const GET_ALL_CUSTOMER_PROMOS = "v1.0/tariff/promocodes/get-all/";
/* Misc */
export const DOWNLOAD_FILE_PROXY_URL = "download/file";
export const CLEAN_ADDRESS = "v1.0/public/tariff/clean-address";
export const GET_GEO_POSITION = "v1.0/public/tariff/geo-address";
