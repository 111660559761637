import React from "react";

function Success() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="43" fill="none" viewBox="0 0 54 43">
      <path stroke="#54C46C" strokeLinecap="round" strokeLinejoin="round" strokeWidth="10" d="M5 27l11 11L49 5"></path>
    </svg>
  );
}

export default React.memo(Success);
