import Modal, { ModalProps } from "@/components/Modal2/Modal";
import clsx from "clsx";
import React from "react";
import { useQuery } from "react-query";
import { GET_WIDGET_COMPANY_QUERY } from "@/data/query-keys";
import { AppError } from "@/domain";
import { adminService } from "@/services";
import { GetWidgetCompanyFilterType } from "@/domain/admin";
import LoadingState from "@/dashboard/layout/loading-state";
import ErrorState from "@/dashboard/layout/error-state";
import Link from "next/link";
import { numWord } from "@/data/utils";
import { WidgetDictionary } from "./Widgets";

interface CompanyListModalProps extends ModalProps {
  filter: GetWidgetCompanyFilterType | null;
  accountType: "mover" | "customer" | null;
}

const CompanyListModal: React.FC<CompanyListModalProps> = (props) => {
  const { children, onClose, filter, accountType } = props;
  const { data, isLoading, error, refetch } = useQuery(
    [GET_WIDGET_COMPANY_QUERY, filter],
    () =>
      adminService.getWidgetCompany({
        aggregate_filter: filter!,
      }),
    {
      enabled: !!filter,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      onError: (err: AppError) => {},
      onSuccess: () => {},
    },
  );

  const handleRedirect = (url: string) => {
    window.open(url, "_blank", "noopener noreferrer");
  };

  return (
    <Modal {...props} className={clsx("!relative h-fit !min-w-fit !overflow-y-hidden !p-0", {})}>
      <div className="absolute right-5 top-5 cursor-pointer" onClick={onClose}>
        <CloseIcon />
      </div>
      <div className="flex max-h-[900px] min-w-[600px] max-w-[1600px] flex-col overflow-y-auto rounded-xl border border-divider p-6 px-10">
        <>{isLoading && <LoadingState />}</>
        <>{error && <ErrorState error={error} onRetry={refetch} />}</>
        <>
          {data && data.size > 0 && (
            <div className="flex flex-col gap-12">
              <div className="flex items-center gap-2">
                {filter && (
                  <>
                    <p className="text-textBody text-secondaryText group-hover:text-label">
                      {WidgetDictionary[filter].title}
                    </p>
                  </>
                )}
              </div>
              <div className="flex flex-col gap-6">
                {/*@ts-ignore*/}
                {[...data].map(([v, k]) => {
                  return (
                    <div className="flex flex-col gap-1">
                      <p className="text-textBodySemi">
                        {k.name + " "}({k.inn})
                      </p>
                      <div>
                        <p>
                          <label
                            className="cursor-pointer text-primary underline underline-offset-4 hover:text-hover"
                            onClick={
                              accountType
                                ? () =>
                                    handleRedirect(
                                      `/admin/orders/list?${
                                        accountType === "customer" ? `customer_id=${k.prefix}` : `mover_id=${k.prefix}`
                                      }&aggregate_filter=${filter}`,
                                    )
                                : undefined
                            }
                          >
                            {k.prefix}
                          </label>

                          {" - "}
                          {k.count + " " + numWord(k.count, ["заказ", "заказа", "заказов"])}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </>
        <>{data && data.size === 0 && <div>Нет данных</div>}</>
      </div>
    </Modal>
  );
};

export default CompanyListModal;

const CloseIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="stroke-disabled hover:stroke-secondaryText active:stroke-primaryText"
  >
    <path d="M18.666 6L6.66602 18" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.66602 6L18.666 18" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
