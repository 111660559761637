import React from "react";

const MenuIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className="stroke-primaryText"
  >
    <path d="M3 12H21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 6H21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 18H21" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default React.memo(MenuIcon);
