import { ResultResponse } from "@/domain";
import { CheckBeforePrintResultResponse, PrintTnRequest } from "@/domain/tn";
import {
  CheckBeforePrintTnController_AgCheckBeforePrintTnResponse,
  CheckBeforePrintTnController_AgOrderIdCodeBoxIdRequest,
  PrintTnController_AgOrderIdCodeBoxIdRequest,
  PrintTnController_AgPrintTnResponse,
} from "../codegen";

export function mapToPrintTNRequest(request: PrintTnRequest): PrintTnController_AgOrderIdCodeBoxIdRequest {
  return { order_id_code: request.orderId, box_id: request.boxId };
}

export function mapPrintTNReponseToResultResponse(response: PrintTnController_AgPrintTnResponse): ResultResponse {
  return { result: response.result, message: response.message ?? undefined };
}

export function mapToCheckBeforePrintTNRequest(
  request: PrintTnRequest,
): CheckBeforePrintTnController_AgOrderIdCodeBoxIdRequest {
  return { order_id_code: request.orderId, box_id: request.boxId };
}

export function mapCheckBeforePrintTNReponseToResultResponse(
  response: CheckBeforePrintTnController_AgCheckBeforePrintTnResponse,
): CheckBeforePrintResultResponse {
  return {
    result: response.result ?? false,
    message: response.message ?? undefined,
    canPrint: response.can_print ?? false,
  };
}
