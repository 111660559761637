import { EmailConfirmationModal } from "@/widgets/email-confirmation-modal";

import Header from "./Header";
import Footer from "./Footer";
import { AcceptCookiesWarning } from "@/features/accept-cookies";
import NoSSR from "@/components/NoSSR";
import { useScroll } from "react-use";
import { useEffect, useRef } from "react";
import { useAtom } from "jotai";
import { scrollYAtom } from "@/data/hooks/useScrollY";

export const LandingLayout: React.FC = ({ children }) => {
  const scrollRef = useRef(null);
  const { x, y } = useScroll(scrollRef);
  const [scrollY, setScrollY] = useAtom(scrollYAtom);

  useEffect(() => {
    setScrollY(y);
  }, [y]);

  return (
    <>
      <div className="h-screen overflow-y-scroll font-inter" ref={scrollRef}>
        <Header />
        <main>{children}</main>
        <Footer />
      </div>

      {/*<AtiAuthResultModal />*/}
      <EmailConfirmationModal />
      {/*<ResetPasswordModal />*/}
      <NoSSR>
        <AcceptCookiesWarning />
      </NoSSR>
    </>
  );
};
