import axios, { AxiosError } from "axios";
import { AuthTokensResponse } from "@/domain";
import { REFRESH_TOKEN } from "./endpoints";
import { AuthenticationController_SecurityTokenResponse } from "./codegen";
import * as tokenStorage from "data/tokens";

let isRefreshing = false;
let refreshingTokenPromise: Promise<void> = Promise.resolve();

type ValueGetter = () => string | undefined;
type ValueSetter = (value?: string) => void;
let getAccessToken: ValueGetter = () => undefined;
let setAccessToken: ValueSetter = () => {};
let getRefreshToken: ValueGetter = () => undefined;
let setRefreshToken: ValueSetter = () => {};

const API_BASE_URL = "/api";
const clientWithAuth = axios.create({ baseURL: API_BASE_URL });
const clientDefault = axios.create({ baseURL: API_BASE_URL });

clientWithAuth.interceptors.request.use(async function beforeRequest(config) {
  await refreshingTokenPromise;
  const token = getAccessToken();
  if (config.headers && token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
});

clientWithAuth.interceptors.response.use(
  async function (response) {
    return response;
  },
  async function (error: AxiosError) {
    const originalRequest = error.config;
    if (error.response?.status === 401) {
      // @ts-ignore
      if (originalRequest._retry) return error;
      // @ts-ignore
      originalRequest._retry = true;

      try {
        if (!isRefreshing) {
          isRefreshing = true;
          const refreshToken = getRefreshToken();
          refreshingTokenPromise = refreshAccessToken(refreshToken)
            .then((tokens) => {
              setAccessToken(tokens.accessToken);
              setRefreshToken(tokens.refreshToken);
              isRefreshing = false;
            })
            .catch((err) => {
              setAccessToken();
              setRefreshToken();
            });
        }
      } catch (e) {
        throw e;
      }

      return clientWithAuth(error.config);
    }

    if (error.response?.statusText.includes("Отсутствует токен в заголовках авторизации")) {
      await refreshingTokenPromise;
      const token = getAccessToken();
      if (!token) {
        tokenStorage.setAccessToken(null);
        tokenStorage.setRefreshToken(null);
        return;
      }

      return clientWithAuth(error.config);
    }

    throw error;
  },
);

export function refreshAccessToken(token?: string): Promise<AuthTokensResponse> {
  return clientDefault
    .post<AuthenticationController_SecurityTokenResponse>(
      REFRESH_TOKEN,
      {
        refresh_token: token,
      },
      { baseURL: API_BASE_URL },
    )
    .then((response) => response.data)
    .then((response) => ({
      accessToken: response.bearer_token,
      refreshToken: response.refresh_token,
    }));
}

const onGetAccessToken = (getter: ValueGetter) => {
  getAccessToken = getter;
};
const onSetAccessToken = (setter: ValueSetter) => {
  setAccessToken = setter;
};
const onGetRefreshToken = (getter: ValueGetter) => {
  getRefreshToken = getter;
};
const onSetRefreshToken = (setter: ValueSetter) => {
  setRefreshToken = setter;
};

export { clientDefault, clientWithAuth, onGetAccessToken, onSetAccessToken, onGetRefreshToken, onSetRefreshToken };
