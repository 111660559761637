import Button from "@/components/Button";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useLocalStorage } from "react-use";

const ACCEPT_COOKIES_LS_KEY = "kuber-accept-cookies";

export const AcceptCookiesWarning = () => {
  const [isAccepted, accept] = useLocalStorage(ACCEPT_COOKIES_LS_KEY, false);
  const [showWarning, setShowWarning] = useState(!isAccepted);

  useEffect(() => {
    function subscribe(ev: StorageEvent) {
      if (ev.key === ACCEPT_COOKIES_LS_KEY) {
        setShowWarning(false);
      }
    }

    window.addEventListener("storage", subscribe);

    return () => window.removeEventListener("storage", subscribe);
  }, []);

  const handleOpenPrivacyPolicy = () => {
    window.open("/privacy-policy", "_blank");
  };
  const handleAcceptCookies = () => {
    accept(true);
  };

  if (!showWarning || isAccepted) return null;

  return (
    <div
      className={clsx(
        "fixed bottom-11 left-6 right-6 z-[99999]",
        "at768:left-1/2 at768:right-auto at768:-translate-x-1/2",
        "at1366:left-auto at1366:right-8 at1366:translate-x-0",
        "max-w-[640px] at768:w-[640px]",
        "rounded-2xl bg-white shadow-[0_5px_16px_rgba(58,66,74,0.25)]",
        "p-6 at768:px-8 at768:py-9",
      )}
    >
      <div className="flex items-start gap-x-8">
        <div className="hidden at768:block">
          <div className={clsx("flex items-center justify-center", "h-12 w-12 rounded-lg bg-primary")}>
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15 29.3327C7.09332 29.3327 0.666656 22.906 0.666656 14.9993C0.666656 7.09268 7.09332 0.666016 15 0.666016C22.9067 0.666016 29.3333 7.09268 29.3333 14.9993C29.3333 22.906 22.9067 29.3327 15 29.3327ZM15 2.66602C8.19999 2.66602 2.66666 8.19935 2.66666 14.9993C2.66666 21.7993 8.19999 27.3327 15 27.3327C21.8 27.3327 27.3333 21.7993 27.3333 14.9993C27.3333 8.19935 21.8 2.66602 15 2.66602Z"
                fill="white"
              />
              <path
                d="M15 17.3327C14.4533 17.3327 14 16.8793 14 16.3327V9.66602C14 9.11935 14.4533 8.66602 15 8.66602C15.5467 8.66602 16 9.11935 16 9.66602V16.3327C16 16.8793 15.5467 17.3327 15 17.3327Z"
                fill="white"
              />
              <path
                d="M15 21.6645C14.8267 21.6645 14.6533 21.6245 14.4933 21.5579C14.3333 21.4912 14.1867 21.3979 14.0533 21.2779C13.9333 21.1446 13.84 21.0112 13.7733 20.8379C13.7067 20.6779 13.6667 20.5046 13.6667 20.3312C13.6667 20.1579 13.7067 19.9845 13.7733 19.8245C13.84 19.6645 13.9333 19.5179 14.0533 19.3846C14.1867 19.2646 14.3333 19.1712 14.4933 19.1045C14.8133 18.9712 15.1867 18.9712 15.5067 19.1045C15.6667 19.1712 15.8133 19.2646 15.9467 19.3846C16.0667 19.5179 16.16 19.6645 16.2267 19.8245C16.2933 19.9845 16.3333 20.1579 16.3333 20.3312C16.3333 20.5046 16.2933 20.6779 16.2267 20.8379C16.16 21.0112 16.0667 21.1446 15.9467 21.2779C15.8133 21.3979 15.6667 21.4912 15.5067 21.5579C15.3467 21.6245 15.1733 21.6645 15 21.6645Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div>
          <div className="text-center at768:text-left">
            KUBER обрабатывает Cookies для того, чтобы персонализировать разделы веб-сайта и сделать его более удобным
            для&nbsp;пользователей. Пожалуйста, ознакомьтесь с{" "}
            <a
              className="inline text-primary underline underline-offset-4 hover:cursor-pointer"
              href="/privacy-policy"
              target="_blank"
            >
              Политикой конфиденциальности данных.
            </a>
          </div>
          <div className="mt-8">
            <div className="flex w-full flex-row gap-6">
              <Button
                type="button"
                size="small"
                onClick={handleOpenPrivacyPolicy}
                variant="outline"
                className="!h-10 !min-h-[40px] w-32 !px-0 !text-sm font-bold at768:w-full"
              >
                <span className="font-bold">Ознакомиться</span>
              </Button>
              <Button
                type="button"
                size="small"
                onClick={handleAcceptCookies}
                className="!h-10 !min-h-[40px] w-32 !text-sm font-bold at768:w-full"
              >
                <span className="font-bold">Принять</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
