export const ACCESS_TOKEN_STORAGE_KEY = "access_token";
export const REFRESH_TOKEN_STORAGE_KEY = "refresh_token";

export function getAccessToken(): string | null {
  if (typeof localStorage === "undefined") return null;

  const accessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
  return accessToken ? JSON.parse(accessToken) : null;
}

export function setAccessToken(token: string | null) {
  if (typeof localStorage === "undefined") return;

  localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, JSON.stringify(token));
}

export function getRefreshToken(): string | null {
  if (typeof localStorage === "undefined") return null;

  const refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
  return refreshToken ? JSON.parse(refreshToken) : null;
}

export function setRefreshToken(token: string | null) {
  if (typeof localStorage === "undefined") return;

  localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, JSON.stringify(token));
}
