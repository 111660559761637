import React from "react";
import { Dialog } from "@headlessui/react";
import clsx from "clsx";

export interface ModalProps {
  open: boolean;
  onClose: () => void;
  className?: string;
  dialogClassName?: string;
}

const Modal: React.FC<ModalProps> = ({ open, onClose, className, children, dialogClassName }) => {
  return (
    <Dialog className={clsx("fixed inset-0 z-[90000] overflow-y-auto", dialogClassName)} open={open} onClose={onClose}>
      <Dialog.Overlay className="fixed inset-0 bg-primaryText opacity-60" />
      <div
        className={clsx(
          "left-1/2 top-1/2 inline-block max-w-[560px] -translate-x-1/2 -translate-y-1/2",
          "shadow-[0_40px_64px_0_rgba(91, 104, 113, 0.24), 0_0_1_0_rgba(26, 32, 36, 0.32)] absolute z-10 overflow-y-auto  rounded-xl bg-white p-8 pr-6",
          className,
        )}
      >
        {children}
      </div>
    </Dialog>
  );
};

export default Modal;
