import { useEffect } from "react";
import { onGetAccessToken, onGetRefreshToken, onSetAccessToken, onSetRefreshToken } from "@/api/kuber-api";
import { useAccessToken, useRefreshToken } from "@/data/hooks/useUser";
import * as tokens from "@/data/tokens";

export const SyncAccessToken = () => {
  const setAccessToken = useAccessToken();
  const setRefreshToken = useRefreshToken();

  useEffect(() => {
    onGetAccessToken(() => {
      return tokens.getAccessToken() || undefined;
    });
  }, [onGetAccessToken]);
  useEffect(() => {
    onSetAccessToken((value) => {
      setAccessToken(value ?? null);
    });
  }, [onSetAccessToken]);
  useEffect(() => {
    onGetRefreshToken(() => {
      return tokens.getRefreshToken() || undefined;
    });
  }, [onGetRefreshToken]);
  useEffect(() => {
    onSetRefreshToken((value) => {
      setRefreshToken(value ?? null);
    });
  }, [onSetRefreshToken]);

  return null;
};
