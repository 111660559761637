import React from "react";
import clsx from "clsx";

interface LoadingProps {
  text?: string;
  size?: "small";
  primary?: boolean;
}

const Loading = ({ text, size, primary }: LoadingProps) => (
  <div className="flex w-full items-center justify-center">
    {text && <label className="font-medium text-title">{text}</label>}
    <div className={clsx("flex items-center justify-between gap-[3px] transition-all ease-in-out")}>
      {[1, 2, 3, 4, 5].map((i) => (
        <div
          key={i}
          className={clsx("w-[2px] animate-rectangle-bounce rounded", {
            "h-5": i === 1 || i === 5,
            "h-4": i === 2 || i === 4,
            "h-6": i === 3,
            "bg-primary": primary,
            "bg-white": !primary,
          })}
          style={{ animationDelay: `0.${i - 1}s` }}
        ></div>
      ))}
    </div>
  </div>
);

export default React.memo(Loading);
