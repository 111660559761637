import { mover } from "@/api/kuber-api";
import {
  AddDriverRequest,
  AddSemiTrailerRequest,
  AddTruckRequest,
  DirectoryListEntity,
  GetDriversRequest,
  GetDriversResponse,
  GetMoverDirectoryListRequest,
  GetSemiTrailersRequest,
  GetSemiTrailersResponse,
  GetTrucksRequest,
  GetTrucksResponse,
  ResultResponse,
  UpdateDocumentRequest,
  UpdateDriverRequest,
  UpdateSemiTrailerRequest,
  UpdateTruckRequest,
} from "@/domain";
import { transformError } from "@/shared";
import {
  GetDriverCardController_DriverCardResponse,
  GetDriverListController_DriverListResponseV2,
  GetSemiTrailerCardController_SemiTrailerCardResponse,
  GetSemiTrailerListController_SemiTrailerListResponseV2,
  GetTruckCardController_TruckCardResponse,
  GetTruckListController_TruckListResponseV2,
} from "@/api/kuber-api/codegen";
import { fromUpdateDocumentRequest } from "@/api/kuber-api/mover-directories/mover-mappers";

export function getDrivers(request: GetDriversRequest): Promise<GetDriversResponse> {
  return mover.api
    .getDrivers(request)
    .then((response) => mover.mappers.toGetDriversResponse(response))
    .catch((error) => {
      throw transformError(error);
    });
}

export function updateDriver(request: UpdateDriverRequest): Promise<ResultResponse> {
  return mover.api
    .updateDriver(mover.mappers.fromUpdateDriverRequest(request))
    .then((response) => mover.mappers.toResultResponse(response))
    .catch((error) => {
      throw transformError(error);
    });
}

export async function updateDriverCard(request: UpdateDriverRequest): Promise<ResultResponse> {
  try {
    const docs = request.documents.filter((doc) => doc.file.content !== "");
    const response = await mover.api.updateDriver(mover.mappers.fromUpdateDriverRequest(request));
    if (docs.length !== 0) {
      await mover.api.updateDocument(
        fromUpdateDocumentRequest({
          documents: docs,
          entityId: request.uniqCode,
        }),
      );
    }
    return mover.mappers.toResultResponse(response);
  } catch (err) {
    throw transformError(err);
  }
}

export function addDriver(request: AddDriverRequest): Promise<ResultResponse> {
  return mover.api
    .addDriver(mover.mappers.fromAddDriverRequest(request))
    .then((response) => mover.mappers.toResultResponse(response))
    .catch((error) => {
      throw transformError(error);
    });
}

export function getTrucks(request: GetTrucksRequest): Promise<GetTrucksResponse> {
  return mover.api
    .getTrucks(request)
    .then((response) => mover.mappers.toGetTrucksResponse(response))
    .catch((error) => {
      throw transformError(error);
    });
}

export function updateTruck(request: UpdateTruckRequest): Promise<ResultResponse> {
  return mover.api
    .updateTruck(mover.mappers.fromUpdateTruckRequest(request))
    .then((response) => mover.mappers.toResultResponse(response))
    .catch((error) => {
      throw transformError(error);
    });
}

export async function updateTruckCard(request: UpdateTruckRequest): Promise<ResultResponse> {
  try {
    const docs = request.documents.filter((doc) => doc.file.content !== "");
    const response = await mover.api.updateTruck(mover.mappers.fromUpdateTruckRequest(request));
    if (docs.length !== 0) {
      await mover.api.updateDocument(
        fromUpdateDocumentRequest({
          documents: docs,
          entityId: request.uniqCode,
        }),
      );
    }
    return mover.mappers.toResultResponse(response);
  } catch (err) {
    throw transformError(err);
  }
}

export function addTruck(request: AddTruckRequest): Promise<ResultResponse> {
  return mover.api
    .addTruck(mover.mappers.fromAddTruckRequest(request))
    .then((response) => mover.mappers.toResultResponse(response))
    .catch((error) => {
      throw transformError(error);
    });
}

export function getSemitrailers(request: GetSemiTrailersRequest): Promise<GetSemiTrailersResponse> {
  return mover.api
    .getSemitrailers(request)
    .then((response) => mover.mappers.toGetSemitrailersResponse(response))
    .catch((error) => {
      throw transformError(error);
    });
}

export function updateSemiTrailer(request: UpdateSemiTrailerRequest): Promise<ResultResponse> {
  return mover.api
    .updateSemiTrailer(mover.mappers.fromUpdateSemiTrailerRequest(request))
    .then((response) => mover.mappers.toResultResponse(response))
    .catch((error) => {
      throw transformError(error);
    });
}

export async function updateSemitrailerCard(request: UpdateSemiTrailerRequest): Promise<ResultResponse> {
  try {
    const docs = request.documents.filter((doc) => doc.file.content !== "");
    const response = await mover.api.updateSemiTrailer(mover.mappers.fromUpdateSemiTrailerRequest(request));
    if (docs.length !== 0) {
      await mover.api.updateDocument(
        fromUpdateDocumentRequest({
          documents: docs,
          entityId: request.uniqCode,
        }),
      );
    }
    return mover.mappers.toResultResponse(response);
  } catch (err) {
    throw transformError(err);
  }
}

export function addSemiTrailer(request: AddSemiTrailerRequest): Promise<ResultResponse> {
  return mover.api
    .addSemiTrailer(mover.mappers.fromAddSemiTrailerRequest(request))
    .then((response) => mover.mappers.toResultResponse(response))
    .catch((error) => {
      throw transformError(error);
    });
}

export function archive(entityId: string): Promise<ResultResponse> {
  return mover.api
    .archiveEntity({ uniq_code: entityId })
    .then((response) => mover.mappers.toResultResponse(response))
    .catch((error) => {
      throw transformError(error);
    });
}

export function unarchive(entityId: string): Promise<ResultResponse> {
  return mover.api
    .unarchiveEntity({ uniq_code: entityId })
    .then((response) => mover.mappers.toResultResponse(response))
    .catch((error) => {
      throw transformError(error);
    });
}

export function updateDocument(request: UpdateDocumentRequest): Promise<ResultResponse> {
  return mover.api
    .updateDocument(mover.mappers.fromUpdateDocumentRequest(request))
    .then((response) => mover.mappers.toResultResponse(response))
    .catch((error) => {
      throw transformError(error);
    });
}

export function getDriverList(
  request: GetMoverDirectoryListRequest,
): Promise<GetDriverListController_DriverListResponseV2> {
  return mover.api.getDriversList(request).catch((error) => {
    throw transformError(error);
  });
}

export function getTruckList(
  request: GetMoverDirectoryListRequest,
): Promise<GetTruckListController_TruckListResponseV2> {
  return mover.api.getTrucksList(request).catch((error) => {
    throw transformError(error);
  });
}

export function getSemitrailerList(
  request: GetMoverDirectoryListRequest,
): Promise<GetSemiTrailerListController_SemiTrailerListResponseV2> {
  return mover.api.getSemitrailersList(request).catch((error) => {
    throw transformError(error);
  });
}

export function getDirectories(
  request: GetMoverDirectoryListRequest,
  entity: DirectoryListEntity,
): Promise<
  | GetDriverListController_DriverListResponseV2
  | GetTruckListController_TruckListResponseV2
  | GetSemiTrailerListController_SemiTrailerListResponseV2
> {
  switch (entity) {
    case "driver":
      return getDriverList(request);

    case "truck":
      return getTruckList(request);

    default:
      return getSemitrailerList(request);
  }
}

export function getDriver(uniq: string): Promise<GetDriverCardController_DriverCardResponse> {
  return mover.api.getDriver(uniq).catch((error) => {
    throw transformError(error);
  });
}

export function getSemitrailer(uniq: string): Promise<GetSemiTrailerCardController_SemiTrailerCardResponse> {
  return mover.api.getSemitrailer(uniq).catch((error) => {
    throw transformError(error);
  });
}

export function getTruck(uniq: string): Promise<GetTruckCardController_TruckCardResponse> {
  return mover.api.getTruck(uniq).catch((error) => {
    throw transformError(error);
  });
}

export function getEntity(request: {
  uniq: string;
  entity: DirectoryListEntity;
}): Promise<
  | GetDriverCardController_DriverCardResponse
  | GetSemiTrailerCardController_SemiTrailerCardResponse
  | GetTruckCardController_TruckCardResponse
> {
  switch (request.entity) {
    case "driver":
      return getDriver(request.uniq);

    case "truck":
      return getTruck(request.uniq);

    default:
      return getSemitrailer(request.uniq);
  }
}
