import { DirectoryRecord, DocumentFileEntity, WorkflowCheckEntity } from "@/domain";
import { account } from "@/services";
import React from "react";

import { FileCheck, FileResult, Sorting } from "./types";
import { AxiosError } from "axios";
import { APPLICATION_PDF_EXTENSION, OCTET_STREAM_EXTENSION, PDF_EXTENSION } from "@/dashboard/support/constants";
import { ROUBLE_SIGN } from "@/data/constants";

export function numberWithSpaces(x: number | string) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export function ignoreNonDigit(onChange: React.ChangeEventHandler<HTMLInputElement>) {
  return function handleChange(ev: React.ChangeEvent<HTMLInputElement>) {
    if (ev.target.value === "" || /^\d+$/.test(ev.target.value)) {
      onChange(ev);
    }
  };
}

export const terminalFilterConfig = {
  stringify: (option: any) => `${option.data.label} ${option.data.addressTerminal}`,
};

export function createSorting(newSortingField: string, currentSorting: Sorting): Sorting {
  const isSortedByCurrentField = currentSorting.field === newSortingField;

  return {
    field: newSortingField as typeof currentSorting.field,
    direction: isSortedByCurrentField && currentSorting.direction === "desc" ? "asc" : "desc",
  };
}

type FileAttachment = {
  name: string;
  content: string;
  type: string;
  previewUrl?: string;
};

export function readFileAsync(file: File): Promise<FileAttachment> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onabort = () => {};
    reader.onerror = () => {
      reject(new Error(`Error reading file ${file.name}`));
    };
    reader.onload = () => {
      const binaryStr = reader.result?.toString().replace("data:", "").replace(/^.+,/, "");
      resolve({
        name: file.name,
        content: binaryStr!,
        type: file.type,
        previewUrl: /^image/.test(file.type) ? URL.createObjectURL(file) : undefined,
      });
    };
    reader.readAsDataURL(file);
  });
}

export async function loadBinaryResource(url: string, extension: string) {
  const response = await account
    .downloadFile(url)
    .then((response) => {
      if (extension === PDF_EXTENSION && response.file.type === OCTET_STREAM_EXTENSION)
        return response.file.slice(0, response.file.size, APPLICATION_PDF_EXTENSION);

      return response.file;
    })
    .catch((err: any) => {});

  if (response) return URL.createObjectURL(response);
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export function mergeFilesWithChecks(files: DocumentFileEntity[], checks: WorkflowCheckEntity[]): FileResult[] {
  return files.map((file) => {
    const checkEntity = checks.find((e) => e.documentFile && e.documentFile.id === file.id);
    const check: FileCheck = checkEntity
      ? { failed: checkEntity.confNameStatus.status.id === "fail", comment: checkEntity.comment }
      : { failed: false };
    const fileWithCheck: FileResult = {
      fileId: file.id,
      fileName: file.originalName,
      fileType: {
        id: file.typeId,
        name: file.typeName,
      },
      check,
    };
    return fileWithCheck;
  });
}

export function formatPhoneNumber(phone: string): string {
  const clean = phone.replace(/\D/g, "").replace(/^8/, "7");
  // save
  if (clean.length !== 11) {
    return phone;
  }

  const operatorCode = clean.substring(1, 4);
  const group1 = clean.substring(4, 7);
  const group2 = clean.substring(7, 9);
  const group3 = clean.substring(9, 11);
  const formatted = `+7 (${operatorCode}) ${group1}-${group2}-${group3}`;
  return formatted;
}

export function formatCompanyName(name: string): string {
  return '"' + name + '"';
}

export function formatPrice(price: number) {
  return numberWithSpaces(price.toFixed(2));
}

export function formatFloatPrice(price: number) {
  return numberWithSpaces(isInt(price) ? price.toFixed(0) : price.toFixed(2));
}

export function formatFloatRubPrice(price: number) {
  return numberWithSpaces(isInt(price) ? price.toFixed(0) : price.toFixed(2)) + " " + ROUBLE_SIGN;
}

function isInt(n: number) {
  return n % 1 === 0;
}

export function selectActiveOptions<
  T extends {
    active: boolean;
  },
>(items: T[]) {
  return items.filter((i) => i.active);
}

export function checkBrowser() {
  let browser = "Chrome";

  let c = navigator.userAgent.search("Chrome");
  let f = navigator.userAgent.search("Firefox");

  if (c > -1) {
    browser = "Chrome";
  } else if (f > -1) {
    browser = "Firefox";
  }
  return browser;
}

export function isAxiosError(err: any): err is AxiosError {
  return typeof err.isAxiosError === "boolean";
}

export function truncateString(str: string, length = 30) {
  return str.length > length ? str.substring(0, length - 1) + "..." : str;
}

export function findAutocompleteUniqueValues(array: any[]): any[] {
  const suggestionsResponse = array.map((item) => item.value);
  const uniqueOptions: Set<string> = new Set(suggestionsResponse);
  const stringArray = Array.from(uniqueOptions);
  return stringArray.map((item) => {
    return {
      value: item,
      label: item,
    } as any;
  });
}

export const formatDateRequest = (input: string): string => {
  const array = input.split(".");
  return array[2] + "-" + array[1] + "-" + array[0];
};

export const toQueryParam = (param: string, value: string | undefined): string => {
  if (!value) return "";
  return `&${param}=${value}`;
};

type QueryParams = {
  [key: string]: string;
};
export const mapQueryParam = (queryParams: QueryParams): string => {
  const entries = Object.entries(queryParams);
  return entries.map((e) => `&${e[0]}=${e[1]}`).join("");
};

export const prepareQueryParam = (queryParams: QueryParams): QueryParams => {
  let map: QueryParams = {};
  const entries = Object.entries(queryParams);
  entries.map((entry) => {
    if (!entry[1]) return;
    map[entry[0]] = entry[1];
  });
  return map;
};

export const formatPriceData = (value: string, length: number): number => {
  return parseFloat(
    value
      .substring(0, length - 3)
      .split(" ")
      .join("")
      .split(",")
      .join("."),
  );
};

export const checkForAddress = (option: DirectoryRecord | null): boolean => {
  if (!option) return true;
  return option.value.split(",").length >= 3;
};

export function numWord(value: number, words: string[]) {
  value = Math.abs(value) % 100;
  var num = value % 10;
  if (value > 10 && value < 20) return words[2];
  if (num > 1 && num < 5) return words[1];
  if (num == 1) return words[0];
  return words[2];
}
