import { clientDefault, clientWithAuth } from "../client";
import { CREATE_ACCOUNT_CUSTOMER, CREATE_ACCOUNT_MOVER, LOGIN, LOGOUT, PHONE_TOKEN, PHONE_VERIFY } from "../endpoints";
import {
  AuthenticationController_AuthenticationApiRequest,
  AuthenticationController_SecurityTokenResponse,
  CustomerRegistrationController_RegistrationInputDto,
  CustomerRegistrationController_SecurityTokenResponse,
  MoverRegistrationController_RegistrationInputDto,
  MoverRegistrationController_SecurityTokenResponse,
  PhoneTokenGetterByPhoneController_AuthSmsPhoneNumberRequest,
  PhoneTokenGetterByPhoneController_PhoneVerificationUuidResponse,
  PhoneVerifierByTokenController_PhoneTokenResponse,
  PhoneVerifierByTokenController_PhoneVerificationUuidAndCodeRequest,
  PrivateAccountLogoutController_ResultBoolResponse,
} from "../codegen";

export function signIn(
  request: AuthenticationController_AuthenticationApiRequest,
): Promise<AuthenticationController_SecurityTokenResponse> {
  return clientDefault.post(LOGIN, request).then((response) => response.data);
}

export function fetchPhoneVerificationUuid(
  request: PhoneTokenGetterByPhoneController_AuthSmsPhoneNumberRequest,
): Promise<PhoneTokenGetterByPhoneController_PhoneVerificationUuidResponse> {
  return clientDefault.post(PHONE_TOKEN, request).then((response) => response.data);
}

export function fetchPhoneToken(
  request: PhoneVerifierByTokenController_PhoneVerificationUuidAndCodeRequest,
): Promise<PhoneVerifierByTokenController_PhoneTokenResponse> {
  return clientDefault.post(PHONE_VERIFY, request).then((response) => response.data);
}

export function registerCustomer(
  request: CustomerRegistrationController_RegistrationInputDto,
): Promise<CustomerRegistrationController_SecurityTokenResponse> {
  return clientDefault
    .post<CustomerRegistrationController_SecurityTokenResponse>(CREATE_ACCOUNT_CUSTOMER, request)
    .then((response) => response.data);
}

export function registerMover(
  request: MoverRegistrationController_RegistrationInputDto,
): Promise<MoverRegistrationController_SecurityTokenResponse> {
  return clientDefault
    .post<MoverRegistrationController_SecurityTokenResponse>(CREATE_ACCOUNT_MOVER, request)
    .then((response) => response.data);
}

export async function logout(): Promise<PrivateAccountLogoutController_ResultBoolResponse> {
  return clientWithAuth
    .delete<PrivateAccountLogoutController_ResultBoolResponse>(LOGOUT)
    .then((response) => response.data);
}
