import { AppError } from "@/domain";
import { AxiosError } from "axios";

function isAxiosError(err: any): err is AxiosError {
  return typeof err.isAxiosError === "boolean";
}

export function transformError(error: any): AppError {
  if (isAxiosError(error)) {
    if (error.response && error.response.data && error.response.data.error_message) {
      return new AppError({
        isBackendError: true,
        errorMessage: error.response.data.error_message,
        errorCode: error.response.data.error_code,
        error,
        statusCode: error.response.status,
      });
    }

    return new AppError({
      isBackendError: false,
      error,
    });
  }

  return new AppError({
    isBackendError: false,
    error,
  });
}
