import {
  ChangeTicketPinnedStatusRequest,
  GetPaginatedTicketChatsListResponse,
  GetTicketChatDirectoryResponse,
  GetTicketChatMessagesRequest,
  GetTicketChatsListResponse,
  GetTicketChatsRequest,
  GetTicketId,
  GetTicketMessagesResponse,
  GetTicketsListRequest,
  OpenTicketChatRequest,
  OpenTicketResponse,
  OrderItem,
  SendTicketChatMessageRequest,
  SendTicketMessageResponse,
} from "@/domain/tickets";
import { account, admin, tickets } from "@/api/kuber-api";
import { transformError } from "@/shared";
import { roles } from "@/data/constants";
import { EntityWithProfileRequest, ProfileType, ResultResponse } from "@/domain";
import {
  GetCustomerMovedFromOtherTicketMessageInfoController_MovedFromOtherTicketMessageInfoResponse,
  GetCustomerMovedToOtherTicketMessageInfoController_MovedToOtherTicketMessageInfoResponse,
  GetMovedFromOtherTicketMessageInfoAdminController_MovedFromOtherTicketMessageInfoResponse,
  GetMovedToOtherTicketMessageInfoAdminController_MovedToOtherTicketMessageInfoResponse,
  GetMoverMovedFromOtherTicketMessageInfoController_MovedFromOtherTicketMessageInfoResponse,
  GetMoverMovedToOtherTicketMessageInfoController_MovedToOtherTicketMessageInfoResponse,
} from "@/api/kuber-api/codegen";

export function getTicketsList(request: GetTicketsListRequest): Promise<GetPaginatedTicketChatsListResponse> {
  return tickets.api
    .getTickets(request)
    .then(tickets.mappers.toGetTicketsListResponse)
    .catch((error) => {
      throw transformError(error);
    });
}

export async function openTicketChat(request: OpenTicketChatRequest): Promise<OpenTicketResponse> {
  try {
    const response =
      request.profileType === roles.CUSTOMER
        ? await tickets.api.openCustomerTicketChat(tickets.mappers.fromOpenTicketChatRequest(request.payload))
        : await tickets.api.openMoverTicketChat(tickets.mappers.fromOpenTicketChatRequest(request.payload));

    return tickets.mappers.toOpenTicketChatResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function sendTicketChatMessage(request: SendTicketChatMessageRequest): Promise<SendTicketMessageResponse> {
  try {
    let response;

    switch (request.profileType) {
      case roles.CUSTOMER:
        response = await tickets.api.sendCustomerTicketChatMessage(
          tickets.mappers.fromSendTicketChatMessageRequest(request.payload),
        );
        break;
      case roles.MOVER:
        response = await tickets.api.sendMoverTicketChatMessage(
          tickets.mappers.fromSendTicketChatMessageRequest(request.payload),
        );
        break;
      default:
        response = await admin.api.sendAdminTicketChatMessage(
          tickets.mappers.fromSendTicketChatMessageRequest(request.payload),
        );
        break;
    }

    return tickets.mappers.toSendTicketChatMessageResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getOrdersList(profileType: ProfileType): Promise<OrderItem[]> {
  try {
    const response =
      profileType === roles.CUSTOMER ? await tickets.api.getCustomerOrders() : await tickets.api.getMoverOrders();

    return tickets.mappers.toOrdersListResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getTicketChatList(request: GetTicketChatsRequest): Promise<GetTicketChatsListResponse> {
  try {
    const response =
      request.profileType === roles.CUSTOMER
        ? await tickets.api.getCustomerTicketChats({ pagination: request.payload.pagination })
        : await tickets.api.getMoverTicketChats({ pagination: request.payload.pagination });

    return tickets.mappers.toGetTicketChatListResponse(response, request.profileType);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getTicketChatMessageList(
  request: GetTicketChatMessagesRequest,
): Promise<GetTicketMessagesResponse> {
  try {
    let response;

    switch (request.profileType) {
      case roles.CUSTOMER:
        response = await tickets.api.getCustomerTicketChatMessages({
          ticketId: request.payload.ticketId,
          pagination: request.payload.pagination,
        });
        break;
      case roles.MOVER:
        response = await tickets.api.getMoverTicketChatMessages({
          ticketId: request.payload.ticketId,
          pagination: request.payload.pagination,
        });
        break;
      default:
        response = await admin.api.getAdminTicketChatMessages({
          ticketId: request.payload.ticketId,
          pagination: request.payload.pagination,
        });
        break;
    }

    return tickets.mappers.toGetTicketMessagesRequest(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getTicketChatDirectory(profileType: ProfileType): Promise<GetTicketChatDirectoryResponse> {
  try {
    const response =
      profileType === roles.CUSTOMER
        ? await tickets.api.getCustomerTicketChatDirectory()
        : await tickets.api.getMoverTicketChatDirectory();

    return tickets.mappers.toGetTicketChatDirectoryResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function changeTicketPinnedStatus(request: ChangeTicketPinnedStatusRequest): Promise<ResultResponse> {
  try {
    let response;

    switch (request.profileType) {
      case roles.CUSTOMER:
        response = await tickets.api.changeCustomerTicketPinnedStatus({
          ticket_id: request.payload.ticketId,
          pinned: request.payload.pinned,
        });
        break;
      case roles.MOVER:
        response = await tickets.api.changeMoverTicketPinnedStatus({
          ticket_id: request.payload.ticketId,
          pinned: request.payload.pinned,
        });
        break;
      default:
        response = await admin.api.changeAdminTicketPinnedStatus({
          ticket_id: request.payload.ticketId,
          pinned: request.payload.pinned,
        });
        break;
    }

    return tickets.mappers.toChangeTicketPinnedStatusResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getUnreadMessageCount(request: EntityWithProfileRequest<GetTicketId>): Promise<number> {
  try {
    let response;

    switch (request.profileType) {
      case roles.CUSTOMER:
        response = await tickets.api.getCustomerUnreadMessageCount({
          ticketId: request.payload.ticketId,
        });
        break;
      case roles.MOVER:
        response = await tickets.api.getMoverUnreadMessageCount({
          ticketId: request.payload.ticketId,
        });
        break;
      default:
        response = await admin.api.getAdminUnreadMessageCount({
          ticketId: request.payload.ticketId,
        });
        break;
    }
    return response.count;
  } catch (error) {
    throw transformError(error);
  }
}

export async function markMessagesAsRead(
  request: EntityWithProfileRequest<{
    messages: string[];
    ticketId: string;
  }>,
): Promise<ResultResponse> {
  try {
    let response;

    switch (request.profileType) {
      case roles.CUSTOMER:
        response = await tickets.api.markCustomerMessagesAsRead({
          message_id_list: request.payload.messages,
          ticket_id: request.payload.ticketId,
        });
        break;
      case roles.MOVER:
        response = await tickets.api.markMoverMessagesAsRead({
          message_id_list: request.payload.messages,
          ticket_id: request.payload.ticketId,
        });
        break;
      default:
        response = await admin.api.markAdminMessagesAsRead({
          message_id_list: request.payload.messages,
          ticket_id: request.payload.ticketId,
        });
        break;
    }
    return account.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getMoveMessageFromInfo(
  request: EntityWithProfileRequest<{
    messageId: string;
  }>,
): Promise<
  | GetMovedFromOtherTicketMessageInfoAdminController_MovedFromOtherTicketMessageInfoResponse
  | GetMoverMovedFromOtherTicketMessageInfoController_MovedFromOtherTicketMessageInfoResponse
  | GetCustomerMovedFromOtherTicketMessageInfoController_MovedFromOtherTicketMessageInfoResponse
> {
  try {
    let response;

    switch (request.profileType) {
      case roles.CUSTOMER:
        response = await tickets.api.getCustomerMoveMessageFromInfo({
          messageId: request.payload.messageId,
        });
        break;
      case roles.MOVER:
        response = await tickets.api.getMoverMoveMessageFromInfo({
          messageId: request.payload.messageId,
        });
        break;
      default:
        response = await admin.api.getMoveMessageFromInfo({
          messageId: request.payload.messageId,
        });
        break;
    }
    return response;
  } catch (error) {
    throw transformError(error);
  }
}

export async function getMoveMessageToInfo(
  request: EntityWithProfileRequest<{
    messageId: string;
  }>,
): Promise<
  | GetMovedToOtherTicketMessageInfoAdminController_MovedToOtherTicketMessageInfoResponse
  | GetMoverMovedToOtherTicketMessageInfoController_MovedToOtherTicketMessageInfoResponse
  | GetCustomerMovedToOtherTicketMessageInfoController_MovedToOtherTicketMessageInfoResponse
> {
  try {
    let response;

    switch (request.profileType) {
      case roles.CUSTOMER:
        response = await tickets.api.getCustomerMoveMessageToInfo({
          messageId: request.payload.messageId,
        });
        break;
      case roles.MOVER:
        response = await tickets.api.getMoverMoveMessageToInfo({
          messageId: request.payload.messageId,
        });
        break;
      default:
        response = await admin.api.getMoveMessageToInfo({ messageId: request.payload.messageId });
        break;
    }
    return response;
  } catch (error) {
    throw transformError(error);
  }
}
