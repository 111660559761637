import { clientDefault, clientWithAuth } from "../client";
import {
  CHANGE_CURRENT_EMAIL,
  CHANGE_CURRENT_PHONE_NUMBER,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_PUBLIC,
  CHECK_EMAIL_AVAILABILITY,
  CHECK_PHONE,
  CONFIRM_CURRENT_VERIFIED_EMAIL,
  CONFIRM_CURRENT_VERIFIED_PHONE_NUMBER,
  CONFIRM_EMAIL_VIA_LINK,
  CONNECT_ATI,
  CREATE_COMPANY,
  DELETE_SESSIONS,
  DOWNLOAD_FILE,
  GET_ACCOUNT_INFO,
  GET_SESSIONS,
  PHONE_TOKEN,
  PHONE_VERIFY,
  RESET_PASSWORD,
  SEARCH_COMPANY,
  SEND_CONFIRMATION_CODE,
  SEND_VERIFICATION_CODE_EMAIL,
  VERIFY_CONFIRM_EMAIL_CODE,
} from "../endpoints";
import {
  AccountCheckPhoneController_ResultBoolResponse,
  AccountPasswordChangeByLinkController_ResultBoolResponse,
  AccountPasswordResetController_EmailRequest,
  AccountPasswordResetController_ResultBoolResponse,
  ChangeUserEmailController_EmailChangeRequest,
  ChangeUserEmailController_ResultBoolResponse,
  ChangeUserPhoneNumberController_PhoneNumberChangeRequest,
  ChangeUserPhoneNumberController_ResultBoolResponse,
  ConfirmEmailCodeController_EmailTokenResponse,
  ConfirmEmailCodeController_EmailVerificationUuidAndCodeRequest,
  ConfirmEmailViaLinkController_ResultBoolResponse,
  EmailAvailableController_EmailAvailableRequest,
  EmailAvailableController_ResultBoolResponse,
  PhoneTokenGetterByPhoneController_AuthSmsPhoneNumberRequest,
  PhoneTokenGetterByPhoneController_PhoneVerificationUuidResponse,
  PhoneVerifierByTokenController_PhoneTokenResponse,
  PhoneVerifierByTokenController_PhoneVerificationUuidAndCodeRequest,
  PrivateAccountChangePasswordController_NewUserPasswordWithOldPasswordRequest,
  PrivateAccountChangePasswordController_ResultBoolResponse,
  PrivateAccountCompanyFinderController_CompanyInformationResponse,
  PrivateAccountCompanyFinderController_CompanyRequest,
  PrivateAccountDeleteSessionsController_ResultBoolResponse,
  PrivateAccountInformationController_AccountInformationResponse,
  PrivateAccountNewCompanyController_CompanyNewRequest,
  PrivateAccountNewCompanyController_ResultBoolResponse,
  PrivateAccountRepeatEmailVerificationCodeController_RepeatEmailVerificationRequest,
  PrivateAccountRepeatEmailVerificationCodeController_ResultBoolResponse,
  PrivateAccountSessionsListController_SessionResponse,
  PrivateMoverAtiAuthController_AtiLinkResponse,
  SendCodeForCurrentVerifiedEmailController_EmailVerificationUuidResponse,
  SendCodeForCurrentVerifiedPhoneController_PhoneVerificationUuidResponse,
  SendCodeForNewEmailController_EmailVerificationUuidResponse,
  SendCodeForNewEmailController_NewEmailRequest,
} from "../codegen";
import { ChangePasswordPublicRequest, DownloadFileResponse } from "@/domain";

export function resetPassword(
  request: AccountPasswordResetController_EmailRequest,
): Promise<AccountPasswordResetController_ResultBoolResponse> {
  return clientDefault
    .put<AccountPasswordResetController_ResultBoolResponse>(RESET_PASSWORD, request)
    .then((response) => response.data);
}

export function changePasswordPublic(
  request: ChangePasswordPublicRequest,
): Promise<AccountPasswordChangeByLinkController_ResultBoolResponse> {
  return clientDefault
    .put(`${CHANGE_PASSWORD_PUBLIC}/${request.code}`, { new_password: request.password })
    .then((response) => response.data);
}

export function changePassword(
  request: PrivateAccountChangePasswordController_NewUserPasswordWithOldPasswordRequest,
): Promise<PrivateAccountChangePasswordController_ResultBoolResponse> {
  return clientWithAuth.put(CHANGE_PASSWORD, request).then((response) => response.data);
}

export function changeCurrentEmail(
  request: ChangeUserEmailController_EmailChangeRequest,
): Promise<ChangeUserEmailController_ResultBoolResponse> {
  return clientWithAuth.post(CHANGE_CURRENT_EMAIL, request).then((response) => response.data);
}

export function confirmCurrentVerifiedEmail(): Promise<SendCodeForCurrentVerifiedEmailController_EmailVerificationUuidResponse> {
  return clientWithAuth.get(CONFIRM_CURRENT_VERIFIED_EMAIL).then((response) => response.data);
}

export function verifyConfirmEmailCode(
  request: ConfirmEmailCodeController_EmailVerificationUuidAndCodeRequest,
): Promise<ConfirmEmailCodeController_EmailTokenResponse> {
  return clientWithAuth.post(VERIFY_CONFIRM_EMAIL_CODE, request).then((response) => response.data);
}

export function sendConfirmationCode(
  request: SendCodeForNewEmailController_NewEmailRequest,
): Promise<SendCodeForNewEmailController_EmailVerificationUuidResponse> {
  return clientWithAuth.post(SEND_CONFIRMATION_CODE, request).then((response) => response.data);
}

export function changeCurrentPhoneNumber(
  request: ChangeUserPhoneNumberController_PhoneNumberChangeRequest,
): Promise<ChangeUserPhoneNumberController_ResultBoolResponse> {
  return clientWithAuth.post(CHANGE_CURRENT_PHONE_NUMBER, request).then((response) => response.data);
}

export function confirmCurrentVerifiedPhoneNumber(): Promise<SendCodeForCurrentVerifiedPhoneController_PhoneVerificationUuidResponse> {
  return clientWithAuth.get(CONFIRM_CURRENT_VERIFIED_PHONE_NUMBER).then((response) => response.data);
}

export function verifyConfirmPhoneNumberCode(
  request: PhoneVerifierByTokenController_PhoneVerificationUuidAndCodeRequest,
): Promise<PhoneVerifierByTokenController_PhoneTokenResponse> {
  return clientWithAuth.post(PHONE_VERIFY, request).then((response) => response.data);
}

export function sendConfirmationPhoneNumberCode(
  request: PhoneTokenGetterByPhoneController_AuthSmsPhoneNumberRequest,
): Promise<PhoneTokenGetterByPhoneController_PhoneVerificationUuidResponse> {
  return clientWithAuth.post(PHONE_TOKEN, request).then((response) => response.data);
}

export function confirmEmail(code: string): Promise<ConfirmEmailViaLinkController_ResultBoolResponse> {
  const url = `${CONFIRM_EMAIL_VIA_LINK}?code=${code}`;
  return clientWithAuth.get<ConfirmEmailViaLinkController_ResultBoolResponse>(url).then((response) => response.data);
}

export function sendVerificationEmail(
  request: PrivateAccountRepeatEmailVerificationCodeController_RepeatEmailVerificationRequest,
): Promise<PrivateAccountRepeatEmailVerificationCodeController_ResultBoolResponse> {
  return clientWithAuth.post(SEND_VERIFICATION_CODE_EMAIL, request).then((response) => response.data);
}

export function getAccountInfo(): Promise<PrivateAccountInformationController_AccountInformationResponse> {
  return clientWithAuth
    .get<PrivateAccountInformationController_AccountInformationResponse>(GET_ACCOUNT_INFO)
    .then((response) => response.data);
}

export function getAtiAuthorizationUrl(): Promise<string> {
  return clientWithAuth
    .get<PrivateMoverAtiAuthController_AtiLinkResponse>(`${CONNECT_ATI}`)
    .then((response) => response.data.link);
}

export function searchCompany(request: PrivateAccountCompanyFinderController_CompanyRequest) {
  return clientWithAuth
    .post<PrivateAccountCompanyFinderController_CompanyInformationResponse>(SEARCH_COMPANY, request)
    .then((response) => response.data);
}

export function confirmCompany(
  request: PrivateAccountNewCompanyController_CompanyNewRequest,
): Promise<PrivateAccountNewCompanyController_ResultBoolResponse> {
  return clientWithAuth
    .post<PrivateAccountNewCompanyController_ResultBoolResponse>(CREATE_COMPANY, request)
    .then((response) => response.data);
}

export function downloadFile(fileId: string): Promise<DownloadFileResponse> {
  return clientWithAuth
    .get(`${DOWNLOAD_FILE}?uuid=${fileId}`, {
      responseType: "blob",
      // baseURL: "/",
    })
    .then((response) => {
      const header = response.headers["content-disposition"];
      const parts = header.split(";");
      return { fileName: parts[1].split("=")[1].replaceAll('"', ""), file: response.data };
    });
}

export function checkPhone(phoneNumber: string): Promise<AccountCheckPhoneController_ResultBoolResponse> {
  return clientDefault
    .get<AccountCheckPhoneController_ResultBoolResponse>(`${CHECK_PHONE}/${phoneNumber}`)
    .then((response) => response.data);
}

export function checkEmailAvailability(
  request: EmailAvailableController_EmailAvailableRequest,
): Promise<EmailAvailableController_ResultBoolResponse> {
  return clientDefault
    .post<EmailAvailableController_ResultBoolResponse>(`${CHECK_EMAIL_AVAILABILITY}`, request)
    .then((response) => response.data);
}

export function getSessions(): Promise<PrivateAccountSessionsListController_SessionResponse> {
  return clientWithAuth
    .get<PrivateAccountSessionsListController_SessionResponse>(GET_SESSIONS)
    .then((response) => response.data);
}

export function deleteSessions(): Promise<PrivateAccountDeleteSessionsController_ResultBoolResponse> {
  return clientWithAuth
    .delete<PrivateAccountDeleteSessionsController_ResultBoolResponse>(DELETE_SESSIONS)
    .then((response) => response.data);
}
