import {
  GetAboutPageDataResponse,
  GetContactsResponse,
  GetCustomerPageDataResponse,
  GetMoverPageDataResponse,
  GetNewsResponse,
  LandingEntity,
  LandingNewsEntity,
  RequestConsultationRequest,
  ResultResponse,
} from "@/domain";
import {
  AboutController_AboutPageResponse,
  ContactController_ContactResponse,
  CustomerMainPageController_CustomerMainPageResponse,
  MainEntity,
  MainFormApplicationController_ApplicationMainRequest,
  MainFormApplicationController_ResultBoolResponse,
  MoverMainPageController_MoverMainPageResponse,
  NewsController_NewsResponse,
  ShortNewsEntity,
} from "../codegen";

export function fromRequestConsultationRequest(
  request: RequestConsultationRequest,
): MainFormApplicationController_ApplicationMainRequest {
  return {
    application_main_entity: {
      name: request.name,
      email: request.email,
      phone_number: request.phoneNumber,
    },
  };
}

export function toResultResponse(response: MainFormApplicationController_ResultBoolResponse): ResultResponse {
  return {
    result: response.result_bool_entity ? response.result_bool_entity.result : false,
  };
}

function toLandingEntity(entity: MainEntity): LandingEntity {
  return {
    id: entity.id,
    title: entity.title!,
    subtitle: entity.subtitle!,
    text: entity.text!,
    createdAt: entity.created_at!,
    image: entity.static_image_entity
      ? {
          id: entity.static_image_entity.id!,
          name: entity.static_image_entity.name!,
          link: entity.static_image_entity.link!,
        }
      : null,
  };
}

function toNewsEntity(entity: ShortNewsEntity): LandingNewsEntity {
  return {
    id: entity.id,
    title: entity.title,
    createdAt: entity.date,
    image: entity.image,
  };
}

export function toGetNewsResponse(response: NewsController_NewsResponse): GetNewsResponse {
  return {
    items: response.news.map((e) => toNewsEntity(e)),
    pagination: response.pagination_entity,
  };
}

export function toGetContactsResponse(response: ContactController_ContactResponse): GetContactsResponse {
  return {
    address: response.address,
    phone: response.phone,
    emailBusiness: response.email_business,
    emailHr: response.email_hr,
    emailBookkeeping: response.email_bookkeeping,
    emailSupport: response.email_support,
    howToGetByAuto: response.how_to_get_by_auto,
    howToGetOnFoot: response.how_to_get_on_foot,
    companyDetails: response.company_details,
  };
}

export function toGetCustomerPageDataResponse(
  response: CustomerMainPageController_CustomerMainPageResponse,
): GetCustomerPageDataResponse {
  return {
    mainInfo: toLandingEntity(response.main_info!),
    workScheme: response.work_scheme!.map(toLandingEntity),
    howToUse: response.how_use!.map(toLandingEntity),
    questions: response.question!.map(toLandingEntity),
    reviews: response.review!.map(toLandingEntity),
    simpleText: response.simple_text!.map(toLandingEntity),
    advantages: response.advantages ? response.advantages.map(toLandingEntity) : [],
    features: response.features!.map(toLandingEntity),
  };
}

export function toGetMoverPageDataResponse(
  response: MoverMainPageController_MoverMainPageResponse,
): GetMoverPageDataResponse {
  return {
    mainInfo: toLandingEntity(response.main_info!),
    workScheme: response.work_scheme!.map(toLandingEntity),
    howToUse: response.how_use!.map(toLandingEntity),
    questions: response.question!.map(toLandingEntity),
    reviews: response.review!.map(toLandingEntity),
    simpleText: response.simple_text!.map(toLandingEntity),
  };
}

export function toGetAboutPageDataResponse(response: AboutController_AboutPageResponse): GetAboutPageDataResponse {
  // TODO: codegen
  // @ts-ignore
  return response.about.map(toLandingEntity);
}
