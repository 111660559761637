import React from "react";

import { Card } from "@/ui";
import { AppError } from "@/domain";
import { ErrorState } from "@/entities/error";

interface ErrorStateProps {
  error: AppError;
  onRetry: () => void;
}

const ErrorStateDashboard = ({ error, onRetry }: ErrorStateProps) => {
  return (
    <Card className="flex-grow flex justify-center items-center">
      <ErrorState error={error} onRetry={onRetry} />
    </Card>
  );
};

export default ErrorStateDashboard;
