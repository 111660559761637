import React from "react";
import clsx from "clsx";

interface Props extends React.InputHTMLAttributes<HTMLDivElement> {}

const Card: React.FC<Props> = ({ children, className, ...props }) => (
  <div className={clsx("rounded-2xl bg-white p-4 shadow-[0px_4px_20px_rgba(59,72,82,0.05)]", className)} {...props}>
    {children}
  </div>
);

export default React.memo(Card);
