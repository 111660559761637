import { account, auth, refreshAccessToken as refreshToken } from "@/api/kuber-api";
import {
  AuthTokensResponse,
  PhoneTokenRequest,
  RegisterAccountRequest,
  ResultResponse,
  SignInRequest,
  SmsMessageName,
} from "@/domain";
import { transformError } from "@/shared";

export function signIn(request: SignInRequest): Promise<AuthTokensResponse> {
  return auth.api
    .signIn(auth.mappers.fromSignInRequest(request))
    .then((response) => auth.mappers.toAuthTokensResponse(response))
    .catch((error) => {
      throw transformError(error);
    });
}

export function fetchPhoneVerificationUuid(phoneNumber: string, smsType: SmsMessageName): Promise<string> {
  return auth.api
    .fetchPhoneVerificationUuid({ phone_number: phoneNumber, sms_message_type: { value: smsType } })
    .then((response) => response.phone_verification_uuid)
    .catch((error) => {
      throw transformError(error);
    });
}

export function fetchPhoneToken(request: PhoneTokenRequest): Promise<string> {
  return auth.api
    .fetchPhoneToken(auth.mappers.fromPhoneTokenRequest(request))
    .then((response) => response.phone_token)
    .catch((error) => {
      throw transformError(error);
    });
}

export async function registerAccount(request: RegisterAccountRequest): Promise<AuthTokensResponse> {
  try {
    const response =
      request.profileType === "customer"
        ? await auth.api.registerCustomer(auth.mappers.toRegisterAccountInputDto(request.payload))
        : await auth.api.registerMover(auth.mappers.toRegisterAccountInputDto(request.payload));

    return auth.mappers.toAuthTokensResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export function refreshAccessToken(token: string) {
  return refreshToken(token);
}

export async function logout(): Promise<ResultResponse> {
  try {
    const response = await auth.api.logout();
    return account.mappers.toResultResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}
