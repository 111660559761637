import {
  AttachmentEntity,
  EntityWithProfileRequest,
  FileAttachment,
  PaginatedResponse,
  Pagination,
  ProfileTypePaginatedResponse,
  Sorting,
  UpdateEntityRequest,
} from "./common";
import { CustomerOrderEntity, TicketTitleAndContainerNumbersEntity } from "@/api/kuber-api/codegen";
import { RoleName } from "@/data/constants";

/** Tickets */

type TicketId = string;

type TicketStatus = "open" | "answered" | "closed" | string;

export type TicketRating = "rating_1" | "rating_2" | "rating_3" | "rating_4" | "rating_5" | string;

export type TicketEntity = {
  id: string;
  createdAt: string;
  answeredAt: string;
  theme?: string;
  message?: string;
  status: TicketStatus;
  rating: TicketRating;
};

export type TicketTitleEntity = {
  id: string;
  name: string;
  description: string | undefined;
  readonly: boolean;
  active: boolean;
};

export type GetTicketsListRequest = {
  pagination: Pagination;
  sorting: Sorting<"number_ticket" | "created_at">;
  status?: TicketStatus;
};

export type GetTicketsListResponse = PaginatedResponse<TicketEntity>;

export type TicketMessageEntity = {
  id: string;
  createdAt: string;
  text?: string;
  attachments?: AttachmentEntity[];
  senderType: TicketMessageSenderValue;
  read: boolean;
  movedFrom: boolean;
  movedTo: boolean;
  deleted: boolean;
  senderName?: string;
};

export type TicketChatMessageEntity = {
  id: string;
  createdAt: string;
  text: string;
  attachments?: Blob[];
  senderType: TicketMessageSenderValue;
  read: boolean;
  moved: boolean;
};

export const ticketMessageSenderTypes = {
  USER: "user",
  SUPPORT_EMPLOYEE: "support_employee",
  BOT: "bot",
  SYSTEM: "system",
} as const;

type TicketMessageSenderKeys = keyof typeof ticketMessageSenderTypes;
export type TicketMessageSenderValue = (typeof ticketMessageSenderTypes)[TicketMessageSenderKeys];

export type GetTicketMessagesRequest = {
  ticketId: TicketId;
  pagination: Pagination;
};

export type GetTicketId = {
  ticketId: string;
};

export type GetTicketChatRequest = {
  pagination: Pagination;
};

export type GetTicketMessagesResponse = PaginatedResponse<TicketMessageEntity>;

export type OpenTicketRequest = {
  text: string;
  attachments?: FileAttachment[];
};

export type OpenTicketResponse = {
  ticketId: string;
};

export type CloseTicketRequest = {
  ticketId: TicketId;
};

export type CloseTicketResponse = {
  result: boolean;
};

export type ChangeTicketPinnedStatusResponse = {
  ticketId: string;
  pinned: boolean;
};

export type RateTicketRequest = {
  ticketId: TicketId;
  rating: TicketRating;
};

export type RateTicketResponse = {
  result: boolean;
};

export type SendTicketMessageRequest = {
  ticketId: TicketId;
  text: string;
  attachments?: FileAttachment[];
};

export type SendTicketMessageResponse = {
  messageId?: string;
};

export type TicketStatusEntity = {
  id: string;
  name: string;
  description?: string;
  active: boolean;
};

type NewMessageEntity = {
  text: string | null;
  attachments?: FileAttachment[];
  read: boolean;
};

export type SendTicketChatMessageRequest = EntityWithProfileRequest<{
  ticketId: TicketId;
  message: NewMessageEntity;
}>;

export type OpenTicketChatRequest = UpdateEntityRequest<{
  title: string;
  pinned: boolean;
  message: NewMessageEntity;
}>;

export type GetTicketChatMessagesRequest = EntityWithProfileRequest<GetTicketMessagesRequest>;

export type GetTicketChatsRequest = UpdateEntityRequest<GetTicketChatRequest>;

export type ChangeTicketPinnedStatusRequest = EntityWithProfileRequest<ChangeTicketPinnedStatusResponse>;

export type GetOrdersListRequest = UpdateEntityRequest<{}>;

export type OrderItem = {
  orderId: string;
  containers: string[] | null;
};

export type TicketChatEntity = {
  id: string;
  title: string;
  createdAt: string;
  pinned: boolean;
  unread: number;
  order: CustomerOrderEntity | null;
  status: TicketStatus;
  lastMessage: string | null;
  isLastMessageSentByAdmin?: boolean;
  senderProfileType?: RoleName;
};

export type AdminTicketChatEntity = TicketChatEntity & {
  userId: string;
  prefix: string;
};

export type GetPaginatedAdminTicketChatsListResponse = PaginatedResponse<AdminTicketChatEntity>;
export type GetPaginatedTicketChatsListResponse = PaginatedResponse<TicketChatEntity>;
export type GetTicketChatsListResponse = ProfileTypePaginatedResponse<TicketChatEntity>;
export type GetTicketChatDirectoryResponse = TicketTitleAndContainerNumbersEntity[];
