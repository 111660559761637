import { tn } from "@/api/kuber-api";
import { ResultResponse, PrintTnRequest, CheckBeforePrintResultResponse } from "@/domain";
import { transformError } from "@/shared";

export function checkBeforePrint(request: PrintTnRequest): Promise<CheckBeforePrintResultResponse> {
  return tn.api
    .checkBeforePrintTN(tn.mappers.mapToCheckBeforePrintTNRequest(request))
    .then(tn.mappers.mapCheckBeforePrintTNReponseToResultResponse)
    .catch((error) => {
      throw transformError(error);
    });
}

export function print(request: PrintTnRequest): Promise<ResultResponse> {
  return tn.api
    .printTN(tn.mappers.mapToPrintTNRequest(request))
    .then(tn.mappers.mapPrintTNReponseToResultResponse)
    .catch((error) => {
      throw transformError(error);
    });
}
