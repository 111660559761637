import { AuthTokensResponse, PhoneTokenRequest, RegisterAccountRequest, SignInRequest } from "@/domain";
import {
  AuthenticationController_AuthenticationApiRequest,
  AuthenticationController_SecurityTokenResponse,
  CustomerRegistrationController_RegistrationInputDto,
  CustomerRegistrationController_SecurityTokenResponse,
  MoverRegistrationController_RegistrationInputDto,
  MoverRegistrationController_SecurityTokenResponse,
  PhoneVerifierByTokenController_PhoneVerificationUuidAndCodeRequest,
} from "../codegen";

export function fromSignInRequest(request: SignInRequest): AuthenticationController_AuthenticationApiRequest {
  return request;
}

export function toAuthTokensResponse(
  response:
    | AuthenticationController_SecurityTokenResponse
    | CustomerRegistrationController_SecurityTokenResponse
    | MoverRegistrationController_SecurityTokenResponse,
): AuthTokensResponse {
  return {
    accessToken: response.bearer_token,
    refreshToken: response.refresh_token,
  };
}

export function toRegisterAccountInputDto(
  request: RegisterAccountRequest["payload"],
): CustomerRegistrationController_RegistrationInputDto | MoverRegistrationController_RegistrationInputDto {
  return {
    email: request.email,
    password: request.password,
    mobile_phone_token: request.phoneToken,
  };
}

export function fromPhoneTokenRequest(
  request: PhoneTokenRequest,
): PhoneVerifierByTokenController_PhoneVerificationUuidAndCodeRequest {
  return { verification_uuid: request.phoneVerificationUuid, code: request.smsCode };
}
