import { ReactNode } from "react";

import Button from "@/components/Button";
import Success from "@/components/icons/Success";
import Error from "@/components/icons/Error";
import Warning from "@/components/icons/Warning";

interface Props {
  success: boolean;
  title?: ReactNode;
  subtitle?: ReactNode;
  errorCode?: ReactNode;
  button?: ReactNode;
  buttonText?: string;
  onButtonClick?: () => void;
  warning?: boolean;
}

const ModalResult: React.FC<Props> = ({
  success,
  title,
  subtitle,
  errorCode,
  button,
  buttonText,
  onButtonClick,
  warning,
}) => (
  <div className="flex flex-col items-center justify-center pb-2 pt-10">
    <span className="">
      {success ? (
        <Success />
      ) : warning ? (
        <Warning />
      ) : (
        <span className="block h-10 w-10">
          <Error />
        </span>
      )}
    </span>
    <div className="mt-8"></div>
    {title && <h4 className="text-center text-xl font-bold text-title">{title}</h4>}
    {subtitle && <span className="mt-4 text-center text-secondary">{subtitle}</span>}
    {errorCode && <div className="mt-8 text-base font-medium text-label">код ошибки: {errorCode}</div>}
    <div className="mt-14 w-full text-center">
      {button || (
        <Button onClick={onButtonClick} type="button" className="w-full">
          <span className="px-8">{buttonText}</span>
        </Button>
      )}
    </div>
  </div>
);

export default ModalResult;
