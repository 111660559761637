import {
  CancelWithdrawalRequest,
  CustomerBillingInfo,
  CustomerWithdrawRequest,
  DepositRequest,
  DepositResponse,
  GetBalanceOperationsHistoryRequest,
  GetBalanceOperationsHistoryResponse,
  GetBillingCreditsRequest,
  GetBillingCreditsResponse,
  GetFinesRequest,
  GetFinesResponse,
  GetInvoicesRequest,
  GetInvoicesResponse,
  GetWithdrawalsRequest,
  GetWithdrawalsResponse,
  MoverBillingInfo,
  MoverWithdrawRequest,
  PayCreditRequest,
  ProfileType,
  ResultResponse,
} from "@/domain";
import { balance } from "@/api/kuber-api";
import { transformError } from "@/shared";
import {
  AutoMoverBalanceWithdrawController_AutoBalanceWithdrawRequest,
  type CompletedWorkActEntity,
} from "@/api/kuber-api/codegen";

export async function getBalance(profileType: ProfileType): Promise<number> {
  try {
    const response =
      profileType === "customer" ? await balance.api.getCustomerBalance() : await balance.api.getMoverBalance();

    return response.balance ?? 0;
  } catch (error) {
    throw transformError(error);
  }
}

export async function getBillingInfo(profileType: ProfileType): Promise<MoverBillingInfo | CustomerBillingInfo> {
  try {
    if (profileType === "customer") {
      return await balance.api
        .getCustomerBillingInfo()
        .then((response) => balance.mappers.toCustomerBillingInfo(response));
    } else {
      return await balance.api.getMoverBillingInfo().then((response) => balance.mappers.toMoverBillingInfo(response));
    }
  } catch (error) {
    throw transformError(error);
  }
}

export async function getInvoices(request: GetInvoicesRequest): Promise<GetInvoicesResponse> {
  try {
    const response =
      request.profileType === "customer"
        ? await balance.api.getCustomerInvoices(request)
        : await balance.api.getMoverInvoices(request);

    return balance.mappers.toGetInvoicesResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getFines(request: GetFinesRequest): Promise<GetFinesResponse> {
  try {
    const response =
      request.profileType === "customer"
        ? await balance.api.getCustomerFines(request)
        : await balance.api.getMoverFines(request);

    return balance.mappers.toGetFinesResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getWithdrawals(request: GetWithdrawalsRequest): Promise<GetWithdrawalsResponse> {
  try {
    const response =
      request.profileType === "customer"
        ? await balance.api.getCustomerWithdrawals(request)
        : await balance.api.getMoverWithdrawals(request);

    return balance.mappers.toGetWithdrawalsResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getBalanceOperationsHistory(
  request: GetBalanceOperationsHistoryRequest,
): Promise<GetBalanceOperationsHistoryResponse> {
  try {
    const response =
      request.profileType === "customer"
        ? await balance.api.getCustomerOperationsHistory(request.payload)
        : await balance.api.getMoverOperationsHistory(request.payload);

    return balance.mappers.toGetBalanceOperationsHistoryResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getBillingCredits(request: GetBillingCreditsRequest): Promise<GetBillingCreditsResponse> {
  try {
    return balance.api
      .getBillingCredits(request)
      .then((response) => balance.mappers.toGetBalanceCreditsResponse(response));
  } catch (error) {
    throw transformError(error);
  }
}

export async function deposit(request: DepositRequest): Promise<DepositResponse> {
  try {
    const response =
      request.profileType === "customer"
        ? await balance.api.depositCustomer(request.payload)
        : await balance.api.depositMover(request.payload);

    return balance.mappers.toDepositResponse(response);
  } catch (error) {
    throw transformError(error);
  }
}

// export async function withdraw(request: MoverWithdrawRequest | CustomerWithdrawRequest): Promise<ResultResponse> {
//   try {
//     const response =
//       request.profileType === "customer"
//         ? await balance.api.withdrawCustomer(request.payload)
//         : await balance.api.withdrawMover(request.payload);
//
//     return balance.mappers.toResultResponse(response.result_bool_entity!);
//   } catch (error) {
//     throw transformError(error);
//   }
// }

export async function withdrawCustomer(request: CustomerWithdrawRequest): Promise<ResultResponse> {
  try {
    const response = await balance.api.withdrawCustomer(request.payload);

    return balance.mappers.toResultResponse(response.result_bool_entity!);
  } catch (error) {
    throw transformError(error);
  }
}

export async function withdrawMover(request: MoverWithdrawRequest): Promise<ResultResponse> {
  try {
    const response = await balance.api.withdrawMover(request.payload);

    return balance.mappers.toResultResponse(response.result_bool_entity!);
  } catch (error) {
    throw transformError(error);
  }
}

export async function getMoverCompletedWorkAct(): Promise<CompletedWorkActEntity[]> {
  try {
    const response = await balance.api.getMoverCompletedWorkAct();

    return response.completed_work_act_entities ?? [];
  } catch (error) {
    throw transformError(error);
  }
}

export async function cancelWithdrawalRequest(request: CancelWithdrawalRequest): Promise<ResultResponse> {
  try {
    const response =
      request.profileType === "customer"
        ? await balance.api.cancelCustomerWithdrawRequest({ application_withdraw_uuid: request.payload.withdrawalId })
        : await balance.api.cancelMoverWithdrawRequest({ application_withdraw_uuid: request.payload.withdrawalId });

    return balance.mappers.toResultResponse(response.result_bool_entity!);
  } catch (error) {
    throw transformError(error);
  }
}

export async function payCredit(request: PayCreditRequest): Promise<ResultResponse> {
  return balance.api
    .payCredit({ credit_uuid: request.creditId })
    .then((response) => balance.mappers.toResultResponse(response.result_bool_entity!))
    .catch((error) => {
      throw transformError(error);
    });
}

export async function changeAutoWithdrawState(
  request: AutoMoverBalanceWithdrawController_AutoBalanceWithdrawRequest,
): Promise<ResultResponse> {
  try {
    const response = await balance.api.changeAutoWithdrawState(request);

    return balance.mappers.toResultResponse(response.result_bool_entity!);
  } catch (error) {
    throw transformError(error);
  }
}
