import { clientDefault, clientWithAuth } from "../client";
import {
  CancelOrderController_AgOrderCancelRequest,
  CancelOrderController_ResultBoolResponse,
  ConfirmTransportInfoSentController_ConfirmTransportInfoSentResponse,
  GetActualOrdersListController_AgOrderListRequest,
  GetActualOrdersListController_AgOrderListResponse,
  GetArchiveOrdersListController_AgOrderListRequest,
  GetArchiveOrdersListController_AgOrderListResponse,
  GetLastActualOrdersController_GetLastActualOrdersResponse,
  GetOrderController_AgSingleOrderResponse,
  GetOrderRouteMapController_AgGetOrderRouteMapResponse,
  GetOrderStatusChainController_AgOrderCustomerStatusChainResponse,
  GetPromoCodesCustomerController_TariffCalculationPromoCodesCustomerResponse,
  GetPromoCodesOrderCustomerController_TariffCalculationPromoCodesOrderCustomerResponse,
  GetPublicTariffEmptyContainerController_PublicTariffCalculationEmptyContainerRequest,
  GetPublicTariffEmptyContainerController_PublicTariffCalculationResponse,
  GetPublicTariffFirstMileController_PublicTariffCalculationFirstMileRequest,
  GetPublicTariffFirstMileController_PublicTariffCalculationResponse,
  GetPublicTariffLastMileController_PublicTariffCalculationLastMileRequest,
  GetPublicTariffLastMileController_PublicTariffCalculationResponse,
  GetTariffEmptyContainerController_TariffCalculationEmptyContainerRequest,
  GetTariffEmptyContainerController_TariffCalculationResponse,
  GetTariffFirstMileController_TariffCalculationFirstMileRequest,
  GetTariffFirstMileController_TariffCalculationResponse,
  GetTariffLastMileController_TariffCalculationLastMileRequest,
  GetTariffLastMileController_TariffCalculationResponse,
  LastActualOrdersEntity,
  PlaceOrderEmptyContainerController_OrderEmptyContainerRequest,
  PlaceOrderEmptyContainerController_OrderResponse,
  PlaceOrderFirstMileController_OrderFirstMileRequest,
  PlaceOrderFirstMileController_OrderResponse,
  PlaceOrderLastMileController_OrderLastMileRequest,
  PlaceOrderLastMileController_OrderResponse,
  SearchOrderController_AgOrderSearchRequest,
  SearchOrderController_AgOrderSearchResponse,
  UpdateContainerDeliverySpotController_AgUpdateContainerDeliverySpotRequest,
  UpdateContainerDeliverySpotController_ResultBoolResponse,
  UpdateOrderTariffController_AgUpdateOrderTariffRequest,
  UpdateOrderTariffController_ResultBoolResponse,
} from "../codegen";
import {
  CALCULATE_TARIFF_EMPTY_CONTAINER,
  CALCULATE_TARIFF_EMPTY_CONTAINER_PRIVATE,
  CALCULATE_TARIFF_FIRST_MILE,
  CALCULATE_TARIFF_FIRST_MILE_PRIVATE,
  CALCULATE_TARIFF_LAST_MILE,
  CALCULATE_TARIFF_LAST_MILE_PRIVATE,
  CANCEL_ORDER,
  CHANGE_CONTAINER_DELIVERY_SPOT,
  CONFIRM_TRANSPORT_INFO,
  CREATE_ORDER_EMPTY_CONTAINER,
  CREATE_ORDER_FIRST_MILE,
  CREATE_ORDER_LAST_MILE,
  GET_ACTUAL_ORDERS,
  GET_ALL_CUSTOMER_PROMOS,
  GET_ORDER_BY_ID,
  GET_ORDER_MAP_BY_ID,
  GET_ORDER_STATUS_CHAIN,
  GET_UNUSED_CUSTOMER_PROMOS,
  SEARCH_ARCHIVE_ORDERS_BY_FILTER,
  SEARCH_ORDERS_BY_FILTER,
  SEARCH_ORDERS_BY_QUERY,
  UPDATE_TARIFF,
} from "../endpoints";
import { GetAllPromoListRequest } from "@/domain/admin";

export function getPublicEmptyContainerTariff(
  request: GetPublicTariffEmptyContainerController_PublicTariffCalculationEmptyContainerRequest,
): Promise<GetPublicTariffEmptyContainerController_PublicTariffCalculationResponse> {
  return clientDefault
    .post<GetPublicTariffEmptyContainerController_PublicTariffCalculationResponse>(
      CALCULATE_TARIFF_EMPTY_CONTAINER,
      request,
    )
    .then((response) => response.data);
}

export function getPublicFirstMileTariff(
  request: GetPublicTariffFirstMileController_PublicTariffCalculationFirstMileRequest,
): Promise<GetPublicTariffFirstMileController_PublicTariffCalculationResponse> {
  return clientDefault
    .post<GetPublicTariffFirstMileController_PublicTariffCalculationResponse>(CALCULATE_TARIFF_FIRST_MILE, request)
    .then((response) => response.data);
}

export function getPublicLastMileTariff(
  request: GetPublicTariffLastMileController_PublicTariffCalculationLastMileRequest,
): Promise<GetPublicTariffLastMileController_PublicTariffCalculationResponse> {
  return clientDefault
    .post<GetPublicTariffLastMileController_PublicTariffCalculationResponse>(CALCULATE_TARIFF_LAST_MILE, request)
    .then((response) => response.data);
}

export function getActualOrders(): Promise<LastActualOrdersEntity[]> {
  return clientDefault
    .get<GetLastActualOrdersController_GetLastActualOrdersResponse>(GET_ACTUAL_ORDERS)
    .then((response) => response.data.orders || []);
}

/**
 * расчёт тарифа для первой мили
 * docs: http://84.201.143.108:8990/front/#tag/Tariff/operation/v1.0TariffFirst_mileCalculate
 */
export function calculateFirstMileTariff(
  request: GetTariffFirstMileController_TariffCalculationFirstMileRequest,
): Promise<GetTariffFirstMileController_TariffCalculationResponse> {
  return clientWithAuth
    .post<GetTariffFirstMileController_TariffCalculationResponse>(CALCULATE_TARIFF_FIRST_MILE_PRIVATE, request)
    .then((response) => response.data);
}

/**
 * расчёт тарифа для последней мили
 * docs: http://84.201.143.108:8990/front/#tag/Tariff/operation/v1.0TariffLast_mileCalculate
 */
export function calculateLastMileTariff(
  request: GetTariffLastMileController_TariffCalculationLastMileRequest,
): Promise<GetTariffLastMileController_TariffCalculationResponse> {
  return clientWithAuth
    .post<GetTariffLastMileController_TariffCalculationResponse>(CALCULATE_TARIFF_LAST_MILE_PRIVATE, request)
    .then((response) => response.data);
}

/**
 * расчёт тарифа для порожнего контейнера
 * docs: http://84.201.143.108:8990/front/#tag/Tariff/operation/v1.0TariffEmpty_containerCalculate
 */
export function calculateEmptyContainerTariff(
  request: GetTariffEmptyContainerController_TariffCalculationEmptyContainerRequest,
): Promise<GetTariffEmptyContainerController_TariffCalculationResponse> {
  return clientWithAuth
    .post<GetTariffEmptyContainerController_TariffCalculationResponse>(
      CALCULATE_TARIFF_EMPTY_CONTAINER_PRIVATE,
      request,
    )
    .then((response) => response.data);
}

/**
 * метод получения деталей заказа по ID
 * docs: http://84.201.143.108:8990/front/#tag/Orders/operation/v1.0OrdersOrder%7BorderIdCode%7D
 */
export function getOrderById(orderId: string): Promise<GetOrderController_AgSingleOrderResponse> {
  return clientWithAuth
    .get<GetOrderController_AgSingleOrderResponse>(`${GET_ORDER_BY_ID}/${orderId}`)
    .then((response) => response.data);
}

export function getOrderMapById(orderId: string): Promise<GetOrderRouteMapController_AgGetOrderRouteMapResponse> {
  return clientWithAuth
    .get<GetOrderRouteMapController_AgGetOrderRouteMapResponse>(`${GET_ORDER_MAP_BY_ID}/${orderId}`)
    .then((response) => response.data);
}

/**
 * метод создания заказа (первая миля)
 * docs: http://84.201.143.108:8990/front/#tag/Orders/operation/v1.0OrdersNewFirst_mile
 */
export function createFirstMileOrder(
  request: PlaceOrderFirstMileController_OrderFirstMileRequest,
): Promise<PlaceOrderFirstMileController_OrderResponse> {
  return clientWithAuth
    .post<PlaceOrderFirstMileController_OrderResponse>(CREATE_ORDER_FIRST_MILE, request)
    .then((response) => response.data);
}

/**
 * метод создания заказа (последняя миля)
 * docs: http://84.201.143.108:8990/front/#tag/Orders/operation/v1.0OrdersNewFirst_mile
 */
export function createLastMileOrder(
  request: PlaceOrderLastMileController_OrderLastMileRequest,
): Promise<PlaceOrderLastMileController_OrderResponse> {
  return clientWithAuth
    .post<PlaceOrderLastMileController_OrderResponse>(CREATE_ORDER_LAST_MILE, request)
    .then((response) => response.data);
}

/**
 * метод создания заказа (порожний контейнер)
 * docs: http://84.201.143.108:8990/front/#tag/Orders/operation/v1.0OrdersNewEmpty_container
 */
export function createEmptyContainerOrder(
  request: PlaceOrderEmptyContainerController_OrderEmptyContainerRequest,
): Promise<PlaceOrderEmptyContainerController_OrderResponse> {
  return clientWithAuth
    .post<PlaceOrderEmptyContainerController_OrderResponse>(CREATE_ORDER_EMPTY_CONTAINER, request)
    .then((response) => response.data);
}

/**
 * метод удаления заказа
 * docs: http://84.201.143.108:8990/front/#tag/Orders/operation/v1.0OrdersCancel
 */
export function cancelOrder(
  request: CancelOrderController_AgOrderCancelRequest,
): Promise<CancelOrderController_ResultBoolResponse> {
  return clientWithAuth
    .put<CancelOrderController_ResultBoolResponse>(CANCEL_ORDER, request)
    .then((response) => response.data);
}

/**
 * метод обновления выбранных тарифов
 * docs: http://84.201.143.108:8990/front/#tag/Orders/operation/v1.0OrdersUpdate_tariff
 */
export function updateOrderTariffs(
  request: UpdateOrderTariffController_AgUpdateOrderTariffRequest,
): Promise<UpdateOrderTariffController_ResultBoolResponse> {
  return clientWithAuth.put(UPDATE_TARIFF, request).then((response) => response.data);
}

/**
 * метод подтверждения информации о перевозчике
 * docs: http://84.201.143.108:8990/front/#tag/Orders/operation/v1.0OrdersConfirm_transport_info_sent%7BorderIdCode%7D
 */
export function confirmOrderTransportInfo(
  orderId: string,
): Promise<ConfirmTransportInfoSentController_ConfirmTransportInfoSentResponse> {
  return clientWithAuth
    .put<ConfirmTransportInfoSentController_ConfirmTransportInfoSentResponse>(`${CONFIRM_TRANSPORT_INFO}/${orderId}`)
    .then((response) => response.data);
}

/**
 * метод получения цепочки статусов заказа
 * docs: http://84.201.143.108:8990/front/#tag/Orders/operation/v1.0OrdersStatus_chain%7BorderIdCode%7D
 */
export function getOrderStatusChain(
  orderId: string,
): Promise<GetOrderStatusChainController_AgOrderCustomerStatusChainResponse> {
  return clientWithAuth
    .get<GetOrderStatusChainController_AgOrderCustomerStatusChainResponse>(`${GET_ORDER_STATUS_CHAIN}/${orderId}`)
    .then((response) => response.data);
}

/**
 * метод поиска заказа по номеру контейнера
 * docs: http://84.201.143.108:8990/front/#tag/Orders/operation/v1.0OrdersSearch
 */
export function searchOrdersByQuery(
  request: SearchOrderController_AgOrderSearchRequest,
): Promise<SearchOrderController_AgOrderSearchResponse> {
  return clientWithAuth
    .post<SearchOrderController_AgOrderSearchResponse>(SEARCH_ORDERS_BY_QUERY, request)
    .then((response) => response.data);
}

/**
 * поиск активных заказов
 * docs: http://84.201.143.108:8990/front/#tag/Orders/operation/v1.0OrdersActual
 */
export function searchOrdersByFilter(
  request: GetActualOrdersListController_AgOrderListRequest,
): Promise<GetActualOrdersListController_AgOrderListResponse> {
  return clientWithAuth
    .post<GetActualOrdersListController_AgOrderListResponse>(SEARCH_ORDERS_BY_FILTER, request)
    .then((response) => response.data);
}

/**
 * поиск архивных заказов
 * docs: http://84.201.143.108:8990/front/#tag/Orders/operation/v1.0OrdersArchive
 */
export function searchArchiveOrdersByFilter(
  request: GetArchiveOrdersListController_AgOrderListRequest,
): Promise<GetArchiveOrdersListController_AgOrderListResponse> {
  return clientWithAuth
    .post<GetArchiveOrdersListController_AgOrderListResponse>(SEARCH_ARCHIVE_ORDERS_BY_FILTER, request)
    .then((response) => response.data);
}

export function getCustomerPromos(request: {
  customerUuid: string;
}): Promise<GetPromoCodesOrderCustomerController_TariffCalculationPromoCodesOrderCustomerResponse> {
  const url = GET_UNUSED_CUSTOMER_PROMOS + request.customerUuid;
  return clientWithAuth
    .get<GetPromoCodesOrderCustomerController_TariffCalculationPromoCodesOrderCustomerResponse>(url)
    .then((response) => response.data);
}

export function getCustomerAllPromos(
  request: GetAllPromoListRequest,
): Promise<GetPromoCodesCustomerController_TariffCalculationPromoCodesCustomerResponse> {
  // const sorting = `&sorting_order_by=${request.sorting.direction}&sorting_field=${request.sorting.field}`;
  const url = `${GET_ALL_CUSTOMER_PROMOS}${request.customer_uuid}?offset=${request.pagination.offset}&limit=${request.pagination.limit}`;
  return clientWithAuth
    .get<GetPromoCodesCustomerController_TariffCalculationPromoCodesCustomerResponse>(url)
    .then((response) => response.data);
}

export function changeContainerDeliverySpot(
  request: UpdateContainerDeliverySpotController_AgUpdateContainerDeliverySpotRequest,
): Promise<UpdateContainerDeliverySpotController_ResultBoolResponse> {
  return clientWithAuth.post(CHANGE_CONTAINER_DELIVERY_SPOT, request).then((response) => response.data);
}
