export const buttonSizes = {
  LARGE: "large",
  MEDIUM: "medium",
  SMALL: "small",
} as const;

export const buttonVariants = {
  CONTAIN: "contain",
  OUTLINE: "outline",
  GRADIENT: "gradient",
  LINK: "link",
} as const;

export const iconPlacements = {
  LEFT: "left",
  RIGHT: "right",
  TWO: "two",
} as const;
