import { useEffect } from "react";
import { useToggle, useWindowSize } from "react-use";
import NextLink from "next/link";
import { useRouter } from "next/router";
import clsx from "clsx";

import useUser from "@/data/hooks/useUser";
import Button from "@/components/Button2";
import GhostButton from "@/components/GhostButton";
import NoSSR from "@/components/NoSSR";
import MenuIcon from "./MenuIcon";
import Section from "@/components/Section2";

import MainBetaLogo from "@/components/icons/logo/MainBeta";
import { breakpoints } from "@/styles/constants";
import { initialAdminPage, initialDashboardPage, kuberFormattedPhoneNumber, kuberPhoneNumber } from "@/data/constants";

const menu = [
  {
    title: "Главная",
    href: "/",
  },
  {
    title: "О компании",
    href: "/about",
  },
  // {
  //   title: "Перевозчикам",
  //   href: "/mover",
  // },
  {
    title: "Новости",
    href: "/news",
  },
  {
    title: "Контакты",
    href: "/contacts",
  },
];

export default function Header() {
  const { width } = useWindowSize();

  return (
    <Section>
      <header className="flex h-14 w-full max-w-xxl flex-col items-start justify-end gap-3 py-2 tablet:h-28 tablet:pb-0 tablet:pt-5 tablet:text-base desktop:h-32 desktop:pb-0 desktop:pt-6 desktop:text-lg">
        <div className="flex w-full items-center justify-between tablet:items-start">
          {width < breakpoints.TABLET ? <MainBetaLogo isMobile={true} /> : <MainBetaLogo isMobile={false} />}

          <NoSSR>
            <div className="hidden tablet:block">
              <Buttons isMobile={false} />
            </div>
            <div className="tablet:hidden">
              <MobileMenu />
            </div>
          </NoSSR>
        </div>
        <div className="hidden h-12 w-full items-center justify-between p-0 tablet:flex">
          <Menu />
          <Phone />
        </div>
      </header>
    </Section>
  );
}

const Buttons = ({ isMobile }: { isMobile: boolean }) => {
  const { user } = useUser();

  if (user) return <UserButtons isMobile={isMobile} />;

  return <AnonymousButtons isMobile={isMobile} />;
};

const AnonymousButtons = ({ isMobile }: { isMobile: boolean }) => {
  const router = useRouter();

  return (
    <div className="flex w-full flex-col-reverse gap-3 tablet:flex-row tablet:items-start tablet:justify-end tablet:gap-2 desktop:gap-3">
      <Button
        size={isMobile ? "medium" : "small"}
        onClick={() => router.push("/auth/register")}
        variant="outline"
        data-testid="header-register-button"
      >
        Регистрация
      </Button>
      <Button
        onClick={() => router.push("/auth/login")}
        size={isMobile ? "medium" : "small"}
        data-testid="header-login-button"
      >
        Войти
      </Button>
    </div>
  );
};

const UserButtons = ({ isMobile }: { isMobile: boolean }) => {
  const { logoutWithoutRedirect, user } = useUser();
  const router = useRouter();

  return (
    <div className="flex w-full flex-col-reverse gap-3 tablet:flex-row tablet:items-start tablet:justify-end tablet:gap-2 desktop:gap-3">
      <Button
        size={isMobile ? "medium" : "small"}
        onClick={logoutWithoutRedirect}
        variant="outline"
        data-testid="logout-button"
      >
        Выход
      </Button>
      <Button
        onClick={() =>
          !user!.isUserProfile && user!.isAdminProfile
            ? router.push(initialAdminPage)
            : router.push(initialDashboardPage)
        }
        size={isMobile ? "medium" : "small"}
        data-testid="go-to-dashboard-button"
      >
        Личный кабинет
      </Button>
    </div>
  );
};

interface MenuProps {
  onNavigate?: () => void;
}

const Menu = ({ onNavigate = () => {} }: MenuProps) => {
  const router = useRouter();

  return (
    <div
      className={clsx(
        "flex h-full w-full flex-col tablet:w-fit tablet:flex-row tablet:items-center",
        "text-left",
        "gap-0 tablet:gap-8",
        "text-textBodySemi tablet:text-textBody desktop:text-textMedium",
      )}
    >
      {menu.map((item, idx) => (
        <NextLink href={router.pathname === item.href ? "" : item.href} key={idx}>
          <a
            className={clsx(
              "box-border h-fit whitespace-nowrap px-6 py-3 tablet:border-b-[3px] tablet:px-0 tablet:pb-[5px] tablet:pt-2 tablet:text-primaryText desktop:pb-[7px] desktop:pt-[0.625rem]",
              router.pathname === item.href
                ? "text-grayscale-500 hover:cursor-default hover:text-primaryText active:text-primaryText tablet:border-primary tablet:focus-visible:outline-none"
                : "active:hover:bg-bgDisabled active:hover:text-active tablet:border-transparent tablet:hover:text-hover tablet:focus-visible:-mx-3 tablet:focus-visible:rounded-lg tablet:focus-visible:bg-white tablet:focus-visible:px-3 tablet:focus-visible:outline-none tablet:focus-visible:ring-4 tablet:focus-visible:ring-bgMiddleBlue tablet:active:hover:bg-transparent",
            )}
            onClick={onNavigate}
          >
            {item.title}
          </a>
        </NextLink>
      ))}
    </div>
  );
};

const MobileMenu = () => {
  const [visible, toggle] = useToggle(false);
  useEffect(() => {
    document.body.style.overflow = visible ? "hidden" : "";
  }, [visible]);

  return (
    <>
      <GhostButton borderless={true} onClick={toggle}>
        <div className="flex items-center justify-center">
          <MenuIcon />
        </div>
      </GhostButton>
      {visible && <Sidebar onNavigate={toggle} />}
    </>
  );
};

interface Props {
  onNavigate: () => void;
}

const Sidebar: React.FC<Props> = ({ onNavigate }) => (
  <div className="absolute bottom-0 left-0 right-0 top-14 z-50 flex w-full flex-col overflow-auto bg-white">
    <div className="flex w-full grow pt-2">
      <Menu onNavigate={onNavigate} />
    </div>
    <div className="flex flex-col items-start gap-6 p-6">
      <div className="flex w-full justify-center">
        <Buttons isMobile={true} />
      </div>
      <div className="flex w-full justify-center">
        <Phone />
      </div>
    </div>
  </div>
);

const Phone = () => {
  return (
    <a
      href={`tel:${kuberPhoneNumber}`}
      className="flex items-center gap-2 text-textMediumSemi text-label tablet:text-textBodySemi desktop:text-textMediumSemi"
    >
      {/* <span className="flex h-4 w-4 items-center justify-center"> */}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        className="fill-label"
      >
        <path d="M18.1753 14.6243C18.0837 14.416 17.9337 14.241 17.717 14.091L14.9587 12.1327C14.7503 11.991 14.5587 11.8827 14.3753 11.8077C14.192 11.7327 14.0253 11.6993 13.867 11.6993C13.6587 11.6993 13.467 11.7577 13.2753 11.8743C13.0837 11.9743 12.8837 12.1327 12.6753 12.341L12.042 12.966C11.9503 13.0577 11.842 13.1077 11.7003 13.1077C11.6253 13.1077 11.5587 13.0993 11.492 13.0743C11.4337 13.0493 11.3837 13.0243 11.342 13.0077C11.167 12.916 10.9587 12.7827 10.7253 12.6077C10.4337 12.3577 9.24199 11.3077 8.94199 11.016C8.50866 10.5743 8.10866 10.1327 7.73366 9.69935C7.36699 9.26602 7.10866 8.90768 6.95866 8.63268C6.94199 8.58268 6.91699 8.53268 6.89199 8.46602C6.87533 8.39935 6.86699 8.34102 6.86699 8.27435C6.86699 8.14102 6.90866 8.03268 7.00033 7.94102L7.63366 7.28268C7.83366 7.08268 7.99199 6.88268 8.10033 6.69102C8.21699 6.49935 8.27533 6.29935 8.27533 6.09935C8.27533 5.94102 8.23366 5.76602 8.15866 5.59102C8.08366 5.40768 7.97533 5.21602 7.82533 5.00768L5.89199 2.28268C5.74199 2.06602 5.55033 1.91602 5.33366 1.81602C5.12533 1.71602 4.89199 1.66602 4.65866 1.66602C4.04199 1.66602 3.45866 1.92435 2.92533 2.44935C2.48366 2.86602 2.16699 3.33268 1.96699 3.84102C1.76699 4.34102 1.66699 4.88268 1.66699 5.44935C1.66699 6.31602 1.86699 7.22435 2.26699 8.17435C2.66699 9.11602 3.21699 10.066 3.90033 11.016C4.59199 11.966 5.36699 12.8743 6.22533 13.741C6.50866 14.0243 7.70033 15.066 8.00033 15.3327C8.30866 15.591 8.63366 15.8327 8.95866 16.0743C9.90866 16.766 10.867 17.316 11.8253 17.7243C12.7837 18.1327 13.692 18.3327 14.542 18.3327C15.117 18.3327 15.6503 18.2243 16.142 18.0243C16.142 18.0243 16.1587 18.016 16.167 18.016C16.6753 17.8077 17.1253 17.4827 17.5337 17.0327C17.7753 16.766 17.9587 16.4827 18.1003 16.1827C18.1337 16.116 18.1587 16.0493 18.1837 15.9827C18.267 15.7493 18.3087 15.5077 18.3087 15.2743C18.3087 15.041 18.2587 14.8327 18.1753 14.6243Z" />
      </svg>
      {/* </span> */}

      <span>{kuberFormattedPhoneNumber}</span>
    </a>
  );
};
