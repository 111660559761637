import React from "react";
import clsx from "clsx";
import { buttonSizes, buttonVariants } from "@/components/Button2/constants";
import Loading from "@/components/Button2/Loading";

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: typeof buttonVariants.LINK | typeof buttonVariants.OUTLINE | typeof buttonVariants.CONTAIN;
  loading?: boolean;
  size?: typeof buttonSizes.SMALL | typeof buttonSizes.MEDIUM | typeof buttonSizes.LARGE;
  fullWidth?: boolean;
}

// const Spinner = React.memo(() => (
//   <div className="flex items-center justify-center rounded-2xl">
//     <div className="inline-block h-6 w-6 animate-spin rounded-full border-2 border-r-transparent" role="status"></div>
//   </div>
// ));

const Button: React.FC<ButtonProps> = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, loading, disabled, variant, size = buttonSizes.MEDIUM, className, fullWidth, ...props }, ref) => {
    let css;
    switch (variant) {
      case buttonVariants.LINK:
        css = clsx("text-primary", {
          "cursor-not-allowed opacity-70": disabled,
          "hover:text-primary-dark active:text-secondary": !disabled,
        });
        break;
      case buttonVariants.OUTLINE:
        css = clsx("relative w-full rounded-xl text-primary bg-white border-primary border", {
          "min-h-[56px] px-6 text-textMediumSemi": size === buttonSizes.LARGE,
          "min-h-[48px] px-6 text-textBodySemi": size === buttonSizes.MEDIUM,
          "min-h-[40px] px-4 text-textBodySemi": size === buttonSizes.SMALL,
          "cursor-not-allowed !bg-white !text-label !border-bg": disabled && !loading,
          "active:bg-active active:text-white hover:bg-hover hover:text-white focus-visible:outline-none focus-visible:border-0 focus-visible:ring-4 focus-visible:ring-bgMiddleBlue":
            !disabled,
          "cursor-not-allowed": loading,
          "tablet:w-full": fullWidth,
          "tablet:w-fit": !fullWidth,
        });
        break;
      default:
        css = clsx("relative w-full rounded-xl text-white bg-primary", {
          "min-h-[56px] px-6 text-textMediumSemi": size === buttonSizes.LARGE,
          "min-h-[48px] px-6 text-textBodySemi": size === buttonSizes.MEDIUM,
          "min-h-[40px] px-4 text-textBodySemi": size === buttonSizes.SMALL,
          "cursor-not-allowed !bg-bg !text-label": disabled && !loading,
          "hover:bg-hover active:bg-active focus-visible:outline-none focus-visible:border-0 focus-visible:ring-4 focus-visible:ring-bgMiddleBlue":
            !disabled,
          "cursor-not-allowed": loading,
          "tablet:w-full": fullWidth,
          "tablet:w-fit": !fullWidth,
        });
    }

    return (
      <button ref={ref} className={clsx(css, className)} disabled={disabled} {...props}>
        {!loading ? (
          children
        ) : (
          <>
            <div className="absolute left-0 right-0 mx-auto">
              <Loading />
            </div>
            <label className="invisible">{children}</label>
          </>
        )}
      </button>
    );
  },
);

export default React.memo(Button);
