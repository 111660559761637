import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { config } from "@/shared";
import { GET_ACCOUNT_INFO_QUERY } from "@/data/query-keys";

export const SyncAccountInfo = () => {
  const queryClient = useQueryClient();
  useEffect(() => {
    function subscribeToPostMessage(ev: MessageEvent<any>) {
      if (ev.data === config.EMAIL_CONFIRMED_EVENT) {
        queryClient.invalidateQueries(GET_ACCOUNT_INFO_QUERY);
      }
    }
    function subscribeToLocalStorageKey(ev: StorageEvent) {
      if (ev.key === config.EMAIL_CONFIRMED_EVENT) {
        queryClient.invalidateQueries(GET_ACCOUNT_INFO_QUERY);
      }
    }

    window.addEventListener("message", subscribeToPostMessage);
    window.addEventListener("storage", subscribeToLocalStorageKey);

    return () => {
      window.removeEventListener("message", subscribeToPostMessage);
      window.removeEventListener("storage", subscribeToLocalStorageKey);
    };
  }, [queryClient]);

  return null;
};
