export const palette = {
  phoneIcon: "#7E8B99",
  menuIcon: "#272E35",
};

export const breakpoints = {
  MOBILE: 360,
  TABLET: 760,
  LAPTOP: 1024,
  DESKTOP: 1280,
  DESKTOP1366: 1366,
  DESKTOP1536: 1536,
  DESKTOP1920: 1920,
} as const;

export const messages = {
  ERROR: "error",
  SUCCESS: "success",
  WARNING: "warning",
  INFO: "info",
} as const;
type MessageKeys = keyof typeof messages;
export type MessageValue = (typeof messages)[MessageKeys];
