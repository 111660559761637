export const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i;

export const emailLoginRegex = /^[a-zA-Z0-9а-яА-Я.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i;

export const phoneNumberRegex = /^79[0-9]{9}$/i;

export const confirmationCodeRegex = /^[0-9]{4}$/i;

export const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

export const dayRegex = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/;

export const addressDeepness = /^(,*[a-zA-Z]){2}/;

export const minLengthRegex = /^.{8,}$/;

export const atLeastOneDigitRegex = /\d/;

export const atLeastOneUppercaseAndOneLowercaseLettersRegex = /(?=.*[a-z])(?=.*[A-Z])/;

export const specialConfirmationCodeRegex = /^[0-9]{4}$/i;

export const dayTimeRegex =
  /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s* ([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

export const alternativePhoneNumberRegex = /^[+]79[0-9]{9}$/i;
