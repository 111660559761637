import { UpdateEntityRequest } from "./common";

export type SignInRequest = {
  email: string;
  password: string;
};

export type AccountChangePasswordRequest = {
  email: string;
};

export type AuthTokensResponse = {
  accessToken: string;
  refreshToken: string;
};

export type PhoneTokenRequest = {
  phoneVerificationUuid: string;
  smsCode: string;
};

export type RegisterAccountRequest = UpdateEntityRequest<{
  email: string;
  password: string;
  phoneToken: string;
}>;

export const smsMessageTypes = {
  REGISTRATION_CODE: "registration_code",
  CHANGE_PHONE_NUMBER_CODE: "change_phone_number_code",
} as const;

type SmsMessageKeys = keyof typeof smsMessageTypes;
export type SmsMessageName = (typeof smsMessageTypes)[SmsMessageKeys];
