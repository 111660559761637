import {
  CalculateAdminTariffRequest,
  CreateTn2022Request,
  CreateTn2022Response,
  GetUserInfoResponse,
  RecalculateTariffOrderEntityResponse,
  SearchUsersResponse,
  TariffOrderEntityResponse,
  UserEntity,
} from "@/domain/admin";
import { toAttachmentEntity } from "../shared/mappers";
import {
  AddContainerNumberToOrderController_AddContainerNumberToOrderResponse,
  AddCreditCustomerAdminController_CreditCustomerResponse,
  AdminTariffEmptyMileController_PublicTariffCalculationEmptyContainerRequest,
  AdminTariffsFirstMileController_PublicTariffCalculationFirstMileRequest,
  AdminTariffsLastMileController_PublicTariffCalculationLastMileRequest,
  CreateTn2022ManuallyController_AgCreateTn2022Request,
  CreateTn2022ManuallyController_AgCreateTn2022Response,
  EditUserProfileController_EditUserProfileRequest,
  GetOrderRouteMapController_AgGetOrderRouteMapResponse,
  GetTicketListAdminController_TicketListAdminResponse,
  GetUserInfoByAdminController_UserInfoAdminResponse,
  GetUsersListController_AgUserListResponse,
  TariffOrderEntity,
  type TicketListAdminEntity,
  UserInfoEntity,
} from "../codegen";
import { toKuberEnum } from "../shared";
import { AdminTicketChatEntity, GetPaginatedAdminTicketChatsListResponse, ResultResponse } from "@/domain";
import { EditUserFormState } from "@/admin/data/form/types";
import { toMapResponse } from "@/api/kuber-api/orders/orders-mappers";

export function fromCreateTn2022Request(
  request: CreateTn2022Request,
): CreateTn2022ManuallyController_AgCreateTn2022Request {
  return {
    delivery_type: toKuberEnum(request.deliveryType),
    tn_date: request.tnDate,
    tn_number: request.tnNumber,
    order_date: request.orderDate,
    order_number: request.orderNumber,
    order_number_kuber: request.kuberOrderNumber,
    shipper_inn: request.shipperInn,
    consignee_inn: request.consigneeInn,
    client_inn: request.clientInn,
    mover_inn: request.moverInn,
    warehouse_inn: request.warehouseInn,
    cargo_container_type: toKuberEnum(request.containerType),
    cargos: request.cargos.map((c) => ({
      cargo_container_height: c.containerHeight ? toKuberEnum(c.containerHeight) : undefined,
      cargo_container_number: c.containerNumber,
      cargo_name: c.cargoName,
      cargo_total_weight_nett: c.cargoWeight,
      zpu: c.zpu,
    })),
    driver_inn: request.driverInn,
    driver_fio: request.driverFio,
    driver_phone: request.driverPhone,
    truck_brand: toKuberEnum(request.truckBrand),
    truck_weight_capacity: request.truckWeightCapacity,
    truck_gov_number: request.truckGovNumber,
    truck_possession_type: request.truckOwnership
      ? { own: 1, joint_property: 2, rent: 3, leasing: 4, free_use: 5 }[request.truckOwnership]
      : undefined,
    semitrailer_brand: toKuberEnum(request.semiTrailerBrand),
    semitrailer_weight_capacity: request.semiTrailerWeightCapacity,
    semitrailer_gov_number: request.semiTrailerGovNumber,
    // TODO: //
    address_load: undefined,
    address_warehouse: request.warehouseAddress,
    // TODO: //
    address_unload: undefined,
    terminal_load: toKuberEnum(request.loadTerminal),
    terminal_unload: toKuberEnum(request.unloadTerminal),
    terminal_load_inn: undefined,
    terminal_unload_inn: undefined,
    date_load: request.loadDate,
    date_unload: request.unloadDate,
    shipper_is_expeditor: request.shipperIsExpeditor,
    time_load: null,
    time_unload: null,
    genset_required: request.gensetRequired,
    temperature_from: request.temperatureFrom,
    temperature_to: request.temperatureTo,
    dangerous_cargo: request.dangerousCargo,
    dangerous_cargo_class: request.dangerousCargoClass,
    number_of_copies: request.numberOfCopies,
  };
}

export function toCreateTn2022Response(
  response: CreateTn2022ManuallyController_AgCreateTn2022Response,
): CreateTn2022Response {
  return toAttachmentEntity(response.entity!);
}

export function toResultResponse(response: AddCreditCustomerAdminController_CreditCustomerResponse): ResultResponse {
  return {
    result: response.result_bool_entity ? response.result_bool_entity.result : false,
    message: response.result_bool_entity ? response.result_bool_entity.message ?? undefined : undefined,
  };
}

export function toAddContainerToOrderResultResponse(
  response: AddContainerNumberToOrderController_AddContainerNumberToOrderResponse,
): ResultResponse {
  return {
    result: response.entity ? response.entity.result : false,
    message: response.entity ? response.entity.message ?? undefined : undefined,
  };
}

export function toEditUserRequest(data: EditUserFormState): EditUserProfileController_EditUserProfileRequest {
  return {
    user_id: data.user?.user_id ?? "",
    profile_entity: {
      first_name: data.firstName,
      second_name: data.secondName,
      middle_name: !!data.middleName ? data.middleName : null,
      address_registration: !!data.addressRegistration ? data.addressRegistration : null,
      passport_serial: !!data.passportSerial ? data.passportSerial : null,
      passport_number: !!data.passportNumber ? data.passportNumber : null,
      personal_inn: !!data.personalInn ? data.personalInn : null,
      position: data.position,
      passport_date_issue: !!data.passportDateIssue ? data.passportDateIssue.split(".").reverse().join("-") : null,
      passport_issued_by: !!data.passportIssuedBy ? data.passportIssuedBy : null,
      passport_department_code: !!data.passportDepartmentCode ? data.passportDepartmentCode : null,
      address_post: !!data.addressPost ? data.addressPost : null,
    },
  };
}

export function toSearchUsersResponse(response: GetUsersListController_AgUserListResponse): SearchUsersResponse {
  const users = response.user_info_entities ? response.user_info_entities.map((entry) => toUsersListEntity(entry)) : [];
  return {
    items: users,
    pagination: response.pagination ? response.pagination : { offset: 0, limit: 10, total: users.length },
  };
}

export function toUsersListEntity(entity: UserInfoEntity): UserEntity {
  return {
    id: entity.user_id,
    email: entity.email ?? undefined,
    prefix: entity.prefix,
    phoneNumber: entity.mobile_phone ?? undefined,
    personalInn: entity.profile_entity?.personal_inn ?? undefined,
    fio: entity.profile_entity
      ? (entity.profile_entity.second_name ? entity.profile_entity.second_name + " " : "") +
        (entity.profile_entity.first_name ? entity.profile_entity.first_name + " " : "") +
        (entity.profile_entity.middle_name ?? "")
      : undefined,
    companyLegalForm: entity.company_entity?.legal_form ?? undefined,
    companyInn: entity.company_entity?.company_inn ?? undefined,
    companyKpp: entity.company_entity?.kpp ?? undefined,
    companyOgrn: entity.company_entity?.ogrn ?? undefined,
    companyOkpo: entity.company_entity?.okpo ?? undefined,
    verificationStatus:
      entity.dir_verification_status_enum.value === "partially_verified"
        ? "Упрощенная верификация"
        : entity.dir_verification_status_enum.value === "full_verified"
        ? "Полная верификация"
        : "Верификация не пройдена",
    createdAt: entity.created_at,
    bankBic: entity.bank_bill_entity?.bank_bic ?? undefined,
    bankBill: entity.bank_bill_entity?.bank_bill ?? undefined,
    bankName: entity.bank_bill_entity?.bank_name ?? undefined,
    bankCorAccount: entity.bank_bill_entity?.bank_correspondent_account ?? undefined,
    companyFio: entity.company_entity
      ? (entity.company_entity.executive_agency_second_name
          ? entity.company_entity.executive_agency_second_name + " "
          : "") +
        (entity.company_entity.executive_agency_first_name
          ? entity.company_entity.executive_agency_first_name + " "
          : "") +
        (entity.company_entity.executive_agency_middle_name ?? "")
      : undefined,
    companyPosition: entity.company_entity?.executive_agency_position ?? undefined,
    companyNalogType: entity.company_entity?.dir_nalog_type_enum?.value ?? undefined,
    position: entity.profile_entity?.position ?? undefined,
    roles: entity.roles.map((role) => role.value ?? ""),
    companyName: entity.company_entity?.company_name,
    companyAddressRegistration: entity.company_entity?.address_registration ?? undefined,
    companyAddressPost: entity?.company_entity?.address_post ?? undefined,
    addressPost: entity.profile_entity?.address_post ?? undefined,
    addressRegistration: entity.profile_entity?.address_registration ?? undefined,
    passportDepartmentCode: entity.profile_entity?.passport_department_code ?? undefined,
    passportIssueDate: entity.profile_entity?.passport_date_issue ?? undefined,
    passportIssuedBy: entity.profile_entity?.passport_issued_by ?? undefined,
    passportNumber: entity.profile_entity?.passport_number ?? undefined,
    passportSerial: entity.profile_entity?.passport_serial ?? undefined,
    passportSerialNumber: entity.profile_entity
      ? !!entity.profile_entity.passport_serial && !!entity.profile_entity.passport_number
        ? entity.profile_entity.passport_serial + " " + entity.profile_entity.passport_number
        : undefined
      : undefined,
    emailStatus: entity.email_verified,
    responsibleLogist: entity.responsible_logist_for_customer_entity ?? undefined,
  };
}

export function toEmptyMileTariffRequest(
  request: CalculateAdminTariffRequest,
): AdminTariffEmptyMileController_PublicTariffCalculationEmptyContainerRequest {
  return {
    pickup_city: toKuberEnum(request.pickupCity),
    pickup_terminal: toKuberEnum(request.pickupTerminal),
    delivery_city: toKuberEnum(request.deliveryCity),
    delivery_terminal: toKuberEnum(request.deliveryTerminal),
    how_to_carry: request.howToCarry ? toKuberEnum(request.howToCarry) : undefined,
    container_type: toKuberEnum(request.containerType),
    container_class: request.containerClass ? toKuberEnum(request.containerClass) : undefined,
    cargos: request.cargos.map((c) => ({
      container_height: c.containerHeight ? toKuberEnum(c.containerHeight) : undefined,
      cargo_weight: c.cargoWeight,
    })),
  };
}

export function toFirstMileTariffRequest(
  request: CalculateAdminTariffRequest,
): AdminTariffsFirstMileController_PublicTariffCalculationFirstMileRequest {
  return {
    pickup_city: toKuberEnum(request.pickupCity),
    pickup_terminal: toKuberEnum(request.pickupTerminal),
    delivery_city: toKuberEnum(request.deliveryCity),
    delivery_terminal: toKuberEnum(request.deliveryTerminal),
    how_to_carry: request.howToCarry ? toKuberEnum(request.howToCarry) : undefined,
    container_type: toKuberEnum(request.containerType),
    container_class: request.containerClass ? toKuberEnum(request.containerClass) : undefined,
    cargos: request.cargos.map((c) => ({
      container_height: c.containerHeight ? toKuberEnum(c.containerHeight) : undefined,
      cargo_weight: c.cargoWeight,
    })),
    warehouse_address: request.warehouseAddress!,
  };
}

export function toLastMileTariffRequest(
  request: CalculateAdminTariffRequest,
): AdminTariffsLastMileController_PublicTariffCalculationLastMileRequest {
  return {
    pickup_city: toKuberEnum(request.pickupCity),
    pickup_terminal: toKuberEnum(request.pickupTerminal),
    delivery_city: toKuberEnum(request.deliveryCity),
    delivery_terminal: toKuberEnum(request.deliveryTerminal),
    how_to_carry: request.howToCarry ? toKuberEnum(request.howToCarry) : undefined,
    container_type: toKuberEnum(request.containerType),
    container_class: request.containerClass ? toKuberEnum(request.containerClass) : undefined,
    cargos: request.cargos.map((c) => ({
      container_height: c.containerHeight ? toKuberEnum(c.containerHeight) : undefined,
      cargo_weight: c.cargoWeight,
    })),
    warehouse_address: request.warehouseAddress!,
  };
}

function toMapOrderEntity(request: TariffOrderEntity): GetOrderRouteMapController_AgGetOrderRouteMapResponse {
  return {
    entity: request.route_map_entity,
  };
}

export function toTariffOrderEntityResponse(
  request: TariffOrderEntity | undefined,
): TariffOrderEntityResponse | undefined {
  if (!request) return;
  return {
    route_map_entity: toMapResponse(toMapOrderEntity(request)),
    distance: request.distance ?? 0,
    tariff_entities: request.tariff_entities ?? [],
  };
}

export function toRecalculateTariffOrderEntityResponse(
  request: TariffOrderEntity | undefined,
): RecalculateTariffOrderEntityResponse | undefined {
  if (!request) return;
  return {
    tariff_order_entity: request,
    route_map_entity: toMapResponse(toMapOrderEntity(request)),
    distance: request.distance ?? 0,
    tariff_entities: request.tariff_entities ?? [],
  };
}

// SUPPORT

export function toGetTicketChatListResponse(
  response: GetTicketListAdminController_TicketListAdminResponse,
): GetPaginatedAdminTicketChatsListResponse {
  return {
    items: response.ticket_list_entities ? response.ticket_list_entities.map(toTicketEntity) : [],
    pagination: response.pagination!,
  };
}

export function toTicketEntity(ticket: TicketListAdminEntity): AdminTicketChatEntity {
  return {
    id: ticket.id!,
    userId: ticket.user_id,
    status: ticket.status?.value as string,
    createdAt: ticket.created_at,
    order: ticket.order,
    pinned: ticket.pinned_by_user,
    unread: ticket.unread_message_count,
    title: ticket.title,
    lastMessage: ticket.last_message_sent_at ?? null,
    prefix: ticket.user_uniq_code,
    senderProfileType: ticket.user_role.value === "ROLE_CUSTOMER" ? "customer" : "mover",
    isLastMessageSentByAdmin: ticket.last_message_sender_type?.value !== "user",
  };
}

export function toUserInfoResponse(response: GetUserInfoByAdminController_UserInfoAdminResponse): GetUserInfoResponse {
  const { user_entity, company_entity, profile_entity } = response;
  return {
    user: {
      prefix: user_entity.uniq_code,
      email: user_entity.email,
      phoneNumber: user_entity.phone_number,
      role: user_entity.role.value ?? "",
    },
    company: {
      addressPost: company_entity.address_post ?? undefined,
      addressRegistration: company_entity.address_registration_entity
        ? {
            standard: company_entity.address_registration_entity.standard,
            postalCode: company_entity.address_registration_entity.postal_code,
            fullAddress: company_entity.address_registration_entity.full_address,
            egrul: company_entity.address_registration_entity.egrul,
          }
        : undefined,
      inn: company_entity.inn,
      kpp: company_entity.kpp ?? undefined,
      legalForm: company_entity.legal_form,
      name: company_entity.name,
    },
    profile: profile_entity
      ? {
          firstName: profile_entity.first_name,
          secondName: profile_entity.second_name,
          middleName: !!profile_entity.middle_name ? profile_entity.middle_name : "",
          position: profile_entity.position,
          passport_date_issue: profile_entity.passport_date_issue ?? undefined,
          passport_number: profile_entity.passport_number ?? undefined,
          passport_serial: profile_entity.passport_serial ?? undefined,
          passport_department_code: profile_entity.passport_department_code ?? undefined,
          passport_issued_by: profile_entity.passport_issued_by ?? undefined,
        }
      : undefined,
  };
}
