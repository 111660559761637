type EnumLike = {
  value?: string;
};

export function fromKuberEnum(enumValue: EnumLike) {
  // TODO: codegen – 'value' should be generated as required
  return enumValue.value!;
}

export function toKuberEnum(value: string): EnumLike {
  return {
    value,
  };
}
