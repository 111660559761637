export const TITLE_REGEX = /^#(.+)#\s(.+)/i;
export const LINE_BREAKS_REGEX = /(<br>|<br >|<br\/>|<br \/>)/i;
export const BOLD_REGEX = /(\*\*(.+?)\*\*)/gi;

type ParsedErrorMessage = {
  title?: string;
  description?: string;
};

export function isFormattedErrorMessage(error: string) {
  const hasTitle = TITLE_REGEX.test(error);
  const hasLineBreaks = LINE_BREAKS_REGEX.test(error);
  const hasBoldText = BOLD_REGEX.test(error);

  return hasTitle || hasLineBreaks || hasBoldText;
}

export function parseErrorMessage(error: string): ParsedErrorMessage {
  if (!isFormattedErrorMessage(error)) {
    return {
      description: error,
    };
  }

  const result: ParsedErrorMessage = {};

  let restMessage = error;
  const hasTitle = TITLE_REGEX.test(error);
  if (hasTitle) {
    const matches = restMessage.match(TITLE_REGEX);
    if (matches?.length === 3) {
      result.title = matches[1];

      restMessage = matches[2];
    }
  }

  const hasBoldText = new RegExp(BOLD_REGEX).test(restMessage);
  if (hasBoldText) {
    const matches = restMessage.match(BOLD_REGEX);
    if (matches) {
      matches.forEach((match) => {
        let boldPart = match;
        const originalBoldPart = boldPart;
        let i = 1;
        while (boldPart.includes("**")) {
          boldPart = boldPart.replace("**", i % 2 > 0 ? "<b>" : "</b>");
          i++;
        }
        restMessage = restMessage.replace(originalBoldPart, boldPart);
      });
    }
  }
  result.description = restMessage;

  return result;
}
