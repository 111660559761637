import {
  GetDriverListRequest,
  GetDriversRequest,
  GetSemiTrailerListRequest,
  GetSemiTrailersRequest,
  GetTruckListRequest,
  GetTrucksRequest,
} from "@/domain";
import { clientWithAuth } from "../client";
import {
  ADD_DRIVER,
  ADD_SEMI_TRAILER,
  ADD_TRUCK,
  ARCHIVE_ENTITY,
  GET_DRIVER,
  GET_DRIVERS,
  GET_DRIVERS_LIST,
  GET_SEMI_TRAILERS,
  GET_SEMITRAILER,
  GET_SEMITRAILERS_LIST,
  GET_TRUCK,
  GET_TRUCKS,
  GET_TRUCKS_LIST,
  UNARCHIVE_ENTITY,
  UPDATE_DRIVER,
  UPDATE_ENTITY_DOCUMENT,
  UPDATE_SEMI_TRAILER,
  UPDATE_TRUCK,
} from "../endpoints";
import {
  GetDriverCardController_DriverCardResponse,
  GetDriverListController_DriverListResponseV2,
  GetSemiTrailerCardController_SemiTrailerCardResponse,
  GetSemiTrailerListController_SemiTrailerListResponseV2,
  GetTruckCardController_TruckCardResponse,
  GetTruckListController_TruckListResponseV2,
  PrivateTransportAddToArchiveV2Controller_ResultBoolResponse,
  PrivateTransportAddToArchiveV2Controller_UpdateTransportUniqCodeRequest,
  PrivateTransportDeleteFromArchiveController_ResultBoolResponse,
  PrivateTransportDeleteFromArchiveController_UpdateTransportUniqCodeRequest,
  PrivateTransportDriverListController_DriverListResponse,
  PrivateTransportNewDriverController_NewDriverRequest,
  PrivateTransportNewDriverController_ResultBoolResponse,
  PrivateTransportNewSemiTrailerController_NewSemiTrailerRequest,
  PrivateTransportNewSemiTrailerController_ResultBoolResponse,
  PrivateTransportNewTruckController_NewTruckRequest,
  PrivateTransportNewTruckController_ResultBoolResponse,
  PrivateTransportSemiTrailerListController_SemiTrailerListResponse,
  PrivateTransportTruckListController_TruckListResponse,
  UpdateDriverController_ResultBoolResponse,
  UpdateDriverController_UpdateDriverRequest,
  UpdateSemiTrailerController_ResultBoolResponse,
  UpdateSemiTrailerController_UpdateSemiTrailerRequest,
  UpdateTransportDocumentsController_ResultBoolResponse,
  UpdateTransportDocumentsController_UpdateDocumentTransportRequest,
  UpdateTruckController_ResultBoolResponse,
  UpdateTruckController_UpdateTruckRequest,
} from "../codegen";
import { mapQueryParam, prepareQueryParam } from "@/data/utils";

export function getDrivers(
  request: GetDriversRequest,
): Promise<PrivateTransportDriverListController_DriverListResponse> {
  return clientWithAuth
    .get<PrivateTransportDriverListController_DriverListResponse>(
      `${GET_DRIVERS}?limit=${request.limit}&offset=${request.offset}&archive=${request.archive}`,
    )
    .then((response) => response.data);
}

export function updateDriver(
  request: UpdateDriverController_UpdateDriverRequest,
): Promise<UpdateDriverController_ResultBoolResponse> {
  return clientWithAuth
    .post<UpdateDriverController_ResultBoolResponse>(UPDATE_DRIVER, request)
    .then((response) => response.data);
}

export function addDriver(
  request: PrivateTransportNewDriverController_NewDriverRequest,
): Promise<PrivateTransportNewDriverController_ResultBoolResponse> {
  return clientWithAuth
    .post<PrivateTransportNewDriverController_ResultBoolResponse>(ADD_DRIVER, request)
    .then((response) => response.data);
}

export function getTrucks(request: GetTrucksRequest): Promise<PrivateTransportTruckListController_TruckListResponse> {
  return clientWithAuth
    .get<PrivateTransportTruckListController_TruckListResponse>(
      `${GET_TRUCKS}?limit=${request.limit}&offset=${request.offset}&archive=${request.archive}`,
    )
    .then((response) => response.data);
}

export function updateTruck(
  request: UpdateTruckController_UpdateTruckRequest,
): Promise<UpdateTruckController_ResultBoolResponse> {
  return clientWithAuth
    .post<UpdateTruckController_ResultBoolResponse>(UPDATE_TRUCK, request)
    .then((response) => response.data);
}

export function addTruck(
  request: PrivateTransportNewTruckController_NewTruckRequest,
): Promise<PrivateTransportNewTruckController_ResultBoolResponse> {
  return clientWithAuth
    .post<PrivateTransportNewTruckController_ResultBoolResponse>(ADD_TRUCK, request)
    .then((response) => response.data);
}

export function getSemitrailers(
  request: GetSemiTrailersRequest,
): Promise<PrivateTransportSemiTrailerListController_SemiTrailerListResponse> {
  return clientWithAuth
    .get<PrivateTransportSemiTrailerListController_SemiTrailerListResponse>(
      `${GET_SEMI_TRAILERS}?limit=${request.limit}&offset=${request.offset}&archive=${request.archive}`,
    )
    .then((response) => response.data);
}

export function updateSemiTrailer(
  request: UpdateSemiTrailerController_UpdateSemiTrailerRequest,
): Promise<UpdateSemiTrailerController_ResultBoolResponse> {
  return clientWithAuth
    .post<UpdateSemiTrailerController_ResultBoolResponse>(UPDATE_SEMI_TRAILER, request)
    .then((response) => response.data);
}

export function addSemiTrailer(
  request: PrivateTransportNewSemiTrailerController_NewSemiTrailerRequest,
): Promise<PrivateTransportNewSemiTrailerController_ResultBoolResponse> {
  return clientWithAuth
    .post<PrivateTransportNewSemiTrailerController_ResultBoolResponse>(ADD_SEMI_TRAILER, request)
    .then((response) => response.data);
}

export function archiveEntity(
  request: PrivateTransportAddToArchiveV2Controller_UpdateTransportUniqCodeRequest,
): Promise<PrivateTransportAddToArchiveV2Controller_ResultBoolResponse> {
  return clientWithAuth.put(ARCHIVE_ENTITY, request).then((response) => response.data);
}

export function unarchiveEntity(
  request: PrivateTransportDeleteFromArchiveController_UpdateTransportUniqCodeRequest,
): Promise<PrivateTransportDeleteFromArchiveController_ResultBoolResponse> {
  return clientWithAuth.put(UNARCHIVE_ENTITY, request).then((response) => response.data);
}

export function updateDocument(
  request: UpdateTransportDocumentsController_UpdateDocumentTransportRequest,
): Promise<UpdateTransportDocumentsController_ResultBoolResponse> {
  return clientWithAuth
    .post<UpdateTransportDocumentsController_ResultBoolResponse>(UPDATE_ENTITY_DOCUMENT, request)
    .then((response) => response.data);
}

export function getDriversList(request: GetDriverListRequest): Promise<GetDriverListController_DriverListResponseV2> {
  const optionalQueryParams = mapQueryParam(prepareQueryParam(request.filter));
  return clientWithAuth
    .get<GetDriverListController_DriverListResponseV2>(
      `${GET_DRIVERS_LIST}?limit=${request.pagination.limit}&offset=${request.pagination.offset}${optionalQueryParams}`,
    )
    .then((response) => response.data);
}

export function getTrucksList(request: GetTruckListRequest): Promise<GetTruckListController_TruckListResponseV2> {
  const optionalQueryParams = mapQueryParam(prepareQueryParam(request.filter));
  return clientWithAuth
    .get<GetTruckListController_TruckListResponseV2>(
      `${GET_TRUCKS_LIST}?limit=${request.pagination.limit}&offset=${request.pagination.offset}${optionalQueryParams}`,
    )
    .then((response) => response.data);
}

export function getSemitrailersList(
  request: GetSemiTrailerListRequest,
): Promise<GetSemiTrailerListController_SemiTrailerListResponseV2> {
  const optionalQueryParams = mapQueryParam(prepareQueryParam(request.filter));
  return clientWithAuth
    .get<GetSemiTrailerListController_SemiTrailerListResponseV2>(
      `${GET_SEMITRAILERS_LIST}?limit=${request.pagination.limit}&offset=${request.pagination.offset}${optionalQueryParams}`,
    )
    .then((response) => response.data);
}

export function getDriver(uniq: string): Promise<GetDriverCardController_DriverCardResponse> {
  const url = GET_DRIVER + "?driver_uniq_code=" + uniq;
  return clientWithAuth.get<GetDriverCardController_DriverCardResponse>(url).then((response) => response.data);
}

export function getTruck(uniq: string): Promise<GetTruckCardController_TruckCardResponse> {
  const url = GET_TRUCK + "?truck_uniq_code=" + uniq;
  return clientWithAuth.get<GetTruckCardController_TruckCardResponse>(url).then((response) => response.data);
}

export function getSemitrailer(uniq: string): Promise<GetSemiTrailerCardController_SemiTrailerCardResponse> {
  const url = GET_SEMITRAILER + "?semi_trailer_uniq_code=" + uniq;
  return clientWithAuth
    .get<GetSemiTrailerCardController_SemiTrailerCardResponse>(url)
    .then((response) => response.data);
}
