import React, { ClipboardEventHandler, useCallback } from "react";
import { IMaskMixin } from "react-imask";

import Input, { InputProps } from "@/components/Input2";

const MaskedInput = IMaskMixin(({ inputRef, ...props }) => <Input {...props} ref={inputRef} />);

export const PhoneInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ onChange, onPaste, ...props }: InputProps, ref) => {
    const handleAccept = useCallback(
      (value: string, obj: any) => {
        const unmaskValue = value ? value.replace(/\W/g, "") : value;
        // // Check if the first number is '8'
        // if (unmaskValue.indexOf("8") === 2) {
        //   // @ts-ignore
        //   onChange && onChange("7");
        //   return;
        // }
        // @ts-ignore
        onChange && onChange(unmaskValue);
      },
      [onChange],
    );
    const handlePaste: ClipboardEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        let pasteValue = e.clipboardData.getData("text");
        if (pasteValue.length === 10) {
          pasteValue = `7${pasteValue}`;
        } else if (pasteValue.length === 11 && pasteValue[0] === "8") {
          pasteValue = `7${pasteValue.substring(1)}`;
        } else {
          return;
        }
        e.preventDefault();
        // @ts-ignore
        onChange(pasteValue);
        // onPaste && onPaste(pasteValue);
      },
      [onChange],
    );

    return (
      <MaskedInput
        {...props}
        // @ts-ignore type of values incompatible
        inputRef={ref}
        mask="+7 (900) 000-00-00"
        lazy={true}
        placeholderChar="_"
        onPaste={handlePaste}
        onAccept={handleAccept}
        unmask={undefined}
      />
    );
  },
);

export const NumberInput = React.forwardRef<HTMLInputElement, InputProps>(({ onChange, ...props }: InputProps, ref) => {
  const handleAccept = useCallback(
    (value: string) => {
      // @ts-ignore
      onChange && onChange(value);
    },
    [onChange],
  );

  return (
    // @ts-ignore type of values incompatible
    <MaskedInput inputRef={ref} mask={/\d+/} lazy={true} placeholderChar="_" onAccept={handleAccept} {...props} />
  );
});

export const WeightInput = React.forwardRef<HTMLInputElement, InputProps>(({ onChange, ...props }: InputProps, ref) => {
  const handleAccept = useCallback(
    (value: string) => {
      // @ts-ignore
      onChange && onChange(value);
    },
    [onChange],
  );

  return (
    <MaskedInput
      // @ts-ignore type of values incompatible
      inputRef={ref}
      mask={Number}
      thousandsSeparator=" "
      lazy={false}
      scale={0}
      radix="."
      placeholderChar="_"
      onAccept={handleAccept}
      {...props}
    />
  );
});

export const PriceInput = React.forwardRef<HTMLInputElement, InputProps>(({ onChange, ...props }: InputProps, ref) => {
  const handleAccept = useCallback(
    (value: string) => {
      // @ts-ignore
      onChange && onChange(value);
    },
    [onChange],
  );

  return (
    // @ts-ignore type of values incompatible
    <MaskedInput
      mask={"num р."}
      blocks={{ num: { mask: Number, thousandsSeparator: " " } }}
      // thousandsSeparator=" "
      lazy={false}
      placeholderChar="_"
      onAccept={handleAccept}
      {...props}
    />
  );
});

interface ConfirmationCodeInputProps extends InputProps {
  onValueChange: () => void;
}

const formatInput = (input: string) => {
  return input
    .split(" ")
    .filter((item) => item !== "_")
    .join("");
};
export const ConfirmationCodeInput = React.forwardRef<HTMLInputElement, ConfirmationCodeInputProps>(
  ({ onChange, onValueChange, ...props }: ConfirmationCodeInputProps, ref) => {
    const handleAccept = useCallback(
      (value: string) => {
        const unmaskValue = formatInput(value);
        // @ts-ignore
        onChange && onChange(unmaskValue);
      },
      [onChange],
    );

    return (
      <MaskedInput
        mask="0{ }0{ }0{ }0"
        // @ts-ignore type of values incompatible
        inputRef={ref}
        lazy={false}
        placeholderChar="_"
        onAccept={handleAccept}
        {...props}
      />
    );
  },
);

export default MaskedInput;
