import {
  AddressEntity,
  AttachmentEntity,
  DocumentFileEntity,
  WorkflowCheckEntity,
  WorkflowCheckSide,
  WorkflowCheckStatusType,
} from "@/domain";
import {
  AddressEntity as KuberAddressEntity,
  AgDocsOutputFileEntity,
  DocumentFileEntity as KuberDocumentFileEntity,
  WorkflowCheckEntity as KuberWorkflowCheckEntity,
} from "../codegen";

export function toDocumentFileEntity(file: KuberDocumentFileEntity): DocumentFileEntity {
  return {
    id: file.id,
    originalName: file.original_name,
    extension: file.extension,
    mimeType: file.mime_type,
    typeId: file.type_id,
    typeName: file.type_name,
    comment: file.comment ?? undefined,
  };
}

// TODO: codegen
export function toWorkflowCheckEntity(check: KuberWorkflowCheckEntity): WorkflowCheckEntity {
  if (!check.conf_name_status_entity) throw new Error("check.conf_name_status_entity is required");

  return {
    id: check.id,
    name: check.name!,
    description: check.description ?? undefined,
    comment: check.comment ?? undefined,
    createdAt: check.created_at,
    updatedAt: check.updated_at,
    isDone: check.is_done,
    isManual: check.is_manual,
    documentFile: check.file_entity ? toDocumentFileEntity(check.file_entity) : undefined,
    confNameStatus: {
      name: check.conf_name_status_entity.name!,
      side: check.conf_name_status_entity.name_status_type_enum?.value as WorkflowCheckSide,
      status: {
        id: check.conf_name_status_entity.dir_status_entity?.id! as WorkflowCheckStatusType,
        name: check.conf_name_status_entity.dir_status_entity?.name!,
        description: check.conf_name_status_entity.dir_status_entity?.description!,
        active: check.conf_name_status_entity.dir_status_entity?.active!,
      },
      type: {
        id: check.conf_name_status_entity.dir_type_entity?.id!,
        name: check.conf_name_status_entity.dir_type_entity?.name!,
        description: check.conf_name_status_entity.dir_type_entity?.description!,
        group: {
          id: check.conf_name_status_entity.dir_type_entity?.workflow_dir_group_entity?.id!,
          name: check.conf_name_status_entity.dir_type_entity?.workflow_dir_group_entity?.name!,
          description: check.conf_name_status_entity.dir_type_entity?.workflow_dir_group_entity?.description!,
        },
        category: check.conf_name_status_entity.dir_type_entity?.category!,
        accountType: check.conf_name_status_entity.dir_type_entity?.account_type!,
      },
    },
    checkGroup: {
      verificationStatus: {
        value: check.check_group_entity?.dir_verification_status_enum?.value!,
      },
    },
  };
}

export function toAddressEntity(addressEntity: KuberAddressEntity): AddressEntity {
  return {
    standard: addressEntity.standard,
    postalCode: addressEntity.postal_code ?? undefined,
    fullAddress: addressEntity.full_address ?? undefined,
  };
}

export function toAttachmentEntity(entity: AgDocsOutputFileEntity): AttachmentEntity {
  // TODO: codegen
  return {
    id: entity.attachment_name!,
    link: entity.link!,
    originalName: entity.original_name!,
    mimeType: entity.mime_type?.value!,
    extension: entity.extension?.value!,
  };
}
