import {
  ActualOrderEntity,
  CalculateOrderTariffPublicRequest,
  CalculateTariffRequest,
  CancelOrderRequest,
  CreateOrderRequest,
  FIRST_MILE_DELIVERY_TYPE,
  GetOrderByIdResponse,
  LAST_MILE_DELIVERY_TYPE,
  MarkerTypePoint,
  OrderIdType,
  OrderStatusEntry,
  OrderTariffEntity,
  ResultResponse,
  RoutePointTuple,
  SearchOrdersRequest,
  SearchOrdersResponse,
  UpdateOrderTariffsRequest,
} from "@/domain";
import { orders } from "@/api/kuber-api";
import { transformError } from "@/shared";
import {
  GetOrderController_AgSingleOrderResponse,
  GetPromoCodesCustomerController_TariffCalculationPromoCodesCustomerResponse,
  GetTariffEmptyContainerController_TariffCalculationEmptyContainerRequest,
  GetTariffEmptyContainerController_TariffCalculationResponse,
  GetTariffFirstMileController_TariffCalculationFirstMileRequest,
  GetTariffFirstMileController_TariffCalculationResponse,
  GetTariffLastMileController_TariffCalculationLastMileRequest,
  GetTariffLastMileController_TariffCalculationResponse,
  type PromoCodeEntity,
  UpdateContainerDeliverySpotController_AgUpdateContainerDeliverySpotRequest,
} from "@/api/kuber-api/codegen";
import { GetAllPromoListRequest } from "@/domain/admin";

export function getActualOrders(): Promise<ActualOrderEntity[]> {
  return orders.api.getActualOrders().then((response) => response.map(orders.mappers.toActualOrderEntity));
}

export function calculateTariffPublic(request: CalculateOrderTariffPublicRequest): Promise<number> {
  if (request.deliveryType === FIRST_MILE_DELIVERY_TYPE) {
    return orders.api
      .getPublicFirstMileTariff(orders.mappers.toPublicFirstMileTariffRequest(request))
      .then(orders.mappers.fromCalculateTariffResponse)
      .catch((error) => {
        throw transformError(error);
      });
  } else if (request.deliveryType === LAST_MILE_DELIVERY_TYPE) {
    return orders.api
      .getPublicFirstMileTariff(orders.mappers.toPublicFirstMileTariffRequest(request))
      .then(orders.mappers.fromCalculateTariffResponse)
      .catch((error) => {
        throw transformError(error);
      });
  } else {
    return orders.api
      .getPublicEmptyContainerTariff(orders.mappers.toPublicCalculateTariffRequest(request))
      .then(orders.mappers.fromCalculateTariffResponse)
      .catch((error) => {
        throw transformError(error);
      });
  }
}

export function calculateTariff(request: CalculateTariffRequest): Promise<{
  tariffs?: OrderTariffEntity[];
  distance: number;
  markers: MarkerTypePoint[];
  routeMap: RoutePointTuple[][];
}> {
  if (request.deliveryType === FIRST_MILE_DELIVERY_TYPE) {
    return orders.api
      .calculateFirstMileTariff(orders.mappers.fromFirstMileTariffRequest(request))
      .then(orders.mappers.toOrderTariffs)
      .catch((error) => {
        throw transformError(error);
      });
  } else if (request.deliveryType === LAST_MILE_DELIVERY_TYPE) {
    return orders.api
      .calculateLastMileTariff(orders.mappers.fromLastMileTariffRequest(request))
      .then(orders.mappers.toOrderTariffs)
      .catch((error) => {
        throw transformError(error);
      });
  } else {
    return orders.api
      .calculateEmptyContainerTariff(orders.mappers.fromEmptyContainerTariffRequest(request))
      .then(orders.mappers.toOrderTariffs)
      .catch((error) => {
        throw transformError(error);
      });
  }
}

export async function calculateFirstMileTariff(
  request: GetTariffFirstMileController_TariffCalculationFirstMileRequest,
): Promise<GetTariffFirstMileController_TariffCalculationResponse> {
  return orders.api.calculateFirstMileTariff(request).catch((error) => {
    throw transformError(error);
  });
}

export async function calculateLastMileTariff(
  request: GetTariffLastMileController_TariffCalculationLastMileRequest,
): Promise<GetTariffLastMileController_TariffCalculationResponse> {
  return orders.api.calculateLastMileTariff(request).catch((error) => {
    throw transformError(error);
  });
}

export async function calculateEmptyContainerTariff(
  request: GetTariffEmptyContainerController_TariffCalculationEmptyContainerRequest,
): Promise<GetTariffEmptyContainerController_TariffCalculationResponse> {
  return orders.api.calculateEmptyContainerTariff(request).catch((error) => {
    throw transformError(error);
  });
}

export function getOrderById(orderId: string): Promise<GetOrderByIdResponse> {
  return orders.api
    .getOrderById(orderId)
    .then(orders.mappers.toGetOrderByIdResponse)
    .catch((err) => {
      throw transformError(err);
    });
}

export function getOrderMapById(orderId: string) {
  return orders.api
    .getOrderMapById(orderId)
    .then(orders.mappers.toMapResponse)
    .catch((err) => {
      throw transformError(err);
    });
}

export function getOrderByIdAfterCodegen(orderId: string): Promise<GetOrderController_AgSingleOrderResponse> {
  return orders.api
    .getOrderById(orderId)

    .catch((err) => {
      throw transformError(err);
    });
}

export function createOrder(request: CreateOrderRequest): Promise<OrderIdType> {
  if (request.deliveryType === FIRST_MILE_DELIVERY_TYPE) {
    return orders.api
      .createFirstMileOrder(orders.mappers.fromCreateFirstMileOrderRequest(request))
      .then((response) => response.order_id_code!)
      .catch((error) => {
        throw transformError(error);
      });
  } else if (request.deliveryType === LAST_MILE_DELIVERY_TYPE) {
    return orders.api
      .createLastMileOrder(orders.mappers.fromCreateLastMileOrderRequest(request))
      .then((response) => response.order_id_code!)
      .catch((error) => {
        throw transformError(error);
      });
  } else {
    return orders.api
      .createEmptyContainerOrder(orders.mappers.fromCreateEmptyContainerOrderRequest(request))
      .then((response) => response.order_id_code!)
      .catch((error) => {
        throw transformError(error);
      });
  }
}

export function cancelOrder(request: CancelOrderRequest): Promise<ResultResponse> {
  return orders.api
    .cancelOrder(orders.mappers.fromCancelOrderRequest(request))
    .then(orders.mappers.toResultResponse)
    .catch((error) => {
      throw transformError(error);
    });
}

export function updateOrderTariffs(request: UpdateOrderTariffsRequest): Promise<ResultResponse> {
  return orders.api
    .updateOrderTariffs(orders.mappers.fromUpdateOrderTariffsRequest(request))
    .then(orders.mappers.toResultResponse)
    .catch((error) => {
      throw transformError(error);
    });
}

export function confirmOrderTransportInfo(orderId: string): Promise<ResultResponse> {
  return orders.api
    .confirmOrderTransportInfo(orderId)
    .then((response) =>
      response.entity
        ? { result: response.entity.result, message: response.entity.message ?? undefined }
        : { result: false },
    )
    .catch((error) => {
      throw transformError(error);
    });
}

export function getOrderStatusChain(orderId: string): Promise<OrderStatusEntry[]> {
  return orders.api
    .getOrderStatusChain(orderId)
    .then(orders.mappers.toOrderStatusChain)
    .catch((error) => {
      throw transformError(error);
    });
}

export function searchOrdersByQuery(query: string): Promise<SearchOrdersResponse> {
  return orders.api
    .searchOrdersByQuery({ query })
    .then(orders.mappers.toSearchOrdersResponse)
    .catch((error) => {
      throw transformError(error);
    });
}

export function searchOrdersByFilter(request: SearchOrdersRequest): Promise<SearchOrdersResponse> {
  return orders.api
    .searchOrdersByFilter(orders.mappers.fromSearchOrdersRequest(request))
    .then(orders.mappers.toSearchOrdersResponse)
    .catch((error) => {
      throw transformError(error);
    });
}

export function searchArchiveOrdersByFilter(request: SearchOrdersRequest): Promise<SearchOrdersResponse> {
  return orders.api
    .searchArchiveOrdersByFilter(orders.mappers.fromSearchOrdersRequest(request))
    .then(orders.mappers.toSearchOrdersResponse)
    .catch((error) => {
      throw transformError(error);
    });
}

export async function getCustomerPromos(request: { customerUuid: string }): Promise<PromoCodeEntity[]> {
  return orders.api
    .getCustomerPromos(request)
    .then((promos) => promos.promo_codes_order_customer_entities ?? [])
    .catch((err) => {
      throw transformError(err);
    });
}

export async function getCustomerAllPromos(
  request: GetAllPromoListRequest,
): Promise<GetPromoCodesCustomerController_TariffCalculationPromoCodesCustomerResponse> {
  return orders.api.getCustomerAllPromos(request).catch((err) => {
    throw transformError(err);
  });
}

export async function changeContainerDeliverySpot(
  request: UpdateContainerDeliverySpotController_AgUpdateContainerDeliverySpotRequest,
): Promise<ResultResponse> {
  return orders.api
    .changeContainerDeliverySpot(request)
    .then(orders.mappers.toResultResponse)
    .catch((error) => {
      throw transformError(error);
    });
}
