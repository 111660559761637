import {
  AddDriverRequest,
  AddSemiTrailerRequest,
  AddTruckRequest,
  DriverCardEntity,
  DriverEntity,
  GetDriversResponse,
  GetSemiTrailersResponse,
  GetTrucksResponse,
  ResultResponse,
  SemiTrailerCardEntity,
  SemiTrailerEntity,
  TruckCardEntity,
  TruckEntity,
  UpdateDocumentRequest,
  UpdateDriverRequest,
  UpdateSemiTrailerRequest,
  UpdateTruckRequest,
} from "@/domain";
import {
  type DocumentFileV2Entity,
  DriverCardEntity as KuberDriverCardEntity,
  DriverEntity as KuberDriverEntity,
  NewDocumentMoverEntity,
  PrivateTransportDriverListController_DriverListResponse,
  PrivateTransportNewDriverController_NewDriverRequest,
  PrivateTransportNewSemiTrailerController_NewSemiTrailerRequest,
  PrivateTransportNewTruckController_NewTruckRequest,
  PrivateTransportSemiTrailerListController_SemiTrailerListResponse,
  PrivateTransportTruckListController_TruckListResponse,
  SemiTrailerCardEntity as KuberSemiTrailerCardEntity,
  SemiTrailerEntity as KuberSemiTrailerEntity,
  TruckCardEntity as KuberTruckCardEntity,
  TruckEntity as KuberTruckEntity,
  UpdateDriverController_ResultBoolResponse,
  UpdateDriverController_UpdateDriverRequest,
  UpdateSemiTrailerController_UpdateSemiTrailerRequest,
  UpdateTransportDocumentsController_UpdateDocumentTransportRequest,
  UpdateTruckController_UpdateTruckRequest,
} from "../codegen";
import { toKuberEnum } from "../shared";
import { toDocumentFileEntity, toWorkflowCheckEntity } from "../shared/mappers";
import { DocumentFileAttachment } from "@/data/types";

function toDriverEntity(entity: KuberDriverEntity): DriverEntity {
  return {
    // TODO: codegen
    id: entity.id!,
    alias: entity.alias,
    firstName: entity.first_name,
    secondName: entity.second_name,
    middleName: entity.middle_name ?? "",
    passportAddressRegistration: entity.passport_address_registration ?? "",
    passportSerial: entity.passport_serial ?? "",
    passportNumber: entity.passport_number,
    passportBirthDate: entity.passport_date_birth,
    passportIssuedBy: entity.passport_issued_by ?? "",
    passportIssuedDate: entity.passport_issued_date,
    // TODO: codegen
    passportDepartmentCode: `${entity.passport_department_code}`,
    driverInn: entity.driver_inn ?? "",
    driverLicenseSerial: entity.driver_license_serial,
    driverLicenseNumber: entity.driver_license_number,
    driverLicenseIssuedDate: entity.driver_license_date_issued,
    driverMobilePhone: entity.driver_mobile_phone,
    // TODO: codegen
    // @ts-ignore
    partnershipForm: entity.partnership_form_enum ? entity.partnership_form_enum.value : null,
    uniqCode: entity.uniq_code,
    citizenship: entity.dir_citizenship_enum.value ?? "",
  };
}

function toDriverCardEntity(entity: KuberDriverCardEntity): DriverCardEntity {
  return {
    driver: toDriverEntity(entity.driver_entity),
    files: entity.files ? entity.files.map((f) => toDocumentFileEntity(f)) : [],
    workflowChecks: entity.workflow_check_entities
      ? entity.workflow_check_entities.map((c) => toWorkflowCheckEntity(c))
      : [],
    comments: entity.comment_entities ? entity.comment_entities.map((c) => c.comment) : [],
    verified: entity.dir_verification_status_enum.value !== "not_verified",
  };
}

export function toGetDriversResponse(
  response: PrivateTransportDriverListController_DriverListResponse,
): GetDriversResponse {
  return {
    items: toMergeDriversResponse(response),
    pagination: response.pagination,
  };
}

function toMergeDriversResponse(response: PrivateTransportDriverListController_DriverListResponse): DriverCardEntity[] {
  const userDrivers = response.driver_card_entities ?? [];
  const companyDrivers = (response.driver_company_card_entities ?? []).flatMap((card) => card.driver_card_entity ?? []);
  const mergedDrivers = userDrivers.concat(companyDrivers);

  return mergedDrivers.map((entity) => toDriverCardEntity(entity));
}

export function fromUpdateDriverRequest(request: UpdateDriverRequest): UpdateDriverController_UpdateDriverRequest {
  return {
    driver_entity: {
      uniq_code: request.uniqCode,
      first_name: request.firstName,
      second_name: request.secondName,
      middle_name: request.middleName,
      passport_address_registration: !!request.passportAddressRegistration ? request.passportAddressRegistration : null,
      passport_date_birth: request.passportBirthDate,
      passport_serial: !!request.passportSerial ? request.passportSerial : null,
      passport_number: request.passportNumber,
      passport_issued_by: request.passportIssuedBy,
      passport_issued_date: request.passportIssuedDate,
      // TODO: codegen
      // @ts-ignore
      passport_department_code: !!request.passportDepartmentCode ? request.passportDepartmentCode : null,
      driver_inn: !!request.driverInn ? request.driverInn : null,
      driver_license_serial: request.driverLicenseSerial,
      driver_license_number: request.driverLicenseNumber,
      driver_license_date_issued: request.driverLicenseIssuedDate,
      driver_license_date_expiration: request.driverLicenseExpirationDate,
      driver_mobile_phone: request.driverMobilePhone,
      partnership_form_enum: request.partnershipForm ? toKuberEnum(request.partnershipForm) : null,
      dir_citizenship_enum: toKuberEnum(request.citizenship),
      option_enums: request.options.map((o) => ({ value: o })),
    },
  };
}

export function toResultResponse(response: UpdateDriverController_ResultBoolResponse): ResultResponse {
  return {
    result: response.result_bool_entity ? response.result_bool_entity.result : false,
    message: response.result_bool_entity ? response.result_bool_entity.message ?? undefined : undefined,
  };
}

export function fromAddDriverRequest(request: AddDriverRequest): PrivateTransportNewDriverController_NewDriverRequest {
  return {
    driver_entity: {
      first_name: request.driver.firstName,
      second_name: request.driver.secondName,
      middle_name: request.driver.middleName,
      passport_address_registration: !!request.driver.passportAddressRegistration
        ? request.driver.passportAddressRegistration
        : null,
      passport_date_birth: request.driver.passportBirthDate,
      passport_serial: !!request.driver.passportSerial ? request.driver.passportSerial : null,
      passport_number: request.driver.passportNumber,
      passport_issued_by: request.driver.passportIssuedBy,
      passport_issued_date: request.driver.passportIssuedDate,
      // TODO: codegen
      // @ts-ignore
      passport_department_code: !!request.driver.passportDepartmentCode ? request.driver.passportDepartmentCode : null,
      driver_inn: !!request.driver.driverInn ? request.driver.driverInn : null,
      driver_license_serial: request.driver.driverLicenseSerial,
      driver_license_number: request.driver.driverLicenseNumber,
      driver_license_date_issued: request.driver.driverLicenseIssuedDate,
      driver_license_date_expiration: request.driver.driverLicenseExpirationDate,
      driver_mobile_phone: request.driver.driverMobilePhone,
      partnership_form_enum: request.driver.partnershipForm ? toKuberEnum(request.driver.partnershipForm) : null,
      dir_citizenship_enum: toKuberEnum(request.driver.citizenship),
      option_enums: request.driver.options.map((o) => ({ value: o })),
    },
    document_entities: request.documents.map(fromDocumentAttachment),
  };
}

export function fromDocumentAttachment(attachment: DocumentFileAttachment): NewDocumentMoverEntity {
  return {
    type: toKuberEnum(attachment.documentType ?? ""),
    name: attachment.file.name,
    content: attachment.file.content,
  };
}

function toTruckEntity(entity: KuberTruckEntity): TruckEntity {
  return {
    id: entity.id,
    alias: entity.alias,
    model: entity.model,
    issueDate: entity.issue_date,
    vin: entity.vin,
    carrying: entity.carrying,
    govNumber: entity.gov_number,
    stsNumber: entity.sts_number,
    options: entity.option_enums
      ? entity.option_enums.map((o) => ({
          id: o.id,
          name: o.name,
          description: o.description!,
          active: o.active ?? false,
        }))
      : [],
    // TODO: codegen
    // @ts-ignore
    brand: entity.brand_enum.value,
    // TODO: codegen
    // @ts-ignore
    ownershipForm: entity.ownership_form_enum.value,
    leasingContractNumber: entity.leasing_contract_number ?? undefined,
    uniqCode: entity.uniq_code,
  };
}

function toTruckCardEntity(entity: KuberTruckCardEntity): TruckCardEntity {
  return {
    truck: toTruckEntity(entity.truck_entity),
    files: entity.files ? entity.files.map((f) => toDocumentFileEntity(f)) : [],
    workflowChecks: entity.workflow_check_entities
      ? entity.workflow_check_entities.map((c) => toWorkflowCheckEntity(c))
      : [],
    comments: entity.comment_entities ? entity.comment_entities.map((c) => c.comment) : [],
    // @ts-ignore: Unreachable code error
    verified: entity.verified,
  };
}

export function toGetTrucksResponse(
  response: PrivateTransportTruckListController_TruckListResponse,
): GetTrucksResponse {
  return {
    items: toMergeTrucksResponse(response),
    pagination: response.pagination,
  };
}

function toMergeTrucksResponse(response: PrivateTransportTruckListController_TruckListResponse): TruckCardEntity[] {
  const userTrucks = response.truck_card_entities ?? [];
  const companyTrucks = (response.truck_company_card_entities ?? []).flatMap((card) => card.truck_card_entity ?? []);
  const mergedTrucks = userTrucks.concat(companyTrucks);

  return mergedTrucks.map((entity) => toTruckCardEntity(entity));
}

export function fromUpdateTruckRequest(request: UpdateTruckRequest): UpdateTruckController_UpdateTruckRequest {
  return {
    truck_entity: {
      uniq_code: request.uniqCode,
      brand_enum: toKuberEnum(request.brand),
      model: request.model,
      issue_date: `${request.issueDate}-01-01T00:00:00`,
      vin: request.vin,
      carrying: request.carrying,
      gov_number: request.govNumber,
      sts_number: request.stsNumber,
      ownership_form_enum: toKuberEnum(request.ownershipForm),
      option_enums: request.options.map((o) => ({ value: o })),
      leasing_contract_number: request.leasingContractNumber,
    },
  };
}

export function fromAddTruckRequest(request: AddTruckRequest): PrivateTransportNewTruckController_NewTruckRequest {
  return {
    truck_entity: {
      brand_enum: toKuberEnum(request.truck.brand),
      model: request.truck.model,
      issue_date: `${request.truck.issueDate}-01-01T00:00:00`,
      vin: request.truck.vin,
      carrying: request.truck.carrying,
      gov_number: request.truck.govNumber,
      sts_number: request.truck.stsNumber,
      ownership_form_enum: toKuberEnum(request.truck.ownershipForm),
      option_enums: request.truck.options.map((o) => ({ value: o })),
      leasing_contract_number: request.truck.leasingContractNumber,
    },
    document_entities: request.documents.map(fromDocumentAttachment),
  };
}

function toSemitrailerEntity(entity: KuberSemiTrailerEntity): SemiTrailerEntity {
  return {
    id: entity.id,
    alias: entity.alias,
    model: entity.model,
    issueDate: entity.issue_date,
    vin: entity.vin,
    carrying: entity.carrying,
    govNumber: entity.gov_number,
    stsNumber: entity.sts_number,
    options: entity.option_enums
      ? entity.option_enums.map((o) => ({
          id: o.id,
          name: o.name,
          description: o.description!,
          active: o.active ?? false,
        }))
      : [],
    leasingContractNumber: entity.leasing_contract_number ?? undefined,
    // TODO: codegen
    // @ts-ignore
    brand: entity.brand_enum.value,
    // TODO: codegen
    // @ts-ignore
    sizeType: entity.size_type_enum.value,
    // TODO: codegen
    // @ts-ignore
    ownershipForm: entity.ownership_form_enum.value,
    uniqCode: entity.uniq_code,
  };
}

function toSemitrailerCardEntity(entity: KuberSemiTrailerCardEntity): SemiTrailerCardEntity {
  return {
    semitrailer: toSemitrailerEntity(entity.semi_trailer_entity),
    files: entity.files ? entity.files.map((f) => toDocumentFileEntity(f)) : [],
    workflowChecks: entity.workflow_check_entities
      ? entity.workflow_check_entities.map((c) => toWorkflowCheckEntity(c))
      : [],
    comments: entity.comment_entities ? entity.comment_entities.map((c) => c.comment) : [],
    // @ts-ignore: Unreachable code error
    verified: entity.verified,
  };
}

export function toGetSemitrailersResponse(
  response: PrivateTransportSemiTrailerListController_SemiTrailerListResponse,
): GetSemiTrailersResponse {
  return {
    items: toMergeSemitrailersResponse(response),
    pagination: response.pagination,
  };
}

function toMergeSemitrailersResponse(
  response: PrivateTransportSemiTrailerListController_SemiTrailerListResponse,
): SemiTrailerCardEntity[] {
  const userSemitrailers = response.semi_trailer_card_entities ?? [];
  const companySemitrailers = (response.semi_trailer_company_card_entities ?? []).flatMap(
    (card) => card.semi_trailer_card_entity ?? [],
  );
  const mergedSemitrailers = userSemitrailers.concat(companySemitrailers);

  return mergedSemitrailers.map((entity) => toSemitrailerCardEntity(entity));
}

export function fromUpdateSemiTrailerRequest(
  request: UpdateSemiTrailerRequest,
): UpdateSemiTrailerController_UpdateSemiTrailerRequest {
  return {
    semi_trailer_entity: {
      uniq_code: request.uniqCode,
      brand_enum: toKuberEnum(request.brand),
      model: request.model,
      issue_date: `${request.issueDate}-01-01T00:00:00`,
      vin: request.vin,
      size_type_enum: toKuberEnum(request.sizeType),
      carrying: request.carrying,
      gov_number: request.govNumber,
      sts_number: request.stsNumber,
      ownership_form_enum: toKuberEnum(request.ownershipForm),
      option_enums: request.options.map(toKuberEnum),
      leasing_contract_number: request.leasingContractNumber,
    },
  };
}

export function fromAddSemiTrailerRequest(
  request: AddSemiTrailerRequest,
): PrivateTransportNewSemiTrailerController_NewSemiTrailerRequest {
  return {
    semi_trailer_entity: {
      brand_enum: toKuberEnum(request.semiTrailer.brand),
      model: request.semiTrailer.model,
      issue_date: `${request.semiTrailer.issueDate}-01-01T00:00:00`,
      vin: request.semiTrailer.vin,
      size_type_enum: toKuberEnum(request.semiTrailer.sizeType),
      carrying: request.semiTrailer.carrying,
      gov_number: request.semiTrailer.govNumber,
      sts_number: request.semiTrailer.stsNumber,
      ownership_form_enum: toKuberEnum(request.semiTrailer.ownershipForm),
      option_enums: request.semiTrailer.options.map(toKuberEnum),
      leasing_contract_number: request.semiTrailer.leasingContractNumber,
    },
    document_entities: request.documents.map(fromDocumentAttachment),
  };
}

export function fromUpdateDocumentRequest(
  request: UpdateDocumentRequest,
): UpdateTransportDocumentsController_UpdateDocumentTransportRequest {
  return {
    uniq_code: request.entityId,
    new_document_entities: request.documents.map((doc) => {
      return {
        type: toKuberEnum(doc.documentType ?? ""),
        name: doc.file.name,
        content: doc.file.content,
      };
    }),
  };
}

export function fromDocumentEntity(request: DocumentFileV2Entity): DocumentFileAttachment {
  return {
    documentType: request.type_id,
    file: {
      previewUrl: request.attachment_name,
      initialFile: undefined,
      content: "",
      name: request.original_name,
      type: request.mime_type,
    },
    extension: request.extension,
  };
}
