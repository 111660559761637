import React, { HTMLAttributes } from "react";
import clsx from "clsx";

const Section: React.FC<HTMLAttributes<HTMLDivElement>> = ({ className, children, ...props }) => (
  <section
    className={clsx("flex flex-row items-center justify-center px-6 tablet:px-8 desktop:px-14", className)}
    {...props}
  >
    {children}
  </section>
);

export default Section;
