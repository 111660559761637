import Card from "@/components/Card";
import Loading from "@/components/Loading";

interface Props {
  unstyled?: boolean;
}

const LoadingState = ({ unstyled }: Props) =>
  unstyled ? (
    <div className="flex-grow flex justify-center min-h-[300px]">
      <Loading text="Загружаем" />
    </div>
  ) : (
    <Card className="flex-grow flex justify-center">
      <Loading text="Загружаем" />
    </Card>
  );

export default LoadingState;
