import Close from "@/components/icons/Close";
import clsx from "clsx";

interface Props {
  onClose?: () => void;
  focusOnCloseButton?: boolean;
  className?: string;
}

const ModalHeader: React.FC<Props> = ({ focusOnCloseButton, onClose, children, className }) => (
  <div className="mb-8 flex items-center justify-between gap-4">
    <h4 className={clsx("flex-grow text-xl font-bold text-title", className)}>{children}</h4>
    {onClose && (
      <button
        className="flex h-5 w-5 items-center justify-center text-line hover:text-placeholder"
        onClick={onClose}
        type="button"
        tabIndex={focusOnCloseButton ? undefined : -1}
      >
        <Close />
      </button>
    )}
  </div>
);

export default ModalHeader;
