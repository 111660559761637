export const EMAIL_INPUT_TESTID = "email-input";
export const PASSWORD_INPUT_TESTID = "password-input";
export const CONFIRM_PASSWORD_INPUT_TESTID = "confirm-password-input";
export const FORM_SUBMIT_BUTTON_TESTID = "form-submit-button";

export const CHECKBOX_FIELD_TESTID = "checkbox-field";

export const CONSULT_FORM_TESTID = "consult-form";
export const SUBMIT_CONSULT_FORM_TESTID = "consult-form-submit-button";
export const CONSULT_FORM_NAME_FIELD_TESTID = "consult-form-name-field";
export const CONSULT_FORM_PHONE_FIELD_TESTID = "consult-form-phone-field";
export const CONSULT_FORM_EMAIL_FIELD_TESTID = "consult-form-email-field";
export const CONSULT_FORM_CHECKBOX_FIELD_TESTID = "consult-form-checkbox-field";
export const CONSULT_FORM_MODAL_TESTID = "consult-form-modal";
