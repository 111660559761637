import { ReactElement, ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { NextPage } from "next";
import { AppProps } from "next/app";

import "../styles/globals.css";
import "../components/DateTimePicker/Calendar.css";
import "leaflet/dist/leaflet.css";
import { LandingLayout, SyncAccessToken, SyncAccountInfo } from "@/app";
import { Toaster } from "react-hot-toast";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";

dayjs.extend(utc);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
    },
  },
});

type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement, pageProps: P) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function Kuber({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page: ReactElement) => <LandingLayout>{page}</LandingLayout>);

  return (
    <QueryClientProvider client={queryClient}>
      <Toaster position="bottom-right" reverseOrder={false} gutter={8} />
      <SyncAccessToken />
      <SyncAccountInfo />
      {getLayout(<Component {...pageProps} />, pageProps)}
    </QueryClientProvider>
  );
}

export default Kuber;
