import { directories } from "@/api/kuber-api";
import {
  BillingDirectoryEntity,
  BoxEntity,
  DirectoryRecord,
  DocsPackageDirectoryEntity,
  DocumentDirectoryEntity,
  DocumentTypeEntity,
  LegalFormEntity,
  NalogTypeEntity,
  OrdersDirectoryEntity,
  TicketStatusEntity,
  TransportDirectoryEntity,
} from "@/domain";
import { toDirectoryRecord } from "@/api/kuber-api/directories/directories-mappers";

export function getBoxes(): Promise<BoxEntity[]> {
  return directories.api
    .getBoxesDirectory()
    .then((response) =>
      response.dir_box.map((e) => ({ value: e.value, label: e.label, description: e.description ?? undefined })),
    );
}

export function getTicketStatuses(): Promise<TicketStatusEntity[]> {
  return directories.api.getSupportDirectory().then((response) => {
    if (!response.dir_status) return [] as TicketStatusEntity[];

    return response.dir_status.map((s) => directories.mappers.toTicketStatusEntity(s));
  });
}

export function getTicketTitles(): Promise<DirectoryRecord[]> {
  return directories.api
    .getSupportDirectory()
    .then((response) => response.dir_ticket_title.map((s) => directories.mappers.toDirectoryRecord(s)));
}

export function getSecurityDirectory(): Promise<{
  nalogTypes: NalogTypeEntity[];
  legalForms: LegalFormEntity[];
  roles: DirectoryRecord[];
  adminRoles: DirectoryRecord[];
}> {
  return directories.api.getSecurityDirectory().then((response) => {
    return {
      nalogTypes: response.dir_nalog_type_entities
        ? response.dir_nalog_type_entities.map((e) => directories.mappers.toNalogTypeEnity(e))
        : [],
      legalForms: response.dir_profile_type_entities
        ? response.dir_profile_type_entities.map((e) => directories.mappers.toLegalFormEnity(e))
        : [],
      roles: response.dir_role_entities ? response.dir_role_entities.map((role) => toDirectoryRecord(role)) : [],
      adminRoles: response.dir_admin_change_roles_entities
        ? response.dir_admin_change_roles_entities.map((role) => toDirectoryRecord(role))
        : [],
    };
  });
}

export function getDocumentTypes(): Promise<DocumentTypeEntity[]> {
  return directories.api
    .getDocumentsDirectory()
    .then((response) =>
      response.dir_type ? response.dir_type?.map((e) => directories.mappers.toDocumentTypeEntity(e)) : [],
    );
}

export function getTransportDirectory(): Promise<TransportDirectoryEntity> {
  return directories.api
    .getTransportDirectory()
    .then((response) => directories.mappers.toTransportDirectoryEntity(response));
}

export function getBillingDirectory(): Promise<BillingDirectoryEntity> {
  return directories.api
    .getBillingDirectory()
    .then((response) => directories.mappers.toBillingDirectoryEntity(response));
}

export function getOrdersDirectory(): Promise<OrdersDirectoryEntity> {
  return directories.api.getOrdersDirectory().then((response) => directories.mappers.toOrdersDirectoryEntity(response));
}

export function getDocsPackageDirectory(): Promise<DocsPackageDirectoryEntity> {
  return directories.api
    .getDocsPackageDirectory()
    .then((response) => directories.mappers.toDocsPackageDirectoryEntity(response));
}

export function getNdsDirectory(): Promise<DocsPackageDirectoryEntity> {
  return directories.api
    .getDocsPackageDirectory()
    .then((response) => directories.mappers.toDocsPackageDirectoryEntity(response));
}

export function getCustomerDocumentsDirectory(): Promise<DocumentDirectoryEntity> {
  return directories.api
    .getCustomerDocumentsDirectory()
    .then((response) => directories.mappers.toDocumentDirectoryEntity(response));
}

export function getMoverDocumentsDirectory(): Promise<DocumentDirectoryEntity> {
  return directories.api
    .getMoverDocumentsDirectory()
    .then((response) => directories.mappers.toDocumentDirectoryEntity(response));
}

export function getWorkflowDirectory(): Promise<DirectoryRecord[]> {
  return directories.api.getWorkflowDirectory().then((response) =>
    response.dir_type_entities.map((s) =>
      directories.mappers.toDirectoryRecord({
        label: s.name,
        value: s.id,
        active: s.active,
        description: s.description,
      }),
    ),
  );
}
