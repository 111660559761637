export const TICKET_CLOSED_STATUS = "closed";
export const TICKET_UNANSWERED_STATUS = "u_answered";
export const TICKET_ANSWERED_STATUS = "s_answered";

export const TICKET_GENERAL_CHAT_STATUS = "general_chat";
export const TICKET_NOTIFICATIONS_STATUS = "notifications";
export const TICKET_GENERAL_CHAT_LABEL = "Общий тикет-чат";
export const TICKET_NOTIFICATIONS_LABEL = "Уведомления";

export const PDF_EXTENSION = "pdf" as const;
export const APPLICATION_PDF_EXTENSION = "application/pdf" as const;
export const OCTET_STREAM_EXTENSION = "application/octet-stream" as const;
