import dayjs from "dayjs";
import { Value } from "@/components/DateTimePicker/index";
import { invalidDateStates, InvalidDateType } from "@/data/constants";

const HOURS_TO_MILLISECONDS = 60 * 60 * 1000;
const HOURS_FROM_CURRENT_LOCAL_TIME = 4;
const MILLISECONDS_FROM_CURRENT_LOCAL_TIME = HOURS_FROM_CURRENT_LOCAL_TIME * HOURS_TO_MILLISECONDS;
export const convertToDay = (date: Date) => {
  return dayjs(date).format("DD.MM.YYYY");
};
export const convertToTime = (date: Date) => {
  return dayjs(date).format("HH:mm");
};

const checkForSameDates = (first: Date, second: Date): string => {
  if (
    first.getDate() === second.getDate() &&
    first.getMonth() === second.getMonth() &&
    first.getFullYear() === second.getFullYear()
  ) {
    return dayjs(first).format("DD.MM.YYYY HH:mm").toString() + " — " + dayjs(second).format("HH:mm").toString();
  }
  return (
    dayjs(first).format("DD.MM.YYYY HH:mm").toString() + " — " + dayjs(second).format("DD.MM.YYYY HH:mm").toString()
  );
};

export const valuePieceToDefaultTimeWithFormat = (date: Value) => {
  if (!date) return;

  if (Array.isArray(date)) {
    if (!date[0] || !date[1]) return;
    date[0].setHours(9);
    date[0].setMinutes(0);
    date[0].setSeconds(0);
    date[1].setHours(9);
    date[1].setMinutes(0);
    date[1].setSeconds(0);
    return checkForSameDates(date[0], date[1]);
  }

  date.setHours(9);
  date.setMinutes(0);
  date.setSeconds(0);
  return dayjs(date).format("DD.MM.YYYY HH:mm").toString();
};
export const dateToDefaultTime = (date: Date): Date => {
  date.setHours(9);
  date.setMinutes(0);
  date.setSeconds(0);
  return date;
};
export const isArray = (date: Value): boolean => {
  return Array.isArray(date);
};

export const extractTimeFromInput = (value: string, date: Date | null) => {
  const timeArray = value.split(":");
  date?.setHours(parseInt(timeArray[0]));
  date?.setMinutes(parseInt(timeArray[1]));
  date?.setSeconds(0);

  return date;
};

export const valuePieceWithFormat = (date: Value) => {
  if (!date) return;

  if (Array.isArray(date)) {
    if (!date[0] || !date[1]) return;
    return checkForSameDates(date[0], date[1]);
  }

  return dayjs(date).format("DD.MM.YYYY HH:mm").toString();
};

export const fromValueToISODate = (value: string): string => {
  const valueArray = value.split(" ");
  const dateArray = valueArray[0].split(".");
  return dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0] + "T" + valueArray[1] + ":00";
};

export const fromInputValueToISODate = (value: string, date?: string): string => {
  const valueArray = value.split(" ");
  const dateArray = valueArray[0].split(".");

  if (date) return dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0] + "T" + date + ":00";
  return dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0] + "T" + valueArray[1] + ":00";
};

const fromRangeToValueArray = (range: string[]): [Date, Date] => {
  if (range[1].length < 6)
    return [new Date(fromInputValueToISODate(range[0])), new Date(fromInputValueToISODate(range[0], range[1]))];
  return [new Date(fromInputValueToISODate(range[0])), new Date(fromInputValueToISODate(range[1]))];
};

export const fromInputToDate = (input: string): Date | [Date, Date] => {
  const inputValueArray = input.split(" — ");

  if (inputValueArray.length > 1) return fromRangeToValueArray(inputValueArray);
  return new Date(fromInputValueToISODate(input));
};

export const validate = (value: string | undefined): InvalidDateType => {
  if (!value) return null;
  const date = fromInputToDate(value);
  const localTime = new Date();

  if (Array.isArray(date)) {
    const firstDate = date[0];
    const secondDate = date[1];

    const equalDatesWithoutTime =
      firstDate.getDate() === secondDate.getDate() &&
      firstDate.getMonth() === secondDate.getMonth() &&
      firstDate.getFullYear() === secondDate.getFullYear();

    const diff = Math.abs(firstDate.getTime() - secondDate.getTime());
    const minutesDiff = Math.floor(diff / 1000 / 60);
    const localTimeFirstDateDiff = localTime.getTime() - firstDate.getTime();
    const localTimeSecondDateDiff = localTime.getTime() - secondDate.getTime();

    if (
      (firstDate.getHours() > secondDate.getHours() && equalDatesWithoutTime) ||
      (firstDate.getMinutes() > secondDate.getMinutes() &&
        firstDate.getHours() === secondDate.getHours() &&
        equalDatesWithoutTime)
    )
      return invalidDateStates.INCORRECT_RANGE;
    if (minutesDiff < 59) return invalidDateStates.MINIMUM_RANGE;

    if (localTimeSecondDateDiff > 0) {
      return invalidDateStates.RANGE_LOCAL_TIME;
    }

    if (Math.abs(localTimeFirstDateDiff) < MILLISECONDS_FROM_CURRENT_LOCAL_TIME) {
      return invalidDateStates.EIGHT_HOURS_FROM_LOCAL_TIME;
    }

    return null;
  }

  const diff = localTime.getTime() - date.getTime();

  if (diff > 0) {
    return invalidDateStates.LOCAL_TIME;
  }
  if (Math.abs(diff) < MILLISECONDS_FROM_CURRENT_LOCAL_TIME) {
    return invalidDateStates.EIGHT_HOURS_FROM_LOCAL_TIME;
  }

  return null;
};
