const CloseIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="stroke-disabled hover:stroke-secondaryText active:stroke-primaryText"
  >
    <path d="M18.666 6L6.66602 18" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.66602 6L18.666 18" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default CloseIcon;
