export const GET_ORDER_STATUS_CHAIN_QUERY = "GET_ORDER_STATUS_CHAIN_QUERY";
export const GET_ORDER_BY_ID_QUERY = "GET_ORDER_BY_ID_QUERY";
export const GET_ORDER_MAP_BY_ID_QUERY = "GET_ORDER_MAP_BY_ID_QUERY";
export const GET_BILLING_INFO_QUERY = "GET_BILLING_INFO_QUERY";
export const GET_ACCOUNT_INFO_QUERY = "GET_ACCOUNT_INFO_QUERY";
export const GET_PROFILE_QUERY = "GET_PROFILE_QUERY";

/** DIRECTORIES */

export const GET_SECURITY_DIRECTORT_QUERY = "GET_SECURITY_DIRECTORY";
export const GET_DOCUMENTS_DIRECTORY_QUERY = "GET_DOCUMENTS_DIRECTORY_QUERY";
export const GET_TRANSPORT_DIRECTORY_QUERY = "GET_TRANSPORT_DIRECTORY_QUERY";
export const GET_BILLING_DIRECTORY_QUERY = "GET_BILLING_DIRECTORY_QUERY";
export const GET_BOXES_QUERY = "GET_BOXES_QUERY";
export const GET_ORDERS_DIRECTORY_QUERY = "GET_ORDERS_DIRECTORY_QUERY";
export const GET_DOCS_PACKAGE_DIRECTORY_QUERY = "GET_DOCS_PACKAGE_DIRECTORY_QUERY";
export const GET_NDS_DIRECTORY_QUERY = "GET_NDS_DIRECTORY_QUERY";
export const GET_CUSTOMER_DOCUMENT_DIRECTORY_QUERY = "GET_CUSTOMER_DOCUMENT_DIRECTORY_QUERY";
export const GET_MOVER_DOCUMENT_DIRECTORY_QUERY = "GET_MOVER_DOCUMENT_DIRECTORY_QUERY";

/** ACCOUNT */

export const SEARCH_COMPANY_QUERY = "SEARCH_COMPANY_QUERY";
export const GET_SESSIONS_QUERY = "GET_SESSIONS_QUERY";
export const CONFIRM_EMAIL_QUERY = "CONFIRM_EMAIL_QUERY";
export const CONFIRM_CURRENT_EMAIL_QUERY = "CONFIRM_CURRENT_EMAIL_QUERY";
export const CONFIRM_CURRENT_PHONE_NUMBER_QUERY = "CONFIRM_CURRENT_PHONE_NUMBER_QUERY";

/** SUPPORT */

export const GET_TICKETS_QUERY = "GET_TICKETS_QUERY";
export const GET_TICKET_MESSAGES_QUERY = "GET_TICKET_MESSAGES_QUERY";
export const GET_TICKET_STATUSES_QUERY = "GET_TICKET_STATUSES_QUERY";
export const GET_ORDERS_QUERY = "GET_ORDERS_QUERY";
export const GET_UNREAD_MESSAGE_COUNT_QUERY = "GET_UNREAD_MESSAGE_COUNT_QUERY";
export const GET_TICKET_CHAT_LIST_QUERY = "GET_TICKET_CHAT_LIST_QUERY";
export const GET_TICKET_CHAT_MESSAGE_LIST_QUERY = "GET_TICKET_CHAT_MESSAGE_LIST_QUERY";
export const GET_TICKET_CHAT_DIRECTORY_LIST_QUERY = "GET_TICKET_CHAT_DIRECTORY_LIST_QUERY";

/** ORDERS */

export const GET_ACTUAL_ORDERS_QUERY = "GET_ACTUAL_ORDERS_QUERY";
export const SEARCH_ACTUAL_ORDERS_BY_FILTER_QUERY = "SEARCH_ORDERS_BY_FILTER_QUERY";
export const SEARCH_ARCHIVE_ORDERS_BY_FILTER_QUERY = "SEARCH_ARCHIVE_ORDERS_BY_FILTER_QUERY";
export const SEARCH_ACTUAL_ORDERS_BY_QUERY_QUERY = "SEARCH_ACTUAL_ORDERS_BY_QUERY_QUERY";

/** BALANCE */

export const GET_ACCOUNT_BALANCE_QUERY = "account-balance";
export const GET_INVOICES_QUERY = "GET_INVOICES_QUERY";
export const GET_FINES_QUERY = "GET_FINES_QUERY";
export const GET_WITHDRAWALS_QUERY = "GET_WITHDRAWALS_QUERY";
export const GET_BALANCE_HISTORY_QUERY = "GET_BALANCE_HISTORY_QUERY";
export const GET_BILLING_CREDITS_QUERY = "GET_BILLING_CREDITS_QUERY";

/** MOVER DIRECTORIES */

export const GET_DRIVERS_QUERY = "GET_DRIVERS_QUERY";
export const GET_SEMITRAILERS_QUERY = "GET_SEMITRAILERS_QUERY";
export const GET_TRUCKS_QUERY = "GET_TRUCKS_QUERY";

/** MISC */

export const GET_NEWS_QUERY = "GET_NEWS_QUERY";
export const GET_ONE_NEWS_QUERY = "GET_ONE_NEWS_QUERY";

/** DOCS */

export const GET_DOCUMENT_LIST_QUERY = "GET_DOCUMENT_LIST_QUERY";
export const GET_DOCUMENT_PACKAGE_QUERY = "GET_DOCUMENT_PACKAGE_QUERY";

/** ADMIN */

export const GET_MANUAL_DIGITALIZATIONS_QUERY = "GET_MANUAL_DIGITALIZATIONS_QUERY";
export const GET_ORDERS_WITHOUT_CONTAINER_QUERY = "GET_ORDERS_WITHOUT_CONTAINER_QUERY";
export const GET_UNCHECKED_VERIFICATIONS_QUERY = "GET_UNCHECKED_VERIFICATIONS_QUERY";
export const GET_CLOSING_DOCS_ORDERS_QUERY = "GET_CLOSING_DOCS_ORDERS_QUERY";
export const GET_FINISH_ORDERS_QUERY = "GET_FINISH_ORDERS_QUERY";
export const GET_USER_ID_BY_PARAMS_QUERY = "GET_USER_ID_BY_PARAMS_QUERY";
export const GET_USERS_QUERY = "GET_USERS_QUERY";
export const GET_PROMOS_QUERY = "GET_PROMOS_QUERY";
export const GET_PROMO_INFO_QUERY = "GET_PROMO_INFO_QUERY";
export const GET_ADMIN_TICKET_CHAT_LIST_QUERY = "GET_ADMIN_TICKET_CHAT_LIST_QUERY";
export const GET_ADMIN_TICKET_CHAT_DIRECTORY_QUERY = "GET_ADMIN_TICKET_CHAT_DIRECTORY_QUERY";
export const GET_USER_INFO_QUERY = "GET_USER_INFO_QUERY";
export const GET_ADMIN_MESSAGE_LIST_QUERY = "GET_ADMIN_MESSAGE_LIST_QUERY";
export const GET_TICKET_CHAT_ID_QUERY = "GET_TICKET_CHAT_ID_QUERY";
export const GET_CLOSED_TICKET_CHAT_INFO_QUERY = "GET_CLOSED_TICKET_CHAT_INFO_QUERY";
export const GET_MESSAGE_MOVED_TO_INFO_QUERY = "GET_MESSAGE_MOVED_TO_INFO_QUERY";
export const GET_MESSAGE_MOVED_FROM_INFO_QUERY = "GET_MESSAGE_MOVED_FROM_INFO_QUERY";
export const GET_ADMIN_ORDERS_QUERY = "GET_ADMIN_ORDERS_QUERY";
export const GET_MOVER_DOC_LIST_QUERY = "GET_MOVER_DOC_LIST_QUERY";
export const GET_MOVER_DOC_PACKAGE_LIST_QUERY = "GET_MOVER_DOC_PACKAGE_LIST_QUERY";
export const GET_WITHDRAW_LIST_QUERY = "GET_WITHDRAW_LIST_QUERY";
export const GET_INVOICE_LIST_QUERY = "GET_INVOICE_LIST_QUERY";
export const GET_CREDIT_LIST_QUERY = "GET_CREDIT_LIST_QUERY";
export const GET_OPERATION_LIST_QUERY = "GET_OPERATION_LIST_QUERY";
export const GET_POSTPAYMET_LIST_QUERY = "GET_POSTPAYMET_LIST_QUERY";
export const GET_COMPLETED_WORK_ACT_LIST_QUERY = "GET_COMPLETED_WORK_ACT_LIST_QUERY";
export const GET_CUSTOMER_DOC_PACKAGE_LIST_QUERY = "GET_CUSTOMER_DOC_PACKAGE_LIST_QUERY";
export const GET_MOVER_TRANSPORT_DOC_LIST_QUERY = "GET_MOVER_TRANSPORT_DOC_LIST_QUERY";
export const GET_DRIVER_LIST_QUERY = "GET_DRIVER_LIST_QUERY";
export const GET_TRUCK_LIST_QUERY = "GET_TRUCK_LIST_QUERY";
export const GET_SEMITRAILER_LIST_QUERY = "GET_SEMITRAILER_LIST_QUERY";
export const GET_BANK_PAYMENT_LIST_QUERY = "GET_BANK_PAYMENT_LIST_QUERY";
export const GET_MOVER_COMPLETED_WORK_ACT_LIST_QUERY = "GET_MOVER_COMPLETED_WORK_ACT_LIST_QUERY";
export const GET_ADMIN_MENU_QUERY = "GET_ADMIN_MENU_QUERY";
export const GET_USER_DICTIONARY_QUERY = "GET_USER_DICTIONARY_QUERY";
export const GET_DRIVER_WORKFLOW_LIST_QUERY = "GET_DRIVER_WORKFLOW_LIST_QUERY";
export const GET_TRUCK_WORKFLOW_LIST_QUERY = "GET_TRUCK_WORKFLOW_LIST_QUERY";
export const GET_SEMITRAILER_WORKFLOW_LIST_QUERY = "GET_SEMITRAILER_WORKFLOW_LIST_QUERY";
export const GET_USER_WORKFLOW_LIST_QUERY = "GET_USER_WORKFLOW_LIST_QUERY";
export const GET_MANUAL_CHECK_LIST_QUERY = "GET_MANUAL_CHECK_LIST_QUERY";
export const GET_BRIEF_ORDER_LIST_QUERY = "GET_BRIEF_ORDER_LIST_QUERY";
export const GET_BRIEF_DOC_PACKAGE_LIST_QUERY = "GET_BRIEF_DOC_PACKAGE_LIST_QUERY";
export const GET_WORKFLOW_DIRECTORY_QUERY = "GET_WORKFLOW_DIRECTORY_QUERY";
export const GET_DIGITIZATION_LIST_QUERY = "GET_DIGITIZATION_LIST_QUERY";
export const GET_COMPANY_LIST_QUERY = "GET_COMPANY_LIST_QUERY";
export const GET_WIDGETS_QUERY = "GET_WIDGETS_QUERY";
export const GET_WIDGET_COMPANY_QUERY = "GET_WIDGET_COMPANY_QUERY";

export const GET_UNUSED_PROMOS_QUERY = "GET_UNUSED_PROMOS_QUERY";
export const GET_ALL_PROMOS_QUERY = "GET_ALL_PROMOS_QUERY";
export const GET_DRIVER_TASK_QUERY = "GET_DRIVER_TASK_QUERY";
