import { AttachmentEntity, ResultResponse } from "@/domain/common";
import {
  GetPaginatedTicketChatsListResponse,
  GetTicketChatDirectoryResponse,
  GetTicketChatsListResponse,
  GetTicketMessagesResponse,
  OpenTicketChatRequest,
  OpenTicketResponse,
  OrderItem,
  SendTicketChatMessageRequest,
  SendTicketMessageResponse,
  TicketChatEntity,
  TicketMessageEntity,
  TicketMessageSenderValue,
} from "@/domain/tickets";
import {
  AttachmentEntity as KuberAttachmentEntity,
  ChangeCustomerTicketPinnedStatusController_ResultBoolResponse,
  ChangeMoverTicketPinnedStatusController_ResultBoolResponse,
  ChangeTicketPinnedStatusAdminController_ResultBoolResponse,
  CustomerOrderEntity,
  CustomerTicketListEntity,
  GetCustomerOrdersController_CustomerOrdersResponse,
  GetCustomerTicketListController_CustomerTicketListResponse,
  GetCustomerTicketMessageListController_TicketMessageListResponse,
  GetCustomerTicketsController_ShortTicketListResponse,
  GetMoverOrdersController_MoverOrdersResponse,
  GetMoverTicketListController_MoverTicketListResponse,
  GetMoverTicketMessageListController_TicketMessageListResponse,
  GetMoverTicketsController_ShortTicketListResponse,
  GetShortTicketsAdminController_ShortTicketListResponse,
  GetTicketMessageListAdminController_TicketMessageListAdminResponse,
  MessageListEntity,
  MoverOrderEntity,
  MoverTicketListEntity,
  OpenCustomerTicketController_TicketAndTicketMessageRequest,
  OpenCustomerTicketController_TicketIdResponse,
  OpenMoverTicketController_TicketAndTicketMessageRequest,
  OpenMoverTicketController_TicketIdResponse,
  SendCustomerTicketMessageController_TicketMessageIdResponse,
  SendCustomerTicketMessageController_TicketMessageRequest,
  SendMoverTicketMessageController_TicketMessageIdResponse,
  SendMoverTicketMessageController_TicketMessageRequest,
} from "../codegen";
import { RoleName } from "@/data/constants"; // TODO: codegen

// TODO: codegen
export function toTicketEntity(ticket: CustomerTicketListEntity | MoverTicketListEntity): TicketChatEntity {
  return {
    id: ticket.id!,
    status: ticket.status?.value as string,
    createdAt: ticket.created_at,
    order: ticket.order,
    pinned: ticket.pinned_by_user,
    unread: ticket.unread_message_count,
    title: ticket.title,
    lastMessage: ticket.last_message_sent_at ?? null,
  };
}

export function toGetTicketsListResponse(
  response:
    | GetCustomerTicketListController_CustomerTicketListResponse
    | GetMoverTicketListController_MoverTicketListResponse,
): GetPaginatedTicketChatsListResponse {
  return {
    items: response.ticket_list_entities ? response.ticket_list_entities.map(toTicketEntity) : [],
    pagination: response.pagination!,
  };
}

function toAttachmentEntity(attachment: KuberAttachmentEntity): AttachmentEntity {
  return {
    id: attachment.attachment_name!,
    link: attachment.link!,
    originalName: attachment.original_name!,
    mimeType: attachment.mime_type?.value!,
    extension: attachment.extension!,
  };
}

function toTicketMessageEntity(ticketMessage: MessageListEntity): TicketMessageEntity {
  return {
    id: ticketMessage.id,
    createdAt: ticketMessage.created_at!,
    text: ticketMessage.text!,
    attachments: ticketMessage.attachments?.map(toAttachmentEntity),
    senderType: (ticketMessage.sender_type.value as TicketMessageSenderValue) ?? false,
    movedFrom: ticketMessage.moved_from,
    movedTo: ticketMessage.moved_to,
    deleted: ticketMessage.deleted,
    read: ticketMessage.read_by_user,
    senderName: ticketMessage.sender_first_name ?? undefined,
  };
}

export function toGetTicketMessagesRequest(
  response:
    | GetCustomerTicketMessageListController_TicketMessageListResponse
    | GetMoverTicketMessageListController_TicketMessageListResponse
    | GetTicketMessageListAdminController_TicketMessageListAdminResponse,
): GetTicketMessagesResponse {
  return {
    items: response.message_list_entities ? response.message_list_entities.map(toTicketMessageEntity) : [],
    pagination: response.pagination!,
  };
}

export function fromSendTicketChatMessageRequest(
  request: SendTicketChatMessageRequest["payload"],
): SendMoverTicketMessageController_TicketMessageRequest | SendCustomerTicketMessageController_TicketMessageRequest {
  return {
    ticket_id: request.ticketId,
    message_entity: {
      read_by_user: request.message.read,
      text: request.message.text,
      attachments: request.message.attachments,
    },
  };
}

export function toSendTicketChatMessageResponse(
  response:
    | SendMoverTicketMessageController_TicketMessageIdResponse
    | SendCustomerTicketMessageController_TicketMessageIdResponse,
): SendTicketMessageResponse {
  return {
    messageId: response.message_id,
  };
}

export function fromOpenTicketChatRequest(
  request: OpenTicketChatRequest["payload"],
):
  | OpenCustomerTicketController_TicketAndTicketMessageRequest
  | OpenMoverTicketController_TicketAndTicketMessageRequest {
  return {
    message_entity: {
      read_by_user: request.message.read,
      text: request.message.text,
      attachments: request.message.attachments,
    },
    ticket_title: request.title,
    ticket_pinned: request.pinned,
  };
}

export function toOpenTicketChatResponse(
  response: OpenMoverTicketController_TicketIdResponse | OpenCustomerTicketController_TicketIdResponse,
): OpenTicketResponse {
  return {
    ticketId: response.ticket_id,
  };
}

export function toOrdersListResponse(
  response: GetCustomerOrdersController_CustomerOrdersResponse | GetMoverOrdersController_MoverOrdersResponse,
): OrderItem[] {
  return response.entities.map((entity) => toOrderEntityResponse(entity));
}

function toOrderEntityResponse(response: CustomerOrderEntity | MoverOrderEntity): OrderItem {
  return {
    containers: response.container_numbers,
    orderId: response.order_id_code,
  };
}

export function toGetTicketChatDirectoryResponse(
  response:
    | GetMoverTicketsController_ShortTicketListResponse
    | GetCustomerTicketsController_ShortTicketListResponse
    | GetShortTicketsAdminController_ShortTicketListResponse,
): GetTicketChatDirectoryResponse {
  return response.tickets ? response.tickets : [];
}

export function toGetTicketChatListResponse(
  response:
    | GetCustomerTicketListController_CustomerTicketListResponse
    | GetMoverTicketListController_MoverTicketListResponse,
  profileType: RoleName,
): GetTicketChatsListResponse {
  return {
    profileType: profileType,
    items: response.ticket_list_entities ? response.ticket_list_entities.map(toTicketEntity) : [],
    pagination: response.pagination!,
  };
}

export function toChangeTicketPinnedStatusResponse(
  response:
    | ChangeTicketPinnedStatusAdminController_ResultBoolResponse
    | ChangeMoverTicketPinnedStatusController_ResultBoolResponse
    | ChangeCustomerTicketPinnedStatusController_ResultBoolResponse,
): ResultResponse {
  return {
    result: response.result_bool_entity ? response.result_bool_entity.result : false,
    message: response.result_bool_entity ? response.result_bool_entity.message ?? undefined : undefined,
  };
}
