type AppErrorOptions = {
  isBackendError?: boolean;
  errorMessage?: string;
  errorCode?: string;
  error?: Error;
  statusCode?: number;
};

export class AppError extends Error {
  isBackendError: boolean;
  errorMessage?: string;
  errorCode?: string;
  error?: Error;
  statusCode?: number;

  constructor({ isBackendError = false, errorMessage, errorCode, error, statusCode }: AppErrorOptions) {
    super(errorMessage);
    this.isBackendError = isBackendError;
    this.errorMessage = errorMessage;
    this.errorCode = errorCode;
    this.error = error;
    this.statusCode = statusCode;
  }
}
