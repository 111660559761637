import React, { ReactElement } from "react";
import clsx from "clsx";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  rightIcon?: ReactElement;
  rightIconNoEvents?: boolean;
  inputStyles?: string;
  labelStyles?: string;
  rightIconStyles?: string;
  label?: string;
  isDirty?: boolean;
  isTouched?: boolean;
  resetField?: () => void;
  fieldValue?: string;
  specialIcon?: ReactElement;
  dateTimePicker?: boolean;
  specialIconStyles?: string;
  leftIcon?: ReactElement;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      placeholder,
      error,
      disabled,
      readOnly,
      className,
      rightIcon,
      rightIconNoEvents,
      inputStyles,
      labelStyles,
      rightIconStyles,
      label,
      isDirty,
      isTouched,
      resetField,
      fieldValue,
      specialIcon,
      dateTimePicker,
      specialIconStyles,
      leftIcon,
      ...props
    },
    ref,
  ) => {
    return (
      <div
        className={clsx(
          "relative flex h-fit flex-col gap-1 text-lg text-primaryText",
          disabled && !dateTimePicker && "cursor-not-allowed opacity-30",
          disabled && dateTimePicker && "cursor-not-allowed",
          className,
        )}
      >
        <input
          ref={ref}
          type="text"
          className={clsx(
            "peer bg-white",
            "flex h-14 w-full flex-shrink items-center px-4 pb-[6px] pt-[24px]",
            "text-textBodySemi text-primaryText",
            "rounded-xl border border-divider focus:border-secondaryText",
            "text-ellipsis placeholder-transparent outline-none hover:border-disabled",
            "cursor-text",
            inputStyles && inputStyles,
            error && "!border-error !bg-error-light",
            disabled && "!cursor-not-allowed",
            readOnly && "!cursor-not-allowed",
            rightIcon && "pr-12",
            leftIcon && "pl-12",
          )}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          aria-invalid={!!error}
          {...props}
        />
        <label
          className={clsx(
            "absolute left-[17px] right-[17px] top-1",
            "pointer-events-none text-left text-textBodySemi text-label transition-all",
            "overflow-hidden text-ellipsis whitespace-nowrap",
            "peer-focus:top-1 peer-focus:!text-textSmall peer-focus:!text-secondaryText",
            "peer-placeholder-shown:top-[16px] peer-placeholder-shown:!text-base peer-placeholder-shown:opacity-70",
            labelStyles && labelStyles,
            !!fieldValue && "!text-textSmall",
            rightIcon && "",
            leftIcon && "pl-8",
            error && "!text-error-dark",
          )}
        >
          {label ? label : placeholder}
        </label>
        {rightIcon && fieldValue !== "" && !disabled && (
          <div
            className={clsx(
              "absolute right-2.5 top-2 !bg-transparent",
              "flex h-10 w-10 items-center justify-center bg-white",
              "cursor-pointer",
              rightIconNoEvents && "pointer-events-none",
              rightIconStyles && rightIconStyles,
              error && "!bg-error-light",
              specialIcon && "!right-[42px]",
            )}
            onClick={resetField}
          >
            {rightIcon}
          </div>
        )}
        {specialIcon && (
          <div
            className={clsx(
              "absolute right-2.5 top-2 !bg-transparent",
              "flex h-10 w-10 cursor-text items-center justify-center bg-white",
              specialIconStyles && specialIconStyles,
            )}
          >
            {specialIcon}
          </div>
        )}
        {leftIcon && !disabled && (
          <div
            className={clsx(
              "absolute left-2.5 top-2 !bg-transparent",
              "flex h-10 w-10 items-center justify-center bg-white",
              rightIconNoEvents && "pointer-events-none",
              rightIconStyles && rightIconStyles,
              error && "!bg-error-light",
            )}
            onClick={resetField}
          >
            {leftIcon}
          </div>
        )}
        {error && (
          <label data-testid="error-label" className={clsx("text-left text-textBody text-error-dark")}>
            {error}
          </label>
        )}
      </div>
    );
  },
);

export default Input;
