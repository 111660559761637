import { FIRST_MILE_DELIVERY_TYPE } from "@/domain";

export const EMPTY_STATE = {
  cargos: [{ name: "", cost: "", weight: "", containerHeight: null, containerNumber: "", zpu: "" }],
  deliveryType: {
    value: FIRST_MILE_DELIVERY_TYPE,
    label: "Первая миля",
    active: true,
  },
  containerType: null,
  containerClass: null,
  pickupCity: null,
  pickupTerminal: null,
  deliveryCity: null,
  deliveryTerminal: null,
  warehouseAddress: null,
  warehouseCompanyInn: null,
  warehouseDeliveryDate: "",
  warehouseDeliveryTimePeriod: null,
  warehouseContacts: [{ name: "", phone: "" }],
  howToCarry: null,
  paymentType: null,
  deliveryDateFrom: "",
  deliveryDateTo: "",
  additionalInfo: "",
  options: [],
  dangerClass: "",
  temperatureFrom: "",
  temperatureTo: "",

  shipperInn: null,
  consigneeInn: null,
  clientInn: null,
  requireClientInn: false,

  promo: null,
};

export const tariffPositions = {
  OVERLOAD: "overload",
  PAYMENT_TYPE: "payment_type",
  PROMO_CODE: "promo_code",
} as const;

type TariffPositionKeys = keyof typeof tariffPositions;
export type TariffPositionName = (typeof tariffPositions)[TariffPositionKeys];

export const tariffPositionLabels = {
  [tariffPositions.OVERLOAD]: "Сверхнормативная загрузка контейнера",
  [tariffPositions.PAYMENT_TYPE]: "Скидка за предоплату",
  [tariffPositions.PROMO_CODE]: "Скидка по промо-коду",
} as const;
