import React from "react";
import clsx from "clsx";
import { buttonSizes, buttonVariants } from "@/components/Button2/constants";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: typeof buttonVariants.LINK | typeof buttonVariants.OUTLINE | typeof buttonVariants.GRADIENT;
  loading?: boolean;
  size?: typeof buttonSizes.SMALL | typeof buttonSizes.MEDIUM | typeof buttonSizes.LARGE;
  thin?: boolean;
}

const Spinner = React.memo(() => (
  <div className="flex items-center justify-center rounded-2xl">
    <div className="inline-block h-6 w-6 animate-spin rounded-full border-2 border-r-transparent" role="status"></div>
  </div>
));

const Button: React.FC<Props> = React.forwardRef<HTMLButtonElement, Props>(
  ({ children, loading, disabled, variant, size = buttonSizes.MEDIUM, thin, className, ...props }, ref) => {
    let css;
    switch (variant) {
      case buttonVariants.LINK:
        css = clsx("text-primary", {
          "cursor-not-allowed opacity-70": disabled,
          "hover:text-primary-dark active:text-secondary": !disabled,
        });
        break;
      case buttonVariants.OUTLINE:
        // shadow-[0_20px_30px_rgba(44,152,240,0.29)]
        css = clsx("text-primary rounded-xl px-6 bg-white border-primary border font-semibold", {
          "min-h-[56px] text-lg": size === buttonSizes.LARGE,
          "min-h-[48px] text-base": size === buttonSizes.MEDIUM,
          "min-h-[40px] text-sm": size === buttonSizes.SMALL,
          "cursor-not-allowed opacity-70": disabled,
          "active:bg-active active:text-white hover:bg-hover hover:text-white focus-visible:outline-none focus-visible:border-0 focus-visible:ring-4 focus-visible:ring-bgMiddleBlue":
            !disabled,
        });
        break;
      case buttonVariants.GRADIENT:
      default:
        css = clsx("text-white rounded-xl bg-primary px-6 font-semibold", {
          "min-h-[56px] text-lg": size === buttonSizes.LARGE,
          "min-h-[48px] text-base": size === buttonSizes.MEDIUM,
          "min-h-[40px] text-sm": size === buttonSizes.SMALL,
          "btn-gradient": variant === buttonVariants.GRADIENT,
          "cursor-not-allowed opacity-70": disabled,
          "hover:bg-hover active:bg-active focus-visible:outline-none focus-visible:border-0 focus-visible:ring-4 focus-visible:ring-bgMiddleBlue":
            !disabled,
        });
    }

    return (
      <button ref={ref} className={clsx(css, className)} disabled={disabled} {...props}>
        {!loading ? children : <Spinner />}
      </button>
    );
  },
);

export default React.memo(Button);
