import React, { useEffect } from "react";
import { useToggle } from "react-use";

const NoSSR: React.FC = ({ children }: React.PropsWithChildren<{}>) => {
  const [mounted, toggle] = useToggle(false);
  useEffect(() => {
    toggle(true);
  }, []);
  return mounted ? <>{children}</> : null;
};

export default NoSSR;
