import React from "react";

import RequestForm from "./RequestForm";
import { kuberFormattedPhoneNumber, kuberPhoneNumber } from "@/data/constants";

const Footer = () => {
  return (
    <footer className="flex flex-col items-center justify-end">
      <div className="w-full mobile:px-0 tablet:px-8 desktop:px-14">
        <div
          className="
            relative
            mt-4 
            overflow-hidden bg-white tablet:-mb-24 tablet:mt-14 tablet:rounded-3xl
            tablet:shadow-[0_12px_32px_8px_rgba(39,46,53,0.08)]
            desktop:mx-auto
            desktop:mt-16 desktop:max-w-xxl
          "
        >
          <RequestForm />
        </div>
      </div>

      {/* footer */}
      <div className="flex w-full flex-col items-center gap-4 bg-body px-8 pb-10 pt-10 text-center text-white tablet:pt-[8.5rem]">
        <h4 className="flex-grow-1 text-textMediumSemi">ООО «КУБЕР»</h4>
        <div className="text-textBody">
          <div>
            <span>ИНН 7722489721</span>
          </div>
          <div>
            <span>Москва, Научный проезд, 17, оф. 1537</span>
          </div>
          <div>
            <a href={`tel:${kuberPhoneNumber}`}>{kuberFormattedPhoneNumber}</a>
          </div>
        </div>

        <div className="flex flex-col gap-2 text-textBody text-primary tablet:flex-row tablet:gap-3">
          <a href="/privacy-policy" target="_blank">
            <span className="flex-grow-1 underline underline-offset-4 ">
              Политика обработки и защиты персональных данных
            </span>
          </a>
          <a href="/regulations" target="_blank">
            <span className="flex-grow-1 underline underline-offset-4 ">Правила пользования сайтом</span>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default React.memo(Footer);
