import React from "react";
import clsx from "clsx";

interface LoadingProps {
  text?: string;
  size?: "small";
}

const Loading = ({ text, size }: LoadingProps) => (
  <div className="flex items-center space-x-4">
    {text && <label className="text-title font-medium">{text}</label>}
    <div
      className={clsx(
        "w-auto flex-grow-0 flex justify-between transition-all ease-in-out space-x-1.5",
        size === "small" ? "h-9" : "h-14",
      )}
    >
      {[1, 2, 3, 4, 5].map((i) => (
        <div
          key={i}
          className={clsx("bg-primary rounded animate-rectangle-bounce", size === "small" ? "w-1" : "w-1.5")}
          style={{ animationDelay: `0.${i - 1}s` }}
        ></div>
      ))}
    </div>
  </div>
);

export default React.memo(Loading);
