import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import clsx from "clsx";
import Input from "@/components/Input2";
import { PhoneInput } from "@/components/MaskedInput2";
import Checkbox from "@/components/Checkbox";
import Button from "@/components/Button2";
import Modal from "@/components/Modal";
import Checkmark from "@/components/icons/Checkmark";
import Error from "@/components/icons/Error";

import { emailRegex, phoneNumberRegex } from "@/data/validation";
import { AppError, RequestConsultationRequest } from "@/domain";
import { misc } from "@/services";
import CloseIcon from "src/icons/CloseIcon";
import {
  CONSULT_FORM_EMAIL_FIELD_TESTID,
  CONSULT_FORM_MODAL_TESTID,
  CONSULT_FORM_NAME_FIELD_TESTID,
  CONSULT_FORM_PHONE_FIELD_TESTID,
  CONSULT_FORM_TESTID,
  SUBMIT_CONSULT_FORM_TESTID,
} from "@/tests/data/selectors/app/public-selectors";

type MainFormState = RequestConsultationRequest;

const RequestForm = () => {
  const [isModalVisible, setModalVisibility] = useState(false);
  const [dirtyFields, setDirtyFields] = useState({
    name: false,
    email: false,
    phoneNumber: false,
  });
  const toggleModal = useCallback(() => {
    setModalVisibility((v) => !v);
  }, []);
  const { handleSubmit, control, watch, reset, resetField, setFocus, trigger } = useForm<
    MainFormState & { confirmation: boolean }
  >({
    defaultValues: {
      name: "",
      email: "",
      phoneNumber: "",
      confirmation: false,
    },
  });

  const mutation = useMutation(misc.requestConsultation, {
    onSuccess: () => {
      toggleModal();
    },
    onError: (error: AppError) => {
      toggleModal();
    },
  });

  const submitForm = useCallback(
    (data: MainFormState) => {
      mutation.mutate(data);
    },
    [mutation],
  );

  const resetNameField = () => {
    resetField("name");
    setFocus("name");
    setDirtyFields({
      ...dirtyFields,
      name: true,
    });
  };

  const resetEmailField = () => {
    resetField("email");
    setFocus("email");
    setDirtyFields({
      ...dirtyFields,
      email: true,
    });
  };

  const resetPhoneNumberField = () => {
    resetField("phoneNumber");
    setFocus("phoneNumber");
    setDirtyFields({
      ...dirtyFields,
      phoneNumber: true,
    });
  };

  return (
    <>
      <form
        className=" flex min-h-[inherit] flex-col items-center
          justify-between gap-6 px-6 py-10
          text-center transition-all ease-in-out tablet:items-start tablet:px-16 tablet:py-14 desktop:px-[6.688rem] desktop:py-[4.5rem]
        "
        onSubmit={handleSubmit(submitForm)}
        data-testid={CONSULT_FORM_TESTID}
      >
        <h3 className="tablet:text-header-3 text-title1 tablet:mb-2 desktop:mb-4 desktop:text-header2">
          Заявка на консультацию
        </h3>
        <div className="flex w-full flex-col gap-6 desktop:flex-row">
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState }) => (
              <Input
                {...field}
                resetField={resetNameField}
                rightIcon={<CloseIcon />}
                placeholder="Имя*"
                className="w-full desktop:w-1/3"
                error={fieldState.error?.message}
                isDirty={fieldState.isDirty}
                isTouched={fieldState.isTouched}
                fieldValue={field.value}
                onBlur={() => {
                  (dirtyFields.name || fieldState.isDirty) && trigger(field.name);
                }}
                autoComplete="off"
                data-testid={CONSULT_FORM_NAME_FIELD_TESTID}
              />
            )}
            rules={{
              required: { value: true, message: "Заполните поле" },
            }}
          />
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field, fieldState }) => (
              <PhoneInput
                {...field}
                resetField={resetPhoneNumberField}
                rightIcon={<CloseIcon />}
                placeholder="+ 7 (999) 999 99 99"
                label="Телефон*"
                className="w-full desktop:w-1/3"
                error={fieldState.error?.message}
                inputStyles="focus:!placeholder-disabled focus:placeholder:!text-textBody"
                isDirty={fieldState.isDirty}
                fieldValue={field.value}
                onBlur={() => {
                  (dirtyFields.phoneNumber || fieldState.isDirty) && trigger(field.name);
                }}
                autoComplete="off"
                data-testid={CONSULT_FORM_PHONE_FIELD_TESTID}
              />
            )}
            rules={{
              required: { value: true, message: "Заполните поле" },
              pattern: { value: phoneNumberRegex, message: "Проверьте корректность телефона" },
            }}
          />
          <Controller
            control={control}
            name="email"
            render={({ field, fieldState }) => (
              <>
                <Input
                  {...field}
                  resetField={resetEmailField}
                  rightIcon={<CloseIcon />}
                  placeholder="Электронная почта*"
                  className="w-full desktop:w-1/3"
                  error={fieldState.error?.message}
                  isDirty={fieldState.isDirty}
                  fieldValue={field.value}
                  onBlur={() => {
                    (dirtyFields.email || fieldState.isDirty) && trigger(field.name);
                  }}
                  autoComplete="off"
                  data-testid={CONSULT_FORM_EMAIL_FIELD_TESTID}
                />
              </>
            )}
            rules={{
              required: { value: true, message: "Заполните поле" },
              maxLength: 100,
              pattern: { value: emailRegex, message: "Неверный формат email" },
            }}
          />
        </div>

        <Controller
          control={control}
          name="confirmation"
          render={({ field, fieldState }) => (
            <div className="flex flex-col gap-6">
              <div className="flex flex-row items-start text-textBody">
                <Checkbox {...field} value="" checked={field.value}></Checkbox>
                <span className={clsx("inline-block w-full !text-left text-primaryText")}>
                  Я даю{" "}
                  <a
                    className="inline underline underline-offset-4 hover:cursor-pointer"
                    href="/agreement"
                    target="_blank"
                  >
                    согласие
                  </a>{" "}
                  на обработку моих персональных данных в соответствии с{" "}
                  <a
                    className="inline underline underline-offset-4 hover:cursor-pointer"
                    href="/privacy-policy"
                    target="_blank"
                  >
                    политикой
                  </a>{" "}
                  обработки и защиты персональных данных.
                </span>
              </div>

              <Button
                fullWidth={true}
                className="desktop:w-fit"
                disabled={!field.value}
                size="large"
                type="submit"
                loading={mutation.isLoading}
                data-testid={SUBMIT_CONSULT_FORM_TESTID}
              >
                Отправить заявку
              </Button>
            </div>
          )}
          rules={{
            required: { value: true, message: "Обязательное поле" },
          }}
        />
      </form>
      <Modal
        open={isModalVisible}
        onClose={toggleModal}
        className="flex h-screen items-center tablet:h-auto"
        data-testid={CONSULT_FORM_MODAL_TESTID}
      >
        <div className="flex max-w-[432px] flex-col gap-10 p-5">
          <div className="mx-auto">
            {mutation.isError ? (
              <div className="flex h-20 w-20" data-testid="consult-form-error">
                <Error />
              </div>
            ) : (
              <Checkmark />
            )}
          </div>
          <h4 className="text-center text-smallTitle font-normal" data-testid="consult-form-text">
            {mutation.isError
              ? mutation.error.errorMessage
              : "Ваша заявка принята и будет рассмотрена в течении 24 часов. Спасибо за обращение!"}
          </h4>
          <Button fullWidth={true} onClick={toggleModal}>
            Закрыть
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default RequestForm;
