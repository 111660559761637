import {
  GetBalanceOperationsHistoryRequest,
  GetBillingCreditsRequest,
  GetFinesRequest,
  GetInvoicesRequest,
  GetWithdrawalsRequest,
} from "@/domain";
import { clientWithAuth } from "../client";
import {
  AddCustomerInvoicingBillingController_AddInvoicingBillingRequest,
  AddCustomerInvoicingBillingController_AddInvoicingBillingResponse,
  AddMoverInvoicingBillingController_AddInvoicingBillingRequest,
  AddMoverInvoicingBillingController_AddInvoicingBillingResponse,
  AdvancedCustomerCreditRepaymentController_CustomerCreditRepaymentRequest,
  AdvancedCustomerCreditRepaymentController_CustomerCreditRepaymentResponse,
  AutoMoverBalanceWithdrawController_AutoBalanceWithdrawRequest,
  AutoMoverBalanceWithdrawController_ResultBoolResponse,
  CanceledCustomerApplicationWithdrawController_CancelApplicationWithdrawRequest,
  CanceledCustomerApplicationWithdrawController_CancelApplicationWithdrawResponse,
  CanceledMoverApplicationWithdrawController_CancelApplicationWithdrawRequest,
  CanceledMoverApplicationWithdrawController_CancelApplicationWithdrawResponse,
  GetCustomerBillingApplicationWithdrawController_BillingApplicationWithdrawResponse,
  GetCustomerBillingCreditController_BillingCreditResponse,
  GetCustomerBillingFinesController_BillingFinesResponse,
  GetCustomerBillingHistoryOperationController_BillingHistoryOperationResponse,
  GetCustomerBillingInfoController_CustomerBillingInfoResponse,
  GetCustomerBillingInvoicesController_BillingInvoicesResponse,
  GetCustomerUserBalanceController_UserBalanceResponse,
  GetMoverBillingApplicationWithdrawController_BillingApplicationWithdrawResponse,
  GetMoverBillingCompletedWorkAct_BillingCompletedWorkActResponse,
  GetMoverBillingFinesController_BillingFinesResponse,
  GetMoverBillingHistoryOperationController_BillingHistoryOperationResponse,
  GetMoverBillingInfoController_MoverBillingInfoResponse,
  GetMoverBillingInvoicesController_BillingInvoicesResponse,
  GetMoverUserBalanceController_UserBalanceResponse,
  WithdrawCustomerBalanceInvoiceController_ResultBoolResponse,
  WithdrawCustomerBalanceInvoiceController_WithdrawBalanceInvoiceRequest,
  WithdrawMoverBalanceInvoiceController_ResultBoolResponse,
  WithdrawMoverBalanceInvoiceController_WithdrawMoverBalanceInvoiceRequest,
} from "../codegen";
import {
  AUTO_WITHDRAW_MOVER,
  CANCEL_CUSTOMER_WITHDRAW_REQUEST,
  CANCEL_MOVER_WITHDRAW_REQUEST,
  DEPOSIT_CUSTOMER,
  DEPOSIT_MOVER,
  GET_CUSTOMER_BALANCE,
  GET_CUSTOMER_BILLING_CREDITS,
  GET_CUSTOMER_BILLING_INFO,
  GET_CUSTOMER_FINES,
  GET_CUSTOMER_HISTORY,
  GET_CUSTOMER_INVOICES,
  GET_CUSTOMER_WITHDRAWALS,
  GET_MOVER_BALANCE,
  GET_MOVER_BILLING_INFO,
  GET_MOVER_COMPLETED_WORK_ACT,
  GET_MOVER_FINES,
  GET_MOVER_HISTORY,
  GET_MOVER_INVOICES,
  GET_MOVER_WITHDRAWALS,
  PAY_CUSTOMER_CREDIT,
  WITHDRAW_CUSTOMER,
  WITHDRAW_MOVER,
} from "../endpoints";

export function getCustomerBalance(): Promise<GetCustomerUserBalanceController_UserBalanceResponse> {
  return clientWithAuth
    .get<GetCustomerUserBalanceController_UserBalanceResponse>(GET_CUSTOMER_BALANCE)
    .then((response) => response.data);
}

export function getMoverBalance(): Promise<GetMoverUserBalanceController_UserBalanceResponse> {
  return clientWithAuth
    .get<GetMoverUserBalanceController_UserBalanceResponse>(GET_MOVER_BALANCE)
    .then((response) => response.data);
}

export function getCustomerBillingInfo(): Promise<GetCustomerBillingInfoController_CustomerBillingInfoResponse> {
  return clientWithAuth
    .get<GetCustomerBillingInfoController_CustomerBillingInfoResponse>(GET_CUSTOMER_BILLING_INFO)
    .then((response) => response.data);
}

export function getMoverBillingInfo(): Promise<GetMoverBillingInfoController_MoverBillingInfoResponse> {
  return clientWithAuth
    .get<GetMoverBillingInfoController_MoverBillingInfoResponse>(GET_MOVER_BILLING_INFO)
    .then((response) => response.data);
}

export function getCustomerInvoices(
  request: GetInvoicesRequest,
): Promise<GetCustomerBillingInvoicesController_BillingInvoicesResponse> {
  return clientWithAuth
    .get<GetCustomerBillingInvoicesController_BillingInvoicesResponse>(
      `${GET_CUSTOMER_INVOICES}?offset=${request.payload.pagination.offset}&limit=${request.payload.pagination.limit}&sorting_order_by=${request.payload.sorting.direction}&sorting_field=${request.payload.sorting.field}`,
    )
    .then((response) => response.data);
}

export function getMoverInvoices(
  request: GetInvoicesRequest,
): Promise<GetMoverBillingInvoicesController_BillingInvoicesResponse> {
  return clientWithAuth
    .get<GetMoverBillingInvoicesController_BillingInvoicesResponse>(
      `${GET_MOVER_INVOICES}?offset=${request.payload.pagination.offset}&limit=${request.payload.pagination.limit}&sorting_order_by=${request.payload.sorting.direction}&sorting_field=${request.payload.sorting.field}`,
    )
    .then((response) => response.data);
}

export function getCustomerFines(
  request: GetFinesRequest,
): Promise<GetCustomerBillingFinesController_BillingFinesResponse> {
  return clientWithAuth
    .get<GetCustomerBillingFinesController_BillingFinesResponse>(
      `${GET_CUSTOMER_FINES}?offset=${request.payload.pagination.offset}&limit=${request.payload.pagination.limit}&sorting_order_by=${request.payload.sorting.direction}&sorting_field=${request.payload.sorting.field}`,
    )
    .then((response) => response.data);
}

export function getMoverFines(request: GetFinesRequest): Promise<GetMoverBillingFinesController_BillingFinesResponse> {
  return clientWithAuth
    .get<GetMoverBillingFinesController_BillingFinesResponse>(
      `${GET_MOVER_FINES}?offset=${request.payload.pagination.offset}&limit=${request.payload.pagination.limit}&sorting_order_by=${request.payload.sorting.direction}&sorting_field=${request.payload.sorting.field}`,
    )
    .then((response) => response.data);
}

export function getCustomerWithdrawals(
  request: GetWithdrawalsRequest,
): Promise<GetCustomerBillingApplicationWithdrawController_BillingApplicationWithdrawResponse> {
  return clientWithAuth
    .get<GetCustomerBillingApplicationWithdrawController_BillingApplicationWithdrawResponse>(
      `${GET_CUSTOMER_WITHDRAWALS}?offset=${request.payload.pagination.offset}&limit=${request.payload.pagination.limit}&sorting_order_by=${request.payload.sorting.direction}&sorting_field=${request.payload.sorting.field}`,
    )
    .then((response) => response.data);
}

export function getMoverWithdrawals(
  request: GetWithdrawalsRequest,
): Promise<GetMoverBillingApplicationWithdrawController_BillingApplicationWithdrawResponse> {
  return clientWithAuth
    .get<GetMoverBillingApplicationWithdrawController_BillingApplicationWithdrawResponse>(
      `${GET_MOVER_WITHDRAWALS}?offset=${request.payload.pagination.offset}&limit=${request.payload.pagination.limit}&sorting_order_by=${request.payload.sorting.direction}&sorting_field=${request.payload.sorting.field}`,
    )
    .then((response) => response.data);
}

export function getCustomerOperationsHistory(
  request: GetBalanceOperationsHistoryRequest["payload"],
): Promise<GetCustomerBillingHistoryOperationController_BillingHistoryOperationResponse> {
  return clientWithAuth
    .get<GetCustomerBillingHistoryOperationController_BillingHistoryOperationResponse>(
      `${GET_CUSTOMER_HISTORY}?offset=${request.pagination.offset}&limit=${request.pagination.limit}&sorting_order_by=${request.sorting.direction}&sorting_field=${request.sorting.field}`,
    )
    .then((response) => response.data);
}

export function getMoverOperationsHistory(
  request: GetBalanceOperationsHistoryRequest["payload"],
): Promise<GetMoverBillingHistoryOperationController_BillingHistoryOperationResponse> {
  return clientWithAuth
    .get<GetMoverBillingHistoryOperationController_BillingHistoryOperationResponse>(
      `${GET_MOVER_HISTORY}?offset=${request.pagination.offset}&limit=${request.pagination.limit}&sorting_order_by=${request.sorting.direction}&sorting_field=${request.sorting.field}`,
    )
    .then((response) => response.data);
}

export function getBillingCredits(
  request: GetBillingCreditsRequest,
): Promise<GetCustomerBillingCreditController_BillingCreditResponse> {
  return clientWithAuth
    .get<GetCustomerBillingCreditController_BillingCreditResponse>(
      `${GET_CUSTOMER_BILLING_CREDITS}?offset=${request.pagination.offset}&limit=${request.pagination.limit}&sorting_order_by=${request.sorting.direction}&sorting_field=${request.sorting.field}`,
    )
    .then((response) => response.data);
}

export function depositCustomer(
  request: AddCustomerInvoicingBillingController_AddInvoicingBillingRequest,
): Promise<AddCustomerInvoicingBillingController_AddInvoicingBillingResponse> {
  return clientWithAuth
    .post<AddCustomerInvoicingBillingController_AddInvoicingBillingResponse>(DEPOSIT_CUSTOMER, request)
    .then((response) => response.data);
}

export function depositMover(
  request: AddMoverInvoicingBillingController_AddInvoicingBillingRequest,
): Promise<AddMoverInvoicingBillingController_AddInvoicingBillingResponse> {
  return clientWithAuth
    .post<AddMoverInvoicingBillingController_AddInvoicingBillingResponse>(DEPOSIT_MOVER, request)
    .then((response) => response.data);
}

export function withdrawCustomer(
  request: WithdrawCustomerBalanceInvoiceController_WithdrawBalanceInvoiceRequest,
): Promise<WithdrawCustomerBalanceInvoiceController_ResultBoolResponse> {
  return clientWithAuth
    .post<WithdrawCustomerBalanceInvoiceController_ResultBoolResponse>(WITHDRAW_CUSTOMER, request)
    .then((response) => response.data);
}

export function withdrawMover(
  request: WithdrawMoverBalanceInvoiceController_WithdrawMoverBalanceInvoiceRequest,
): Promise<WithdrawMoverBalanceInvoiceController_ResultBoolResponse> {
  return clientWithAuth
    .post<WithdrawMoverBalanceInvoiceController_ResultBoolResponse>(WITHDRAW_MOVER, request)
    .then((response) => response.data);
}

export function getMoverCompletedWorkAct(): Promise<GetMoverBillingCompletedWorkAct_BillingCompletedWorkActResponse> {
  return clientWithAuth
    .get<GetMoverBillingCompletedWorkAct_BillingCompletedWorkActResponse>(GET_MOVER_COMPLETED_WORK_ACT)
    .then((response) => response.data);
}

export function cancelCustomerWithdrawRequest(
  request: CanceledCustomerApplicationWithdrawController_CancelApplicationWithdrawRequest,
): Promise<CanceledCustomerApplicationWithdrawController_CancelApplicationWithdrawResponse> {
  return clientWithAuth
    .post<CanceledCustomerApplicationWithdrawController_CancelApplicationWithdrawResponse>(
      CANCEL_CUSTOMER_WITHDRAW_REQUEST,
      request,
    )
    .then((response) => response.data);
}

export function cancelMoverWithdrawRequest(
  request: CanceledMoverApplicationWithdrawController_CancelApplicationWithdrawRequest,
): Promise<CanceledMoverApplicationWithdrawController_CancelApplicationWithdrawResponse> {
  return clientWithAuth
    .post<CanceledMoverApplicationWithdrawController_CancelApplicationWithdrawResponse>(
      CANCEL_MOVER_WITHDRAW_REQUEST,
      request,
    )
    .then((response) => response.data);
}

export function payCredit(
  request: AdvancedCustomerCreditRepaymentController_CustomerCreditRepaymentRequest,
): Promise<AdvancedCustomerCreditRepaymentController_CustomerCreditRepaymentResponse> {
  return clientWithAuth
    .post<AdvancedCustomerCreditRepaymentController_CustomerCreditRepaymentResponse>(PAY_CUSTOMER_CREDIT, request)
    .then((response) => response.data);
}

export function changeAutoWithdrawState(
  request: AutoMoverBalanceWithdrawController_AutoBalanceWithdrawRequest,
): Promise<AutoMoverBalanceWithdrawController_ResultBoolResponse> {
  return clientWithAuth
    .post<WithdrawMoverBalanceInvoiceController_ResultBoolResponse>(AUTO_WITHDRAW_MOVER, request)
    .then((response) => response.data);
}
